<template>
  <div class="flex w-full flex-col gap-3 p-6 sm:w-80">
    <div class="flex h-10 w-full  place-content-center">
      <img src="../../assets/logo_blanc.png" alt="Logo" @click="redirectToRoot" />
    </div>
    <div class="flex  mt-10 flex-col gap-2">
      <button @click="redirectToRoot"
        class="inline-flex w-full items-center justify-between rounded-sm hover:bg-gray-200 transition transition-ease-in text-blue-500 bg-card/80 shadow-sm hover:shadow-accent/40 px-4 py-2 gap-3">
        <div class="flex items-center gap-3">
          <Icon name="home" class="-m-1 text-blue-400" />
          <h1 class="text-xl text-blue-400">Accueil</h1>
        </div>
      </button>
    </div>


    <div v-for="category in categories" :key="category.processGroupId" class="mt-1">
      <div v-if="inCategories[category.processGroupId]?.length != 0">
        <CollapsibleMenu>
          <template v-slot:parent>
            <button @click="goToProcessGroup(category.processGroupId)"
              class="inline-flex w-full items-center justify-between rounded-sm hover:bg-gray-200 transition transition-ease-in text-blue-500 bg-card/80 shadow-sm hover:shadow-accent/40 px-4 py-2 gap-3">
              <div class="flex items-center gap-3">
                <Icon v-if="category.processGroupIcon" :name="category.processGroupIcon" class="-m-1 text-blue-400" />
                <h1 class="text-xl text-blue-400">{{ category.processGroupName }}</h1>
              </div>

            </button>
          </template>
          <template v-slot:children>
            <button v-for=" subCategory in inCategories[category.processGroupId]" :key="subCategory.id"
              @click="goToProcessGroup(subCategory.id)"
              class="inline-flex w-full items-center justify-between rounded-sm hover:bg-gray-200 transition transition-ease-in text-blue-500 bg-card/80 shadow-sm hover:shadow-accent/40 px-4 py-2 gap-3">
              <div class="flex items-center gap-3">
                <Icon v-if="subCategory.icon" :name="subCategory.icon" class="-m-1 text-blue-400" />
                <h1 class="text-xl text-blue-400">{{ subCategory.name }}</h1>
              </div>
            </button>
          </template>
        </CollapsibleMenu>

      </div>
      <div v-else>
        <button @click="goToProcessGroup(category.processGroupId)"
          class="inline-flex w-full items-center justify-between rounded-sm hover:bg-gray-200 transition transition-ease-in text-blue-500 bg-card/80 shadow-sm hover:shadow-accent/40 px-4 py-2 gap-3">
          <div class="flex items-center gap-3">
            <Icon v-if="category.processGroupIcon" :name="category.processGroupIcon" class="-m-1 text-blue-400" />
            <h1 class="text-xl text-blue-400">{{ category.processGroupName }}</h1>
          </div>
        </button>
      </div>
    </div>
    <div class="flex flex-col gap-2 mt-auto">
      <button @click="logout"
        class="inline-flex w-full items-center justify-between rounded-xl  border border-red-500 hover:bg-red-200 transition transition-ease-in text-red-500 bg-card/80 shadow-sm hover:shadow-accent/40 px-4 py-2 gap-3">
        <div class="flex items-center gap-3">
          <Icon name="logout" class="-m-1 text-red-400" />
          <h1 class="text-xl text-red-400">Déconnexion</h1>
        </div>
      </button>
    </div>
  </div>
</template>


<script lang="ts" setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import CollapsibleMenu from "./CollapsibleMenu.vue";
import axios from 'axios';
import type { ProcessGroup, inCategory } from '@/types/api'
import Icon from '@/components/ui/Icon.vue'
import { State } from '@/stores/store'; // Assurez-vous d'importer correctement le type State depuis votre chemin
import { useStore } from 'vuex';

const store = useStore<State>();

const sideBarUpToDate = computed(() => store.state.sideBarUpToDate);


const categories = ref<ProcessGroup[]>([])
const inCategories = ref<Record<string, inCategory[]>>({})

const getData = async () => {
  try {
    const response = await axios.get('/processGroup/section/')
    categories.value = response.data.data.categories
    console.log('Categories:', categories.value)  // Journalisation des catégories

    for (const category of categories.value) {
      try {
        const inGroupResponse = await axios.get(
          `/processGroup/${category.processGroupId}/inGroup`
        )
        const inCategory = inGroupResponse.data.data.combinedItems
        console.log(`InCategory for ${category.processGroupId}:`, inCategory)  // Journalisation des sous-catégories

        // Assurez-vous que categoryId est bien défini et unique pour chaque catégorie
        if (category.processGroupId) {
          inCategories.value[category.processGroupId] = inCategory
        } else {
          console.error('Category ID is missing for category:', category)
        }
      } catch (inGroupError) {
        console.error(`Failed to fetch inGroup for category ${category.processGroupId}:`, inGroupError)
      }
    }

    console.log('Final inCategories:', inCategories.value)  // Journalisation finale des sous-catégories
  } catch (error) {
    console.error('Failed to fetch data:', error)
  }
}

import { useAuthStore } from '@/stores/authStore';

const logout = async () => {
  useAuthStore().logout()
}

onMounted(async () => {
  await getData()

});


watch(() => sideBarUpToDate.value, async () => {
  await getData()
  store.commit('refreshSideBarDone')
})

const router = useRouter();

const goToProcessGroup = (id: number) => {
  router.push({
    path: `/processGroup`,
    query: { id: id }
  });

};

const redirectToRoot = () => {
  router.push({ path: "/" });
};

</script>

<style>
.my-container {
  height: 100vh;
  /* 100% de la hauteur de la fenêtre */
}
</style>