



export const IconList = [
  { value: 'accessibility', label: 'Accessibilité' },
  { value: 'accessible', label: 'Accessible' },
  { value: 'account_balance', label: 'Balance' },
  { value: 'account_balance_wallet', label: 'Portefeuille' },
  { value: 'account_box', label: 'Boîte de compte' },
  { value: 'account_circle', label: 'Compte' },
  { value: 'account_circle_off', label: 'Compte Barré' },
  { value: 'add', label: 'Ajouter' },
  { value: 'add_a_photo', label: 'Ajouter une photo' },
  { value: 'add_alert', label: 'Ajouter une alerte' },
  { value: 'add_box', label: 'Ajouter une boîte' },
  { value: 'add_circle', label: 'Ajouter un cercle' },
  { value: 'add_location', label: 'Ajouter une localisation' },
  { value: 'add_location_alt', label: 'Ajouter une localisation (Other)' },
  { value: 'add_shopping_cart', label: 'Ajouter au panier' },
  { value: 'add_to_photos', label: 'Ajouter aux photos' },
  { value: 'airline_seat_flat', label: 'Lit' },
  { value: 'airplanemode_active', label: 'Mode avion actif' },
  { value: 'airplanemode_inactive', label: 'Mode avion inactif' },
  { value: 'airport_shuttle', label: 'MiniBus' },
  { value: 'alarm', label: 'Alarme' },
  { value: 'alarm_add', label: 'Ajouter une alarme' },
  { value: 'alarm_off', label: 'Alarme désactivée' },
  { value: 'alarm_on', label: 'Alarme activée' },
  { value: 'apps', label: 'Applications' },
  { value: 'archive', label: 'Archiver' },
  { value: 'assignment', label: 'Tâche' },
  { value: 'assignment_add', label: 'Ajout de tâche' },
  { value: 'assignment_ind', label: 'Tâche individuelle' },
  { value: 'assignment_late', label: 'Tâche en retard' },
  { value: 'analytics', label: 'Analyse' },
  { value: 'assistant_direction', label: 'Direction mode GPS' },
  { value: 'attach_file', label: 'Joindre un fichier' },
  { value: 'attach_file_add', label: 'Ajouter un fichier joint' },
  { value: 'attach_file_off', label: 'Ne pas joindre un fichier' },
  { value: 'attach_money', label: 'Dollard' },
  { value: 'autorenew', label: 'Renouveler automatiquement' },
  { value: 'add_to_drive', label: 'Ajouter au Drive' },
  { value: 'beach_access', label: 'Plage' },
  { value: 'beenhere', label: 'Valider' },
  { value: 'block', label: 'Bloquer' },
  { value: 'book', label: 'Livre' },
  { value: 'bookmark', label: 'Marque-page' },
  { value: 'bookmarks', label: 'Marque-pages' },
  { value: 'bookmark_border', label: 'Marque-page vide' },


















  // { value: 'accessibility', label: 'Accessibilité' },
  // { value: 'accessible', label: 'Accessible' },
  // { value: 'account_balance', label: 'Balance' },
  // { value: 'account_balance_wallet', label: 'Portefeuille' },
  // { value: 'account_box', label: 'Boîte de compte' },
  // { value: 'account_circle', label: 'Compte' },
  // { value: 'account_circle_off', label: 'Compte Barré' },
  // { value: 'add', label: 'Ajouter' },
  // { value: 'add_a_photo', label: 'Ajouter une photo' },
  // { value: 'add_alert', label: 'Ajouter une alerte' },
  // { value: 'add_box', label: 'Ajouter une boîte' },
  // { value: 'add_circle', label: 'Ajouter un cercle' },
  // { value: 'add_location', label: 'Ajouter une localisation' },
  // { value: 'add_location_alt', label: 'Ajouter une localisation (Other)' },
  // { value: 'add_shopping_cart', label: 'Ajouter au panier' },
  // { value: 'add_to_photos', label: 'Ajouter aux photos' },
  // { value: 'airline_seat_flat', label: 'Lit' },
  // { value: 'airplanemode_active', label: 'Mode avion actif' },
  // { value: 'airplanemode_inactive', label: 'Mode avion inactif' },
  // { value: 'airport_shuttle', label: 'MiniBus' },
  // { value: 'alarm', label: 'Alarme' },
  // { value: 'alarm_add', label: 'Ajouter une alarme' },
  // { value: 'alarm_off', label: 'Alarme désactivée' },
  // { value: 'alarm_on', label: 'Alarme activée' },
  // { value: 'all_inclusive', label: 'Tout compris' },
  // { value: 'archive', label: 'Archiver' },
  // { value: 'arrow_back', label: 'Retour' },
  // { value: 'art_track', label: 'Piste artistique' },
  // { value: 'aspect_ratio', label: 'Ratio' },

  // { value: 'assignment_turned_in', label: 'Tâche effectuée' },
  // { value: 'attach_file', label: 'Joindre un fichier' },
  // { value: 'attach_money', label: 'Joindre de l\'argent' },
  // { value: 'audiotrack', label: 'Piste audio' },
  // { value: 'autorenew', label: 'Renouveler automatiquement' },
  // { value: 'av_timer', label: 'Minuterie AV' },
  // { value: 'backspace', label: 'Retour arrière' },
  // { value: 'add_to_drive', label: 'Ajouter au Drive' },
  // { value: 'battery_alert', label: 'Alerte batterie' },
  // { value: 'battery_charging_full', label: 'Batterie complètement chargée' },
  // { value: 'battery_full', label: 'Batterie pleine' },
  // { value: 'battery_std', label: 'Batterie standard' },
  // { value: 'battery_unknown', label: 'Batterie inconnue' },
  // { value: 'beach_access', label: 'Accès plage' },
  // { value: 'beenhere', label: 'Déjà ici' },
  // { value: 'block', label: 'Bloquer' },
  // { value: 'bluetooth', label: 'Bluetooth' },
  // { value: 'bluetooth_audio', label: 'Audio Bluetooth' },
  // { value: 'bluetooth_connected', label: 'Bluetooth connecté' },
  // { value: 'bluetooth_disabled', label: 'Bluetooth désactivé' },
  // { value: 'bluetooth_searching', label: 'Recherche Bluetooth' },
  // { value: 'blur_circular', label: 'Flou circulaire' },
  // { value: 'blur_linear', label: 'Flou linéaire' },
  // { value: 'blur_off', label: 'Flou désactivé' },
  // { value: 'blur_on', label: 'Flou activé' },
  // { value: 'book', label: 'Livre' },
  // { value: 'bookmark', label: 'Marque-page' },
  // { value: 'bookmark_border', label: 'Marque-page vide' },
  // { value: 'border_all', label: 'Bordure complète' },
  // { value: 'border_bottom', label: 'Bordure en bas' },
  // { value: 'border_clear', label: 'Bordure effacée' },
  // { value: 'border_color', label: 'Couleur de bordure' },
  // { value: 'border_horizontal', label: 'Bordure horizontale' },
  // { value: 'border_inner', label: 'Bordure intérieure' },
  // { value: 'border_left', label: 'Bordure gauche' },
  // { value: 'border_outer', label: 'Bordure extérieure' },
  // { value: 'border_right', label: 'Bordure droite' },
  // { value: 'border_style', label: 'Style de bordure' },
  // { value: 'border_top', label: 'Bordure en haut' },
  // { value: 'border_vertical', label: 'Bordure verticale' },
  // { value: 'branding_watermark', label: 'Filigrane de marque' },
  // { value: 'brightness_1', label: 'Luminosité 1' },
  // { value: 'brightness_2', label: 'Luminosité 2' },
  // { value: 'brightness_3', label: 'Luminosité 3' },
  // { value: 'brightness_4', label: 'Luminosité 4' },
  // { value: 'brightness_5', label: 'Luminosité 5' },
  // { value: 'brightness_6', label: 'Luminosité 6' },
  // { value: 'brightness_7', label: 'Luminosité 7' },
  // { value: 'brightness_auto', label: 'Luminosité auto' },
  // { value: 'brightness_high', label: 'Luminosité élevée' },
  // { value: 'brightness_low', label: 'Luminosité basse' },
  // { value: 'brightness_medium', label: 'Luminosité moyenne' },
  // { value: 'broken_image', label: 'Image brisée' },
  // { value: 'brush', label: 'Brosse' },
  // { value: 'bubble_chart', label: 'Diagramme à bulles' },
  // { value: 'bug_report', label: 'Rapport de bug' },
  // { value: 'build', label: 'Construire' },
  // { value: 'burst_mode', label: 'Mode rafale' },
  // { value: 'business', label: 'Affaires' },
  // { value: 'business_center', label: 'Centre d\'affaires' },
  // { value: 'cached', label: 'En cache' },
  // { value: 'cake', label: 'Gâteau' },
  // { value: 'calendar_today', label: 'Calendrier aujourd\'hui' },
  // { value: 'calendar_view_day', label: 'Vue jour du calendrier' },
  // { value: 'call', label: 'Appel' },
  // { value: 'call_end', label: 'Fin d\'appel' },
  // { value: 'call_made', label: 'Appel effectué' },
  // { value: 'call_merge', label: 'Fusion d\'appel' },
  // { value: 'call_missed', label: 'Appel manqué' },
  // { value: 'call_missed_outgoing', label: 'Appel sortant manqué' },
  // { value: 'call_received', label: 'Appel reçu' },
  // { value: 'call_split', label: 'Division d\'appel' },
  // { value: 'call_to_action', label: 'Appel à l\'action' },
  // { value: 'camera', label: 'Caméra' },
  // { value: 'camera_alt', label: 'Caméra alternative' },
  // { value: 'camera_enhance', label: 'Améliorer la caméra' },
  // { value: 'camera_front', label: 'Caméra frontale' },
  // { value: 'camera_rear', label: 'Caméra arrière' },
  // { value: 'camera_roll', label: 'Pellicule' },
  // { value: 'cancel', label: 'Annuler' },
  // { value: 'card_giftcard', label: 'Carte-cadeau' },
  // { value: 'card_membership', label: 'Carte de membre' },
  // { value: 'card_travel', label: 'Carte de voyage' },
  // { value: 'casino', label: 'Casino' },
  // { value: 'cast', label: 'Diffuser' },
  // { value: 'cast_connected', label: 'Diffusion connectée' },
  // { value: 'category', label: 'Catégorie' },
  // { value: 'center_focus_strong', label: 'Mise au point centrale forte' },
  // { value: 'center_focus_weak', label: 'Mise au point centrale faible' },
  // { value: 'change_history', label: 'Changer l\'historique' },
  // { value: 'chat', label: 'Chat' },
  // { value: 'chat_bubble', label: 'Bulle de chat' },
  // { value: 'chat_bubble_outline', label: 'Bulle de chat vide' },
  // { value: 'check', label: 'Vérifier' },
  // { value: 'check_box', label: 'Case à cocher' },
  // { value: 'check_box_outline_blank', label: 'Case vide' },
  // { value: 'check_circle', label: 'Cercle vérifié' },
  // { value: 'check_circle_outline', label: 'Cercle vérifié vide' },
  // { value: 'chevron_left', label: 'Chevron gauche' },
  // { value: 'chevron_right', label: 'Chevron droit' },
  // { value: 'child_care', label: 'Garde d\'enfants' },
  // { value: 'child_friendly', label: 'Adapté aux enfants' },
  // { value: 'chrome_reader_mode', label: 'Mode lecteur Chrome' },
  // { value: 'class', label: 'Classe' },
  // { value: 'clear', label: 'Effacer' },
  // { value: 'clear_all', label: 'Tout effacer' },
  // { value: 'close', label: 'Fermer' },
  // { value: 'closed_caption', label: 'Sous-titres' },
  // { value: 'cloud', label: 'Nuage' },
  // { value: 'cloud_circle', label: 'Cercle de nuage' },
  // { value: 'cloud_done', label: 'Nuage terminé' },
  // { value: 'cloud_download', label: 'Télécharger depuis le nuage' },
  // { value: 'cloud_off', label: 'Nuage désactivé' },
  // { value: 'cloud_queue', label: 'File d\'attente du nuage' },
  // { value: 'cloud_upload', label: 'Téléverser vers le nuage' },
  // { value: 'code', label: 'Code' },
  // { value: 'collections', label: 'Collections' },
  // { value: 'collections_bookmark', label: 'Marque-page des collections' },
  // { value: 'color_lens', label: 'Palette de couleurs' },
  // { value: 'colorize', label: 'Colorier' },
  // { value: 'comment', label: 'Commentaire' },
  // { value: 'compare', label: 'Comparer' },
  // { value: 'compare_arrows', label: 'Comparer les flèches' },
  // { value: 'computer', label: 'Ordinateur' },
  // { value: 'confirmation_number', label: 'Numéro de confirmation' },
  // { value: 'contact_mail', label: 'Email de contact' },
  // { value: 'contact_phone', label: 'Téléphone de contact' },
  // { value: 'contacts', label: 'Contacts' },
  // { value: 'content_copy', label: 'Copier le contenu' },
  // { value: 'content_cut', label: 'Couper le contenu' },
  // { value: 'content_paste', label: 'Coller le contenu' },
  // { value: 'control_point', label: 'Point de contrôle' },
  // { value: 'control_point_duplicate', label: 'Dupliquer le point de contrôle' },
  // { value: 'copyright', label: 'Droit d\'auteur' },
  // { value: 'create', label: 'Créer' },
  // { value: 'create_new_folder', label: 'Créer un nouveau dossier' },
  // { value: 'credit_card', label: 'Carte de crédit' },
  // { value: 'crop', label: 'Rogner' },
  // { value: 'crop_16_9', label: 'Rogner 16:9' },
  // { value: 'crop_3_2', label: 'Rogner 3:2' },
  // { value: 'crop_5_4', label: 'Rogner 5:4' },
  // { value: 'crop_7_5', label: 'Rogner 7:5' },
  // { value: 'crop_din', label: 'Rogner DIN' },
  // { value: 'crop_free', label: 'Rogner librement' },
  // { value: 'crop_landscape', label: 'Rogner paysage' },
  // { value: 'crop_original', label: 'Rogner original' },
  // { value: 'crop_portrait', label: 'Rogner portrait' },
  // { value: 'crop_rotate', label: 'Rogner et pivoter' },
  // { value: 'crop_square', label: 'Rogner carré' },
  // { value: 'dashboard', label: 'Tableau de bord' },
  // { value: 'data_usage', label: 'Utilisation des données' },
  // { value: 'date_range', label: 'Plage de dates' },
  // { value: 'dehaze', label: 'Déhazer' },
  // { value: 'delete', label: 'Supprimer' },
  // { value: 'delete_forever', label: 'Supprimer pour toujours' },
  // { value: 'delete_outline', label: 'Supprimer vide' },
  // { value: 'delete_sweep', label: 'Balayer la suppression' },
  // { value: 'departure_board', label: 'Tableau des départs' },
  // { value: 'description', label: 'Description' },
  // { value: 'desktop_mac', label: 'Ordinateur de bureau Mac' },
  // { value: 'desktop_windows', label: 'Ordinateur de bureau Windows' },
  // { value: 'details', label: 'Détails' },
  // { value: 'developer_board', label: 'Carte de développement' },
  // { value: 'developer_mode', label: 'Mode développeur' },
  // { value: 'device_hub', label: 'Hub de périphériques' },
  // { value: 'device_unknown', label: 'Périphérique inconnu' },
  // { value: 'devices', label: 'Périphériques' },
  // { value: 'devices_other', label: 'Autres périphériques' },
  // { value: 'dialer_sip', label: 'Composeur SIP' },
  // { value: 'dialpad', label: 'Clavier de numérotation' },
  // { value: 'directions', label: 'Itinéraire' },
  // { value: 'directions_bike', label: 'Itinéraire vélo' },
  // { value: 'directions_boat', label: 'Itinéraire bateau' },
  // { value: 'directions_bus', label: 'Itinéraire bus' },
  // { value: 'directions_car', label: 'Itinéraire voiture' },
  // { value: 'directions_railway', label: 'Itinéraire train' },
  // { value: 'directions_run', label: 'Itinéraire course' },
  // { value: 'directions_subway', label: 'Itinéraire métro' },
  // { value: 'directions_transit', label: 'Itinéraire transport' },
  // { value: 'directions_walk', label: 'Itinéraire à pied' },
  // { value: 'disc_full', label: 'Disque plein' },
  // { value: 'dns', label: 'DNS' },
  // { value: 'do_not_disturb', label: 'Ne pas déranger' },
  // { value: 'do_not_disturb_alt', label: 'Ne pas déranger alt' },
  // { value: 'do_not_disturb_off', label: 'Ne pas déranger désactivé' },
  // { value: 'do_not_disturb_on', label: 'Ne pas déranger activé' },
  // { value: 'dock', label: 'Dock' },
  // { value: 'domain', label: 'Domaine' },
  // { value: 'done', label: 'Terminé' },
  // { value: 'done_all', label: 'Tout terminé' },
  // { value: 'done_outline', label: 'Terminé vide' },
  // { value: 'donut_large', label: 'Grand beignet' },
  // { value: 'donut_small', label: 'Petit beignet' },
  // { value: 'drafts', label: 'Brouillons' },
  // { value: 'drag_handle', label: 'Poignée de glisser' },
  // { value: 'drive_eta', label: 'Durée de conduite estimée' },
  // { value: 'dvr', label: 'DVR' },
  // { value: 'edit', label: 'Modifier' },
  // { value: 'edit_attributes', label: 'Modifier les attributs' },
  // { value: 'edit_location', label: 'Modifier la localisation' },
  // { value: 'eject', label: 'Éjecter' },
  // { value: 'email', label: 'Email' },
  // { value: 'enhanced_encryption', label: 'Chiffrement renforcé' },
  // { value: 'equalizer', label: 'Égaliseur' },
  // { value: 'error', label: 'Erreur' },
  // { value: 'error_outline', label: 'Erreur vide' },
  // { value: 'euro_symbol', label: 'Symbole euro' },
  // { value: 'ev_station', label: 'Station de recharge EV' },
  // { value: 'event', label: 'Événement' },
  // { value: 'event_available', label: 'Événement disponible' },
  // { value: 'event_busy', label: 'Événement occupé' },
  // { value: 'event_note', label: 'Note d\'événement' },
  // { value: 'event_seat', label: 'Siège d\'événement' },
  // { value: 'exit_to_app', label: 'Quitter l\'application' },
  // { value: 'expand_less', label: 'Réduire' },
  // { value: 'expand_more', label: 'Agrandir' },
  // { value: 'explicit', label: 'Explicite' },
  // { value: 'explore', label: 'Explorer' },
  // { value: 'exposure', label: 'Exposition' },
  // { value: 'exposure_neg_1', label: 'Exposition -1' },
  // { value: 'exposure_neg_2', label: 'Exposition -2' },
  // { value: 'exposure_plus_1', label: 'Exposition +1' },
  // { value: 'exposure_plus_2', label: 'Exposition +2' },
  // { value: 'exposure_zero', label: 'Exposition 0' },
  // { value: 'extension', label: 'Extension' },
  // { value: 'face', label: 'Visage' },
  // { value: 'fast_forward', label: 'Avance rapide' },
  // { value: 'fast_rewind', label: 'Rembobiner' },
  // { value: 'favorite', label: 'Favori' },
  // { value: 'favorite_border', label: 'Favori vide' },
  // { value: 'featured_play_list', label: 'Liste de lecture en vedette' },
  // { value: 'featured_video', label: 'Vidéo en vedette' },
  // { value: 'feedback', label: 'Retour' },
  // { value: 'fiber_dvr', label: 'DVR fibre' },
  // { value: 'fiber_manual_record', label: 'Enregistrement manuel fibre' },
  // { value: 'fiber_new', label: 'Nouveau fibre' },
  // { value: 'fiber_pin', label: 'Épingler fibre' },
  // { value: 'fiber_smart_record', label: 'Enregistrement intelligent fibre' },
  // { value: 'file_download', label: 'Télécharger le fichier' },
  // { value: 'file_upload', label: 'Téléverser le fichier' },
  // { value: 'filter', label: 'Filtre' },
  // { value: 'filter_1', label: 'Filtre 1' },
  // { value: 'filter_2', label: 'Filtre 2' },
  // { value: 'filter_3', label: 'Filtre 3' },
  // { value: 'filter_4', label: 'Filtre 4' },
  // { value: 'filter_5', label: 'Filtre 5' },
  // { value: 'filter_6', label: 'Filtre 6' },
  // { value: 'filter_7', label: 'Filtre 7' },
  // { value: 'filter_8', label: 'Filtre 8' },
  // { value: 'filter_9', label: 'Filtre 9' },
  // { value: 'filter_9_plus', label: 'Filtre 9+' },
  // { value: 'filter_b_and_w', label: 'Filtre N&B' },
  // { value: 'filter_center_focus', label: 'Filtre centre' },
  // { value: 'filter_drama', label: 'Filtre drame' },
  // { value: 'filter_frames', label: 'Filtre cadres' },
  // { value: 'filter_hdr', label: 'Filtre HDR' },
  // { value: 'filter_list', label: 'Liste de filtres' },
  // { value: 'filter_none', label: 'Sans filtre' },
  // { value: 'filter_tilt_shift', label: 'Filtre décentrement' },
  // { value: 'filter_vintage', label: 'Filtre vintage' },
  // { value: 'find_in_page', label: 'Trouver dans la page' },
  // { value: 'find_replace', label: 'Trouver et remplacer' },
  // { value: 'fingerprint', label: 'Empreinte digitale' },
  // { value: 'first_page', label: 'Première page' },
  // { value: 'fitness_center', label: 'Salle de sport' },
  // { value: 'flag', label: 'Drapeau' },
  // { value: 'flare', label: 'Flare' },
  // { value: 'flash_auto', label: 'Flash auto' },
  // { value: 'flash_off', label: 'Flash désactivé' },
  // { value: 'flash_on', label: 'Flash activé' },
  // { value: 'flight', label: 'Vol' },
  // { value: 'flight_land', label: 'Atterrissage' },
  // { value: 'flight_takeoff', label: 'Décollage' },
  // { value: 'flip', label: 'Retourner' },
  // { value: 'flip_to_back', label: 'Retourner vers l\'arrière' },
  // { value: 'flip_to_front', label: 'Retourner vers l\'avant' },
  // { value: 'folder', label: 'Dossier' },
  // { value: 'folder_open', label: 'Dossier ouvert' },
  // { value: 'folder_shared', label: 'Dossier partagé' },
  // { value: 'folder_special', label: 'Dossier spécial' },
  // { value: 'font_download', label: 'Télécharger la police' },
  // { value: 'format_align_center', label: 'Aligner au centre' },
  // { value: 'format_align_justify', label: 'Justifier' },
  // { value: 'format_align_left', label: 'Aligner à gauche' },
  // { value: 'format_align_right', label: 'Aligner à droite' },
  // { value: 'format_bold', label: 'Gras' },
  // { value: 'format_clear', label: 'Effacer le format' },
  // { value: 'format_color_fill', label: 'Remplir la couleur' },
  // { value: 'format_color_reset', label: 'Réinitialiser la couleur' },
  // { value: 'format_color_text', label: 'Couleur du texte' },
  // { value: 'format_indent_decrease', label: 'Diminuer l\'indentation' },
  // { value: 'format_indent_increase', label: 'Augmenter l\'indentation' },
  // { value: 'format_italic', label: 'Italique' },
  // { value: 'format_line_spacing', label: 'Interligne' },
  // { value: 'format_list_bulleted', label: 'Liste à puces' },
  // { value: 'format_list_numbered', label: 'Liste numérotée' },
  // { value: 'format_list_numbered_rtl', label: 'Liste numérotée RTL' },
  // { value: 'format_paint', label: 'Peinture' },
  // { value: 'format_quote', label: 'Citation' },
  // { value: 'format_shapes', label: 'Formes' },
  // { value: 'format_size', label: 'Taille du format' },
  // { value: 'format_strikethrough', label: 'Barré' },
  // { value: 'format_textdirection_l_to_r', label: 'Texte de gauche à droite' },
  // { value: 'format_textdirection_r_to_l', label: 'Texte de droite à gauche' },
  // { value: 'format_underlined', label: 'Souligné' },
  // { value: 'forum', label: 'Forum' },
  // { value: 'forward', label: 'Avancer' },
  // { value: 'forward_10', label: 'Avancer 10s' },
  // { value: 'forward_30', label: 'Avancer 30s' },
  // { value: 'forward_5', label: 'Avancer 5s' },
  // { value: 'free_breakfast', label: 'Petit déjeuner gratuit' },
  // { value: 'fullscreen', label: 'Plein écran' },
  // { value: 'fullscreen_exit', label: 'Quitter le plein écran' },
  // { value: 'functions', label: 'Fonctions' },
  // { value: 'g_translate', label: 'G Traduire' },
  // { value: 'gamepad', label: 'Manette' },
  // { value: 'games', label: 'Jeux' },
  // { value: 'gavel', label: 'Marteau' },
  // { value: 'gesture', label: 'Geste' },
  // { value: 'get_app', label: 'Télécharger l\'application' },
  // { value: 'gif', label: 'GIF' },
  // { value: 'golf_course', label: 'Parcours de golf' },
  // { value: 'gps_fixed', label: 'GPS fixe' },
  // { value: 'gps_not_fixed', label: 'GPS non fixe' },
  // { value: 'gps_off', label: 'GPS désactivé' },
  // { value: 'grade', label: 'Note' },
  // { value: 'gradient', label: 'Dégradé' },
  // { value: 'grain', label: 'Grain' },
  // { value: 'graphic_eq', label: 'Égaliseur graphique' },
  // { value: 'grid_off', label: 'Grille désactivée' },
  // { value: 'grid_on', label: 'Grille activée' },
  // { value: 'group', label: 'Groupe' },
  // { value: 'group_add', label: 'Ajouter un groupe' },
  // { value: 'group_work', label: 'Travail en groupe' },
  // { value: 'hd', label: 'HD' },
  // { value: 'hdr_off', label: 'HDR désactivé' },
  // { value: 'hdr_on', label: 'HDR activé' },
  // { value: 'hdr_strong', label: 'HDR fort' },
  // { value: 'hdr_weak', label: 'HDR faible' },
  // { value: 'headset', label: 'Casque' },
  // { value: 'headset_mic', label: 'Micro casque' },
  // { value: 'healing', label: 'Guérison' },
  // { value: 'hearing', label: 'Audition' },
  // { value: 'help', label: 'Aide' },
  // { value: 'help_outline', label: 'Aide vide' },
  // { value: 'high_quality', label: 'Haute qualité' },
  // { value: 'highlight', label: 'Surligner' },
  // { value: 'highlight_off', label: 'Surligner désactivé' },
  // { value: 'history', label: 'Historique' },
  // { value: 'home', label: 'Accueil' },
  // { value: 'hot_tub', label: 'Bain à remous' },
  // { value: 'hotel', label: 'Hôtel' },
  // { value: 'hourglass_empty', label: 'Sablier vide' },
  // { value: 'hourglass_full', label: 'Sablier plein' },
  // { value: 'http', label: 'HTTP' },
  // { value: 'https', label: 'HTTPS' },
  // { value: 'image', label: 'Image' },
  // { value: 'image_aspect_ratio', label: 'Ratio d\'aspect image' },
  // { value: 'import_contacts', label: 'Importer des contacts' },
  // { value: 'import_export', label: 'Importer exporter' },
  // { value: 'important_devices', label: 'Appareils importants' },
  // { value: 'inbox', label: 'Boîte de réception' },
  // { value: 'indeterminate_check_box', label: 'Case à cocher indéterminée' },
  // { value: 'info', label: 'Info' },
  // { value: 'info_outline', label: 'Info vide' },
  // { value: 'input', label: 'Entrée' },
  // { value: 'insert_chart', label: 'Insérer un graphique' },
  // { value: 'insert_comment', label: 'Insérer un commentaire' },
  // { value: 'insert_drive_file', label: 'Insérer un fichier' },
  // { value: 'insert_emoticon', label: 'Insérer un émoticône' },
  // { value: 'insert_invitation', label: 'Insérer une invitation' },
  // { value: 'insert_link', label: 'Insérer un lien' },
  // { value: 'insert_photo', label: 'Insérer une photo' },
  // { value: 'invert_colors', label: 'Inverser les couleurs' },
  // { value: 'invert_colors_off', label: 'Inverser les couleurs désactivé' },
  // { value: 'iso', label: 'ISO' },
  // { value: 'keyboard', label: 'Clavier' },
  // { value: 'keyboard_arrow_down', label: 'Flèche vers le bas' },
  // { value: 'keyboard_arrow_left', label: 'Flèche vers la gauche' },
  // { value: 'keyboard_arrow_right', label: 'Flèche vers la droite' },
  // { value: 'keyboard_arrow_up', label: 'Flèche vers le haut' },
  // { value: 'keyboard_backspace', label: 'Retour arrière' },
  // { value: 'keyboard_capslock', label: 'Verr Maj' },
  // { value: 'keyboard_hide', label: 'Masquer le clavier' },
  // { value: 'keyboard_return', label: 'Retour' },
  // { value: 'keyboard_tab', label: 'Tabulation' },
  // { value: 'keyboard_voice', label: 'Voix clavier' },
  // { value: 'kitchen', label: 'Cuisine' },
  // { value: 'label', label: 'Étiquette' },
  // { value: 'label_outline', label: 'Étiquette vide' },
  // { value: 'landscape', label: 'Paysage' },
  // { value: 'language', label: 'Langue' },
  // { value: 'laptop', label: 'Ordinateur portable' },
  // { value: 'laptop_chromebook', label: 'Ordinateur portable Chromebook' },
  // { value: 'laptop_mac', label: 'Ordinateur portable Mac' },
  // { value: 'laptop_windows', label: 'Ordinateur portable Windows' },
  // { value: 'last_page', label: 'Dernière page' },
  // { value: 'launch', label: 'Lancer' },
  // { value: 'layers', label: 'Couches' },
  // { value: 'layers_clear', label: 'Effacer les couches' },
  // { value: 'leak_add', label: 'Ajouter une fuite' },
  // { value: 'leak_remove', label: 'Supprimer une fuite' },
  // { value: 'lens', label: 'Objectif' },
  // { value: 'library_add', label: 'Ajouter à la bibliothèque' },
  // { value: 'library_books', label: 'Livres de la bibliothèque' },
  // { value: 'library_music', label: 'Musique de la bibliothèque' },
  // { value: 'lightbulb_outline', label: 'Ampoule vide' },
  // { value: 'line_style', label: 'Style de ligne' },
  // { value: 'line_weight', label: 'Épaisseur de ligne' },
  // { value: 'linear_scale', label: 'Échelle linéaire' },
  // { value: 'link', label: 'Lien' },
  // { value: 'linked_camera', label: 'Caméra connectée' },
  // { value: 'list', label: 'Liste' },
  // { value: 'live_help', label: 'Aide en direct' },
  // { value: 'live_tv', label: 'TV en direct' },
  // { value: 'local_activity', label: 'Activité locale' },
  // { value: 'local_airport', label: 'Aéroport local' },
  // { value: 'local_atm', label: 'ATM local' },
  // { value: 'local_bar', label: 'Bar local' },
  // { value: 'local_cafe', label: 'Café local' },
  // { value: 'local_car_wash', label: 'Lavage de voiture local' },
  // { value: 'local_convenience_store', label: 'Magasin de proximité local' },
  // { value: 'local_dining', label: 'Salle à manger locale' },
  // { value: 'local_drink', label: 'Boisson locale' },
  // { value: 'local_florist', label: 'Fleuriste local' },
  // { value: 'local_gas_station', label: 'Station service locale' },
  // { value: 'local_grocery_store', label: 'Épicerie locale' },
  // { value: 'local_hospital', label: 'Hôpital local' },
  // { value: 'local_hotel', label: 'Hôtel local' },
  // { value: 'local_laundry_service', label: 'Blanchisserie locale' },
  // { value: 'local_library', label: 'Bibliothèque locale' },
  // { value: 'local_mall', label: 'Centre commercial local' },
  // { value: 'local_movies', label: 'Films locaux' },
  // { value: 'local_offer', label: 'Offre locale' },
  // { value: 'local_parking', label: 'Parking local' },
  // { value: 'local_pharmacy', label: 'Pharmacie locale' },
  // { value: 'local_phone', label: 'Téléphone local' },
  // { value: 'local_pizza', label: 'Pizzeria locale' },
  // { value: 'local_play', label: 'Jeu local' },
  // { value: 'local_post_office', label: 'Bureau de poste local' },
  // { value: 'local_printshop', label: 'Imprimerie locale' },
  // { value: 'local_see', label: 'Voir localement' },
  // { value: 'local_shipping', label: 'Expédition locale' },
  // { value: 'local_taxi', label: 'Taxi local' },
  // { value: 'location_city', label: 'Ville' },
  // { value: 'location_disabled', label: 'Localisation désactivée' },
  // { value: 'location_off', label: 'Localisation désactivée' },
  // { value: 'location_on', label: 'Localisation activée' },
  // { value: 'location_searching', label: 'Recherche de localisation' },
  // { value: 'lock', label: 'Verrouiller' },
  // { value: 'lock_open', label: 'Déverrouiller' },
  // { value: 'lock_outline', label: 'Verrouiller vide' },
  // { value: 'looks', label: 'Looks' },
  // { value: 'looks_3', label: 'Looks 3' },
  // { value: 'looks_4', label: 'Looks 4' },
  // { value: 'looks_5', label: 'Looks 5' },
  // { value: 'looks_6', label: 'Looks 6' },
  // { value: 'looks_one', label: 'Looks un' },
  // { value: 'looks_two', label: 'Looks deux' },
  // { value: 'loop', label: 'Boucle' },
  // { value: 'loupe', label: 'Loupe' },
  // { value: 'low_priority', label: 'Priorité basse' },
  // { value: 'loyalty', label: 'Fidélité' },
  // { value: 'mail', label: 'Courrier' },
  // { value: 'mail_outline', label: 'Courrier vide' },
  // { value: 'map', label: 'Carte' },
  // { value: 'markunread', label: 'Non lu' },
  // { value: 'markunread_mailbox', label: 'Boîte aux lettres non lue' },
  // { value: 'memory', label: 'Mémoire' },
  // { value: 'menu', label: 'Menu' },
  // { value: 'merge_type', label: 'Type de fusion' },
  // { value: 'message', label: 'Message' },
  // { value: 'mic', label: 'Micro' },
  // { value: 'mic_none', label: 'Micro sans' },
  // { value: 'mic_off', label: 'Micro désactivé' },
  // { value: 'mms', label: 'MMS' },
  // { value: 'mode_comment', label: 'Mode commentaire' },
  // { value: 'mode_edit', label: 'Mode édition' },
  // { value: 'monetization_on', label: 'Monétisation activée' },
  // { value: 'money_off', label: 'Argent désactivé' },
  // { value: 'monochrome_photos', label: 'Photos monochromes' },
  // { value: 'mood', label: 'Humeur' },
  // { value: 'mood_bad', label: 'Mauvaise humeur' },
  // { value: 'more', label: 'Plus' },
  // { value: 'more_horiz', label: 'Plus horizontal' },
  // { value: 'more_vert', label: 'Plus vertical' },
  // { value: 'motorcycle', label: 'Moto' },
  // { value: 'mouse', label: 'Souris' },
  // { value: 'move_to_inbox', label: 'Déplacer vers la boîte de réception' },
  // { value: 'movie', label: 'Film' },
  // { value: 'movie_creation', label: 'Création de film' },
  // { value: 'movie_filter', label: 'Filtre de film' },
  // { value: 'multiline_chart', label: 'Graphique multilignes' },
  // { value: 'music_note', label: 'Note de musique' },
  // { value: 'music_video', label: 'Vidéo musicale' },
  // { value: 'my_location', label: 'Ma localisation' },
  // { value: 'nature', label: 'Nature' },
  // { value: 'nature_people', label: 'Nature et personnes' },
  // { value: 'navigate_before', label: 'Naviguer avant' },
  // { value: 'navigate_next', label: 'Naviguer après' },
  // { value: 'navigation', label: 'Navigation' },
  // { value: 'near_me', label: 'Près de moi' },
  // { value: 'network_cell', label: 'Cellule réseau' },
  // { value: 'network_check', label: 'Vérifier le réseau' },
  // { value: 'network_locked', label: 'Réseau verrouillé' },
  // { value: 'network_wifi', label: 'Wi-Fi réseau' },
  // { value: 'new_releases', label: 'Nouvelles versions' },
  // { value: 'next_week', label: 'Semaine prochaine' },
  // { value: 'nfc', label: 'NFC' },
  // { value: 'no_encryption', label: 'Pas de chiffrement' },
  // { value: 'no_sim', label: 'Pas de SIM' },
  // { value: 'not_interested', label: 'Pas intéressé' },
  // { value: 'note', label: 'Note' },
  // { value: 'note_add', label: 'Ajouter une note' },
  // { value: 'notifications', label: 'Notifications' },
  // { value: 'notifications_active', label: 'Notifications actives' },
  // { value: 'notifications_none', label: 'Aucune notification' },
  // { value: 'notifications_off', label: 'Notifications désactivées' },
  // { value: 'notifications_paused', label: 'Notifications en pause' },
  // { value: 'offline_pin', label: 'Épingler hors ligne' },
  // { value: 'ondemand_video', label: 'Vidéo à la demande' },
  // { value: 'opacity', label: 'Opacité' },
  // { value: 'open_in_browser', label: 'Ouvrir dans le navigateur' },
  // { value: 'open_in_new', label: 'Ouvrir dans un nouvel onglet' },
  // { value: 'open_with', label: 'Ouvrir avec' },
  // { value: 'pages', label: 'Pages' },
  // { value: 'pageview', label: 'Affichage de page' },
  // { value: 'palette', label: 'Palette' },
  // { value: 'pan_tool', label: 'Outil panoramique' },
  // { value: 'panorama', label: 'Panorama' },
  // { value: 'panorama_fish_eye', label: 'Panorama œil de poisson' },
  // { value: 'panorama_horizontal', label: 'Panorama horizontal' },
  // { value: 'panorama_vertical', label: 'Panorama vertical' },
  // { value: 'panorama_wide_angle', label: 'Panorama grand angle' },
  // { value: 'party_mode', label: 'Mode fête' },
  // { value: 'pause', label: 'Pause' },
  // { value: 'pause_circle_filled', label: 'Pause cercle plein' },
  // { value: 'pause_circle_outline', label: 'Pause cercle vide' },
  // { value: 'payment', label: 'Paiement' },
  // { value: 'people', label: 'Personnes' },
  // { value: 'people_outline', label: 'Personnes vide' },
  // { value: 'perm_camera_mic', label: 'Autorisation caméra et micro' },
  // { value: 'perm_contact_calendar', label: 'Autoriser les contacts et le calendrier' },
  // { value: 'perm_data_setting', label: 'Réglage des données' },
  // { value: 'perm_device_information', label: 'Information de l\'appareil' },
  // { value: 'perm_identity', label: 'Identité' },
  // { value: 'perm_media', label: 'Médias' },
  // { value: 'perm_phone_msg', label: 'Messages et téléphone' },
  // { value: 'perm_scan_wifi', label: 'Balayer le Wi-Fi' },
  // { value: 'person', label: 'Personne' },
  // { value: 'person_add', label: 'Ajouter une personne' },
  // { value: 'person_outline', label: 'Personne vide' },
  // { value: 'person_pin', label: 'Épingle de personne' },
  // { value: 'person_pin_circle', label: 'Cercle de l\'épingle de personne' },
  // { value: 'personal_video', label: 'Vidéo personnelle' },
  // { value: 'pets', label: 'Animaux de compagnie' },
  // { value: 'phone', label: 'Téléphone' },
  // { value: 'phone_android', label: 'Téléphone Android' },
  // { value: 'phone_bluetooth_speaker', label: 'Enceinte Bluetooth du téléphone' },
  // { value: 'phone_forwarded', label: 'Transféré' },
  // { value: 'phone_in_talk', label: 'En conversation' },
  // { value: 'phone_iphone', label: 'Téléphone iPhone' },
  // { value: 'phone_locked', label: 'Téléphone verrouillé' },
  // { value: 'phone_missed', label: 'Appels manqués' },
  // { value: 'phone_paused', label: 'Appels en pause' },
  // { value: 'phonelink', label: 'Lien de téléphone' },
  // { value: 'phonelink_erase', label: 'Effacer le lien de téléphone' },
  // { value: 'phonelink_lock', label: 'Verrouiller le lien de téléphone' },
  // { value: 'phonelink_off', label: 'Désactiver le lien de téléphone' },
  // { value: 'phonelink_ring', label: 'Sonnerie de lien de téléphone' },
  // { value: 'phonelink_setup', label: 'Configurer le lien de téléphone' },
  // { value: 'photo', label: 'Photo' },
  // { value: 'photo_album', label: 'Album photo' },
  // { value: 'photo_camera', label: 'Appareil photo' },
  // { value: 'photo_filter', label: 'Filtre photo' },
  // { value: 'photo_library', label: 'Bibliothèque photo' },
  // { value: 'photo_size_select_actual', label: 'Sélection de taille réelle de la photo' },
  // { value: 'photo_size_select_large', label: 'Sélection de taille large de la photo' },
  // { value: 'photo_size_select_small', label: 'Sélection de taille petite de la photo' },
  // { value: 'picture_as_pdf', label: 'Image comme PDF' },
  // { value: 'picture_in_picture', label: 'Image dans l\'image' },
  // { value: 'picture_in_picture_alt', label: 'Image dans l\'image alternative' },
  // { value: 'pie_chart', label: 'Diagramme circulaire' },
  // { value: 'pie_chart_outlined', label: 'Diagramme circulaire souligné' },
  // { value: 'pin_drop', label: 'Épingle de goutte' },
  // { value: 'place', label: 'Lieu' },
  // { value: 'play_arrow', label: 'Jouer flèche' },
  // { value: 'play_circle_filled', label: 'Jouer cercle plein' },
  // { value: 'play_circle_outline', label: 'Jouer cercle vide' },
  // { value: 'play_for_work', label: 'Jouer pour travailler' },
  // { value: 'playlist_add', label: 'Ajouter à la playlist' },
  // { value: 'playlist_add_check', label: 'Ajouter à la playlist vérifier' },
  // { value: 'playlist_play', label: 'Jouer la playlist' },
  // { value: 'plus_one', label: 'Plus un' },
  // { value: 'poll', label: 'Sondage' },
  // { value: 'polymer', label: 'Polymère' },
  // { value: 'pool', label: 'Piscine' },
  // { value: 'portable_wifi_off', label: 'Wi-Fi portable désactivé' },
  // { value: 'portrait', label: 'Portrait' },
  // { value: 'power', label: 'Puissance' },
  // { value: 'power_input', label: 'Entrée de puissance' },
  // { value: 'power_settings_new', label: 'Nouveaux paramètres de puissance' },
  // { value: 'pregnant_woman', label: 'Femme enceinte' },
  // { value: 'present_to_all', label: 'Présent à tous' },
  // { value: 'print', label: 'Imprimer' },
  // { value: 'priority_high', label: 'Priorité élevée' },
  // { value: 'public', label: 'Public' },
  // { value: 'publish', label: 'Publier' },
  // { value: 'query_builder', label: 'Constructeur de requêtes' },
  // { value: 'question_answer', label: 'Question réponse' },
  // { value: 'queue', label: 'File d\'attente' },
  // { value: 'queue_music', label: 'File d\'attente de musique' },
  // { value: 'queue_play_next', label: 'File d\'attente jouer ensuite' },
  // { value: 'radio', label: 'Radio' },
  // { value: 'radio_button_checked', label: 'Bouton radio vérifié' },
  // { value: 'radio_button_unchecked', label: 'Bouton radio non vérifié' },
  // { value: 'rate_review', label: 'Taux critique' },
  // { value: 'receipt', label: 'Reçu' },
  // { value: 'recent_actors', label: 'Acteurs récents' },
  // { value: 'record_voice_over', label: 'Enregistrer la voix off' },
  // { value: 'redeem', label: 'Racheter' },
  // { value: 'redo', label: 'Refaire' },
  // { value: 'refresh', label: 'Actualiser' },
  // { value: 'remove', label: 'Supprimer' },
  // { value: 'remove_circle', label: 'Supprimer cercle' },
  // { value: 'remove_circle_outline', label: 'Supprimer cercle vide' },
  // { value: 'remove_from_queue', label: 'Supprimer de la file d\'attente' },
  // { value: 'remove_red_eye', label: 'Supprimer le rouge de l\'œil' },
  // { value: 'remove_shopping_cart', label: 'Supprimer le panier d\'achat' },
  // { value: 'reorder', label: 'Réorganiser' },
  // { value: 'repeat', label: 'Répéter' },
  // { value: 'repeat_one', label: 'Répéter une fois' },
  // { value: 'replay', label: 'Rejouer' },
  // { value: 'replay_10', label: 'Rejouer 10s' },
  // { value: 'replay_30', label: 'Rejouer 30s' },
  // { value: 'replay_5', label: 'Rejouer 5s' },
  // { value: 'reply', label: 'Répondre' },
  // { value: 'reply_all', label: 'Répondre à tous' },
  // { value: 'report', label: 'Rapport' },
  // { value: 'report_off', label: 'Rapport désactivé' },
  // { value: 'report_problem', label: 'Problème de rapport' },
  // { value: 'restaurant', label: 'Restaurant' },
  // { value: 'restaurant_menu', label: 'Menu du restaurant' },
  // { value: 'restore', label: 'Restaurer' },
  // { value: 'restore_from_trash', label: 'Restaurer de la corbeille' },
  // { value: 'restore_page', label: 'Restaurer la page' },
  // { value: 'ring_volume', label: 'Volume de sonnerie' },
  // { value: 'room', label: 'Pièce' },
  // { value: 'room_service', label: 'Service de chambre' },
  // { value: 'rotate_90_degrees_ccw', label: 'Faire pivoter de 90 degrés CCW' },
  // { value: 'rotate_left', label: 'Rotation à gauche' },
  // { value: 'rotate_right', label: 'Rotation à droite' },
  // { value: 'rounded_corner', label: 'Coin arrondi' },
  // { value: 'router', label: 'Routeur' },
  // { value: 'rowing', label: 'Aviron' },
  // { value: 'rss_feed', label: 'Flux RSS' },
  // { value: 'rv_hookup', label: 'Branchement RV' },
  // { value: 'satellite', label: 'Satellite' },
  // { value: 'save', label: 'Enregistrer' },
  // { value: 'save_alt', label: 'Enregistrer alternatif' },
  // { value: 'scanner', label: 'Scanner' },
  // { value: 'scatter_plot', label: 'Diagramme de dispersion' },
  // { value: 'schedule', label: 'Horaire' },
  // { value: 'school', label: 'École' },
  // { value: 'score', label: 'Score' },
  // { value: 'screen_lock_landscape', label: 'Verrouillage de l\'écran en mode paysage' },
  // { value: 'screen_lock_portrait', label: 'Verrouillage de l\'écran en mode portrait' },
  // { value: 'screen_lock_rotation', label: 'Verrouillage de l\'écran en rotation' },
  // { value: 'screen_rotation', label: 'Rotation de l\'écran' },
  // { value: 'screen_share', label: 'Partage d\'écran' },
  // { value: 'sd_card', label: 'Carte SD' },
  // { value: 'sd_storage', label: 'Stockage SD' },
  // { value: 'search', label: 'Recherche' },
  // { value: 'security', label: 'Sécurité' },
  // { value: 'select_all', label: 'Sélectionner tout' },
  // { value: 'send', label: 'Envoyer' },
  // { value: 'sentiment_dissatisfied', label: 'Sentiment mécontent' },
  // { value: 'sentiment_neutral', label: 'Sentiment neutre' },
  // { value: 'sentiment_satisfied', label: 'Sentiment satisfait' },
  // { value: 'sentiment_very_dissatisfied', label: 'Sentiment très mécontent' },
  // { value: 'sentiment_very_satisfied', label: 'Sentiment très satisfait' },
  // { value: 'settings', label: 'Paramètres' },
  // { value: 'settings_applications', label: 'Applications de paramètres' },
  // { value: 'settings_backup_restore', label: 'Restauration de sauvegarde de paramètres' },
  // { value: 'settings_bluetooth', label: 'Paramètres Bluetooth' },
  // { value: 'settings_brightness', label: 'Luminosité des paramètres' },
  // { value: 'settings_cell', label: 'Paramètres de cellule' },
  // { value: 'settings_ethernet', label: 'Paramètres Ethernet' },
  // { value: 'settings_input_antenna', label: 'Entrée d\'antenne de paramètres' },
  // { value: 'settings_input_component', label: 'Composant d\'entrée de paramètres' },
  // { value: 'settings_input_composite', label: 'Composite d\'entrée de paramètres' },
  // { value: 'settings_input_hdmi', label: 'Entrée HDMI de paramètres' },
  // { value: 'settings_input_svideo', label: 'Entrée S-vidéo de paramètres' },
  // { value: 'settings_overscan', label: 'Paramètres de surbalayage' },
  // { value: 'settings_phone', label: 'Paramètres du téléphone' },
  // { value: 'settings_power', label: 'Paramètres de puissance' },
  // { value: 'settings_remote', label: 'Télécommande' },
  // { value: 'settings_system_daydream', label: 'Paramètres de rêverie diurne du système' },
  // { value: 'settings_voice', label: 'Paramètres vocaux' },
  // { value: 'share', label: 'Partager' },
  // { value: 'shop', label: 'Boutique' },
  // { value: 'shop_two', label: 'Boutique deux' },
  // { value: 'shopping_basket', label: 'Panier d\'achat' },
  // { value: 'shopping_cart', label: 'Chariot de magasinage' },
  // { value: 'short_text', label: 'Texte court' },
  // { value: 'show_chart', label: 'Afficher le graphique' },
  // { value: 'shuffle', label: 'Mélanger' },
  // { value: 'shutter_speed', label: 'Vitesse d\'obturation' },
  // { value: 'signal_cellular_4_bar', label: 'Signal cellulaire à 4 barres' },
  // { value: 'signal_cellular_connected_no_internet_4_bar', label: 'Signal cellulaire connecté sans Internet à 4 barres' },
  // { value: 'signal_cellular_no_sim', label: 'Signal cellulaire sans SIM' },
  // { value: 'signal_cellular_null', label: 'Signal cellulaire nul' },
  // { value: 'signal_cellular_off', label: 'Signal cellulaire désactivé' },
  // { value: 'signal_wifi_4_bar', label: 'Signal Wi-Fi à 4 barres' },
  // { value: 'signal_wifi_4_bar_lock', label: 'Signal Wi-Fi à 4 barres verrouillé' },
  // { value: 'signal_wifi_off', label: 'Signal Wi-Fi désactivé' },
  // { value: 'sim_card', label: 'Carte SIM' },
  // { value: 'sim_card_alert', label: 'Alerte de carte SIM' },
  // { value: 'skip_next', label: 'Suivant' },
  // { value: 'skip_previous', label: 'Précédent' },
  // { value: 'slideshow', label: 'Diaporama' },
  // { value: 'slow_motion_video', label: 'Vidéo au ralenti' },
  // { value: 'smartphone', label: 'Téléphone intelligent' },
  // { value: 'smoke_free', label: 'Fumée libre' },
  // { value: 'smoking_rooms', label: 'Fumer les chambres' },
  // { value: 'sms', label: 'SMS' },
  // { value: 'sms_failed', label: 'Échec du SMS' },
  // { value: 'snooze', label: 'Snooze' },
  // { value: 'sort', label: 'Trier' },
  // { value: 'sort_by_alpha', label: 'Trier par ordre alphabétique' },
  // { value: 'spa', label: 'Spa' },
  // { value: 'space_bar', label: 'Barre d\'espace' },
  // { value: 'speaker', label: 'Haut-parleur' },
  // { value: 'speaker_group', label: 'Groupe de haut-parleurs' },
  // { value: 'speaker_notes', label: 'Notes de haut-parleur' },
  // { value: 'speaker_notes_off', label: 'Haut-parleur désactivé' },
  // { value: 'speaker_phone', label: 'Téléphone haut-parleur' },
  // { value: 'spellcheck', label: 'Vérificateur orthographique' },
  // { value: 'star', label: 'Étoile' },
  // { value: 'star_border', label: 'Bordure d\'étoile' },
  // { value: 'star_half', label: 'Demi-étoile' },
  // { value: 'stars', label: 'Étoiles' },
  // { value: 'stay_current_landscape', label: 'Restez paysage actuel' },
  // { value: 'stay_current_portrait', label: 'Restez portrait actuel' },
  // { value: 'stay_primary_landscape', label: 'Restez paysage primaire' },
  // { value: 'stay_primary_portrait', label: 'Restez portrait primaire' },
  // { value: 'stop', label: 'Arrêt' },
  // { value: 'stop_screen_share', label: 'Arrêter le partage d\'écran' },
  // { value: 'storage', label: 'Stockage' },
  // { value: 'store', label: 'Magasin' },
  // { value: 'store_mall_directory', label: 'Répertoire de centre commercial' },
  // { value: 'straighten', label: 'Redresser' },
  // { value: 'streetview', label: 'Vue de rue' },
  // { value: 'strikethrough_s', label: 'Barré S' },
  // { value: 'style', label: 'Style' },
  // { value: 'subdirectory_arrow_left', label: 'Sous-répertoire flèche gauche' },
  // { value: 'subdirectory_arrow_right', label: 'Sous-répertoire flèche droite' },
  // { value: 'subject', label: 'Sujet' },
  // { value: 'subscriptions', label: 'Abonnements' },
  // { value: 'subtitles', label: 'Sous-titres' },
  // { value: 'subway', label: 'Métro' },
  // { value: 'supervised_user_circle', label: 'Cercle d\'utilisateur supervisé' },
  // { value: 'supervisor_account', label: 'Compte de superviseur' },
  // { value: 'surround_sound', label: 'Son surround' },
  // { value: 'swap_calls', label: 'Échanger des appels' },
  // { value: 'swap_horiz', label: 'Échanger horizontalement' },
  // { value: 'swap_horizontal_circle', label: 'Cercle horizontal d\'échange' },
  // { value: 'swap_vert', label: 'Échanger verticalement' },
  // { value: 'swap_vertical_circle', label: 'Cercle vertical d\'échange' },
  // { value: 'switch_camera', label: 'Changer de caméra' },
  // { value: 'switch_video', label: 'Changer de vidéo' },
  // { value: 'sync', label: 'Synchroniser' },
  // { value: 'sync_alt', label: 'Synchronisation alternative' },
  // { value: 'sync_disabled', label: 'Synchronisation désactivée' },
  // { value: 'sync_problem', label: 'Problème de synchronisation' },
  // { value: 'system_update', label: 'Mise à jour du système' },
  // { value: 'system_update_alt', label: 'Mise à jour du système alternative' },
  // { value: 'tab', label: 'Onglet' },
  // { value: 'tab_unselected', label: 'Onglet non sélectionné' },
  // { value: 'table_chart', label: 'Tableau' },
  // { value: 'tablet', label: 'Tablette' },
  // { value: 'tablet_android', label: 'Tablette Android' },
  // { value: 'tablet_mac', label: 'Tablette Mac' },
  // { value: 'tag_faces', label: 'Visages de tag' },
  // { value: 'tap_and_play', label: 'Appuyez et jouez' },
  // { value: 'terrain', label: 'Terrain' },
  // { value: 'text_fields', label: 'Champs de texte' },
  // { value: 'text_format', label: 'Format de texte' },
  // { value: 'text_rotate_up', label: 'Rotation du texte vers le haut' },
  // { value: 'text_rotate_vertical', label: 'Rotation verticale du texte' },
  // { value: 'text_rotation_angledown', label: 'Rotation du texte inclinée vers le bas' },
  // { value: 'text_rotation_angleup', label: 'Rotation du texte inclinée vers le haut' },
  // { value: 'text_rotation_down', label: 'Rotation du texte vers le bas' },
  // { value: 'text_rotation_none', label: 'Aucune rotation du texte' },
  // { value: 'textsms', label: 'Messages texte' },
  // { value: 'texture', label: 'Texture' },
  // { value: 'theaters', label: 'Théâtres' },
  // { value: 'thumb_down', label: 'Pouce vers le bas' },
  // { value: 'thumb_up', label: 'Pouce vers le haut' },
  // { value: 'thumbs_up_down', label: 'Pouce en haut et en bas' },
  // { value: 'time_to_leave', label: 'Il est temps de partir' },
  // { value: 'timelapse', label: 'Intervalle de temps' },
  // { value: 'timeline', label: 'Chronologie' },
  // { value: 'timer', label: 'Minuteur' },
  // { value: 'timer_10', label: 'Minuteur 10' },
  // { value: 'timer_3', label: 'Minuteur 3' },
  // { value: 'timer_off', label: 'Minuteur désactivé' },
  // { value: 'title', label: 'Titre' },
  // { value: 'toc', label: 'Table des matières' },
  // { value: 'today', label: 'Aujourd\'hui' },
  // { value: 'toll', label: 'Péage' },
  // { value: 'tonality', label: 'Ton' },
  // { value: 'touch_app', label: 'Application tactile' },
  // { value: 'toys', label: 'Jouets' },
  // { value: 'track_changes', label: 'Suivi des modifications' },
  // { value: 'traffic', label: 'Circulation' },
  // { value: 'train', label: 'Train' },
  // { value: 'tram', label: 'Tram' },
  // { value: 'transfer_within_a_station', label: 'Transfert dans une station' },
  // { value: 'transform', label: 'Transformer' },
  // { value: 'transit_enterexit', label: 'Entrée/sortie de transit' },
  // { value: 'translate', label: 'Traduire' },
  // { value: 'trending_down', label: 'Tendance à la baisse' },
  // { value: 'trending_flat', label: 'Tendance plate' },
  // { value: 'trending_up', label: 'Tendance à la hausse' },
  // { value: 'trip_origin', label: 'Origine du voyage' },
  // { value: 'tune', label: 'Réglage' },
  // { value: 'turned_in', label: 'Inclure' },
  // { value: 'turned_in_not', label: 'Non retourné' },
  // { value: 'tv', label: 'Télévision' },
  // { value: 'unarchive', label: 'Désarchiver' },
  // { value: 'undo', label: 'Annuler' },
  // { value: 'unfold_less', label: 'Déplier moins' },
  // { value: 'unfold_more', label: 'Déplier plus' },
  // { value: 'update', label: 'Mettre à jour' },
  // { value: 'usb', label: 'USB' },
  // { value: 'verified_user', label: 'Utilisateur vérifié' },
  // { value: 'vertical_align_bottom', label: 'Alignement vertical en bas' },
  // { value: 'vertical_align_center', label: 'Alignement vertical au centre' },
  // { value: 'vertical_align_top', label: 'Alignement vertical en haut' },
  // { value: 'vertical_split', label: 'Fraction verticale' },
  // { value: 'vibration', label: 'Vibration' },
  // { value: 'video_call', label: 'Appel vidéo' },
  // { value: 'video_label', label: 'Étiquette vidéo' },
  // { value: 'video_library', label: 'Bibliothèque vidéo' },
  // { value: 'videocam', label: 'Caméra vidéo' },
  // { value: 'videocam_off', label: 'Caméra vidéo désactivée' },
  // { value: 'videogame_asset', label: 'Ressource de jeu vidéo' },
  // { value: 'view_agenda', label: 'Voir l\'agenda' },
  // { value: 'view_array', label: 'Voir le tableau' },
  // { value: 'view_carousel', label: 'Voir le carrousel' },
  // { value: 'view_column', label: 'Voir la colonne' },
  // { value: 'view_comfy', label: 'Voir confortable' },
  // { value: 'view_compact', label: 'Voir compact' },
  // { value: 'view_day', label: 'Voir le jour' },
  // { value: 'view_headline', label: 'Voir le titre' },
  // { value: 'view_list', label: 'Voir la liste' },
  // { value: 'view_module', label: 'Voir le module' },
  // { value: 'view_quilt', label: 'Voir le patchwork' },
  // { value: 'view_stream', label: 'Voir le flux' },
  // { value: 'view_week', label: 'Voir la semaine' },
  // { value: 'vignette', label: 'Vignette' },
  // { value: 'visibility', label: 'Visibilité' },
  // { value: 'visibility_off', label: 'Visibilité désactivée' },
  // { value: 'voice_chat', label: 'Discussion vocale' },
  // { value: 'voice_over_off', label: 'Voix off désactivée' },
  // { value: 'voicemail', label: 'Boîte vocale' },
  // { value: 'volume_down', label: 'Volume bas' },
  // { value: 'volume_mute', label: 'Volume muet' },
  // { value: 'volume_off', label: 'Volume désactivé' },
  // { value: 'volume_up', label: 'Augmenter le volume' },
  // { value: 'vpn_key', label: 'Clé VPN' },
  // { value: 'vpn_lock', label: 'Verrouillage VPN' },
  // { value: 'wallpaper', label: 'Papier peint' },
  // { value: 'warning', label: 'Avertissement' },
  // { value: 'watch', label: 'Montre' },
  // { value: 'watch_later', label: 'Regarder plus tard' },
  // { value: 'wb_auto', label: 'WB Auto' },
  // { value: 'wb_incandescent', label: 'WB Incandescent' },
  // { value: 'wb_iridescent', label: 'WB Iridescent' },
  // { value: 'wb_sunny', label: 'WB Ensoleillé' },
  // { value: 'wb_shade', label: 'WB Couvert' },
  // { value: 'wb_twilight', label: 'WB Crépuscule' },
  // { value: 'wc', label: 'WC' },
  // { value: 'web', label: 'Web' },
  // { value: 'web_asset', label: 'Ressource Web' },
  // { value: 'web_asset_off', label: 'Ressource Web Off' },
  // { value: 'weekend', label: 'Week-end' },
  // { value: 'whatshot', label: 'Quoi de neuf' },
  // { value: 'where_to_vote', label: 'Où voter' },
  // { value: 'widgets', label: 'Widgets' },
  // { value: 'wifi', label: 'Wi-Fi' },
  // { value: 'wifi_lock', label: 'Verrouillage Wi-Fi' },
  // { value: 'wifi_off', label: 'Wi-Fi désactivé' },
  // { value: 'wifi_tethering', label: 'Partage de connexion Wi-Fi' },
  // { value: 'work', label: 'Travail' },
  // { value: 'work_off', label: 'Travail désactivé' },
  // { value: 'work_outline', label: 'Contour du travail' },
  // { value: 'wrap_text', label: 'Envelopper le texte' },
  // { value: 'youtube_searched_for', label: 'Recherche YouTube' },
  // { value: 'zoom_in', label: 'Zoom avant' },
  // { value: 'zoom_out', label: 'Zoom arrière' },
  // { value: 'zoom_out_map', label: 'Zoom sur la carte' },

];