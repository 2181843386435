<template>
  <div :style="{
    top: `${rect.topPosition}px`, left: `${rect.leftPosition}px`,
    position: 'absolute', cursor: 'pointer'
  }">
    <div :style="{
      width: rect.width + 'px', height: (rect.height + 5) + 'px'
    }"> <Textarea id="description" v-model="text" placeholder="Texte de la case" class="text-center"/>
    </div>
    <div class="bg-white">
      <Button @click="stop" class="button">
        <span class="material-symbols-outlined">delete</span>
      </Button>
      <Button @click="moreModif" class="button">
        <span class="material-symbols-outlined">edit</span>
      </Button>
      <Button @click="validate" class="button">
        <span class="material-symbols-outlined">check</span>
      </Button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps, defineEmits, watch } from 'vue';
import type { rectangle } from '@/types/api';
import Button from '@/components/ui/button/Button.vue'
import Textarea from '@/components/ui/textarea/Textarea.vue'


interface rectangleMore extends rectangle {
  width: number;
  height: number;
  isSelected: boolean;
  heightTaskResponsibles: number;
  widthTaskResponsibles: number;
}
const props = defineProps({
  rect: {
    type: Object as () => rectangleMore,
    required: true,
  },
});


const emit = defineEmits(['validate', 'stop','moreModif']);

const text = ref<string>(props.rect.text);

const validate = () => {
  emit('validate', text.value);
}

const stop = () => {
  emit('stop');
}

const moreModif = () => {
  emit('moreModif');
}


watch(() => props.rect.text, () => {
  text.value = props.rect.text;
});

</script>

<style scoped>
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  font-family: "Inter-Bold";
  white-space: pre;
  text-align: center;
}
</style>
