<template>
    <button
      class="flex items-center space-x-1 text-blue-500 m-3"
      @click="handleReturnClick"
    >
      <Icon name="arrow_back" class="-m-1 text-blue-400" />
      <span>Retour</span>
    </button>
</template>

<script>
import Icon from "@/components/ui/Icon.vue";

export default {

  methods: {
    handleReturnClick() {
      // Émettre un événement personnalisé
      this.$emit("Click-close");
    },
  },
  components: {
    Icon,
  },
};
</script>

<style>
.texte-container {
  overflow: scroll;
  max-height: 65vh;
}
#image_txt {
  max-height: 30vh;
  margin-left: 10px;
  margin-bottom: 10px;
}
</style>