<template>
  <div v-for="(item, index) in items" :key="index" class="m-2">
    <div v-if="index !=indexModif">
      <div class="flex flex-row justify-between">
        <ImageComponent class="justify-center" v-if="item.type === 'IMAGE'" :mode="mode" :elementId="item.elementId"
          :data="item.data" />
        <DocComponent class="justify-center" v-else-if="item.type === 'DOCUMENT'" :mode="mode" :elementId="item.elementId" :data="item.data" />
        <div v-else-if="item.type === 'MARKDOWN-TEXT'" v-html="renderMarkdown(item.data)"></div>
        <div v-else-if="item.type === 'HEADER'" class="font-bold text-xl">
          {{ item.data }}
        </div>
        <div v-else-if="item.type === 'LIST'">
          <div v-for="(el, index) in dataStringParse(item.data)" :key="index" class="flex flex-row">
            <Icon name="arrow_right_alt" class="mx-1 text-blue-400" />
            <div>{{ el }}</div>
          </div>
        </div>
        <div v-else-if="item.type === 'CHECKLIST'">
          <div v-for="(el, i) in dataStringParse(item.data)" :key="i">
            <input type="checkbox" :id="'checkbox' + index + '_' + i" :value="el" v-model="checklist[i]" />
            <label class="ml-2" :for="'checkbox' + index + '_' + i">{{ el }}</label>
          </div>
        </div>
        <div v-else>{{ item.data }}</div>
        <div v-if="mode === 'Edit'" class="flex flex-row">
          <Icon name="edit" class="mx-1 text-blue-400" @click="editElement(index)" />
          <Icon name="delete" class="mx-1 text-red-400" @click="deleteElement(item.index)" />
        </div>
      </div>
    </div>
    <div v-else class="flex w-fit justify-center">
      <ModifElementComp  class="justify-center" :type="item.type" :data="item.data" @validate="validateElement" />
      <Icon name="close" class="mx-1 text-blue-400" @click="stopEditElement(index)" />
    </div>
  </div>

</template>

<script setup lang="ts">
import { defineProps, defineEmits } from 'vue';
import { useRouter } from 'vue-router';
import MarkdownIt from 'markdown-it';
import Icon from '@/components/ui/Icon.vue';
import ImageComponent from '@/components/ImageComponent.vue';
import DocComponent from '@/components/DocComponent.vue';
import ModifElementComp from '@/components/AddOrModifElementComp.vue';
import type {  elementTextImage } from '@/types/api';
import { ref } from 'vue';
import axios from 'axios';

// Define props with type annotations
const props = defineProps<{
  items: Array<elementTextImage>,
  mode: string
}>();
// Define emits
const emit = defineEmits(['delete', 'edit', 'validate']);

// Local data
const checklist = ref<string[]>([]);  // Utilisation de ref pour les données locales



const renderMarkdown = (text: string) => {
  const md = new MarkdownIt();
  return md.render(text);
};

const dataStringParse = (dataList: string) => {
  return dataList.split('|');
};

const editElement = (index: number) => {

  indexModif.value = index;
};

const stopEditElement = () => {
  indexModif.value = -1;
};

const deleteElement = (index: number) => {
  emit('delete', index);
};

const indexModif = ref<number>(-1);

const validateElement = (data: string) => {
  if (indexModif.value === -1) {
    return;
  }
  const item = props.items[indexModif.value];
  if (item.type === 'IMAGE' || item.type === 'DOCUMENT') {
    const path = item.data.split('|')[1].split('/')[1];
    axios.delete(`element/deletePath/${path}`);
  }
  emit('edit', { data: data, index: indexModif.value});
  indexModif.value = -1;
};

</script>

<style>
.texte-container {
  overflow: scroll;
  max-height: 65vh;
}

#image_txt {
  max-height: 30vh;
  margin-left: 10px;
  margin-bottom: 10px;
}
</style>
