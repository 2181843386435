<template>


    <div class="flex">
        <div class="flex flex-col items-center justify-center">
            <h1 v-if="ErrorMsgMode" class="text-xl font-bold text-center m-2">Veuillez ajouter le document avant de valider</h1>
            <h1 v-if="type == 'IMAGE'" class="text-xl font-bold text-center m-2">Ajouter votre image</h1>
            <h1 v-if="type == 'DOCUMENT'" class="text-xl font-bold text-center m-2">Ajouter votre image</h1>
            <Dropzone v-model="files" class="mb-3" />
            <Input v-if="type == 'DOCUMENT'" v-model="name" placeholder="Nom du document " />
            <Input v-if="type == 'IMAGE'" v-model="name" placeholder="Légende de l'image " />
            <Button @click="validate" class="m-3" variant="destructive"> Valider </Button>
        </div>
    </div>
</template>


<script setup lang="ts">
import { ref } from 'vue';
import Dropzone from '@/components/ui/dropzone/Dropzone.vue';
import Button from '@/components/ui/button/Button.vue';
import Input from '@/components/ui/input/Input.vue';
import axios from 'axios';
import { defineEmits, defineProps } from 'vue';
const files = ref<File[]>([]);
const ErrorMsgMode = ref<boolean>(false);

const name = ref<string>("");
const props = defineProps({
  type : {
    type: String,
    required: true,
  }
});

const emit = defineEmits(['add']);

const validate = async () => {
    if (files.value.length < 0) {
        ErrorMsgMode.value = true;
        return;
    }
    console.log(files.value[0]);
    try {
        const formData = new FormData();
        formData.append('file', files.value[0]);

        const response = await axios.post(
            'element/add-document-or-image',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );

        if (response.status === 200) {
            const path = response.data.data.path;
            console.log(path);
            emit('add', {path : path, name: name.value});
        } else {
            console.error('Erreur lors de l\'ajout de la section');
        }
    } catch (error) {
        console.error('Erreur lors de l\'envoi de la requête', error);
    }
        
}
</script>