<template>
  <Modal :isOpen="Open" @modal-close="close">
    <template #header>
      <div class="flex items-center justify-center">
        <Label v-if="props.mode == 'modif'"> Modifier le Processus {{ name }}</Label>
        <Label v-else> Créer un nouveau Processus dans {{ props.nameGroup ? props.nameGroup : "Nom pas renseigné" }}
        </Label>
        <Button @click="close" icon="close" class="ml-2"> </Button>
      </div>
    </template>
    <template #content>
      <div v-if="fillMessageMode" class="flex justify-center text-red-500">Veuillez remplir tous les champs</div>
      <div class="flex flex-col gap-4">
        <div class="flex flex-col gap-4">
          <Label>Nom du Processus</Label>
          <Input v-model="name" placeholder="Nom du Processus" />
        </div>
        <div class="flex flex-1 flex-col gap-2">
          <Label>Icone du Processus</Label>
          <IconSelection class="z-50" @select="handleSelectIcon($event)" />

        </div>
        <div v-if="props.mode != 'modif'" class="flex flex-1 flex-col gap-2">
          <Label for="gender">Type de Processus</Label>
          <Select v-model="type">
            <SelectTrigger>
              <SelectValue placeholder="Type de Processus" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup class="z-50 bg-white">
                <SelectItem value="DIAGRAM"> Diagramme </SelectItem>
                <SelectItem value="TEXT-IMAGE"> Texte, Images et Documents </SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        <div class="flex flex-col gap-4">
          <Label>Responsable du Processus</Label>
          <Input v-model="responsibles" placeholder="Responsable du Processus" />
        </div>
        <div class="flex flex-col gap-4">
          <Label>Votre Nom, Prénom</Label>
          <Input v-model="modificator" placeholder="Personne qui crée/modifie le processus (Nom Prénom)" />
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex justify-center pb-3"> <Button @click="validate()" variant="outline"> Enregistrer les
          modifications
        </Button>
      </div>

    </template>
  </Modal>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import Modal from './Modale.vue';
import Label from "@/components/ui/label/Label.vue";
import Input from "@/components/ui/input/Input.vue";
import Button from "@/components/ui/button/Button.vue";
import IconSelection from '@/components/IconSelection.vue';
import Select from "@/components/ui/select/Select.vue";
import SelectTrigger from "@/components/ui/select/SelectTrigger.vue";
import SelectContent from "@/components/ui/select/SelectContent.vue";
import SelectGroup from "@/components/ui/select/SelectGroup.vue";
import SelectItem from "@/components/ui/select/SelectItem.vue";
import SelectValue from "@/components/ui/select/SelectValue.vue";
import { useRouter } from 'vue-router';

import { defineProps, defineEmits } from 'vue';
import axios from 'axios';
import { State } from '@/stores/store'; // Assurez-vous d'importer correctement le type State depuis votre chemin
import { useStore } from 'vuex';
const props = defineProps({
  nameGroup: String,
  idGroup: Number,
  open: Boolean,
  mode: String,
  info: Object,
});

const IconName = ref('');
const IconCode = ref('');
const type = ref('');
const responsibles = ref('');
const modificator = ref('');
const ProcessId = ref(0);
const processGroupId = ref(0);
const processApprobator = ref('');
const modifDate = ref('');
const fillMessageMode = ref(false);
const router = useRouter()

const store = useStore<State>();

const init_titleBar = () => {
  store.commit('setProcessPage', {
    name: 'Création du Processus : ' + name.value || 'Pas de Nom de Page',
    icon: IconCode.value || 'error',
    modifDate: '',
    modifUser: modificator.value || 'Pas de Nom de Modificateur',
    creationDate: '',
    approbator: 'Pas approuvé encore',
    responsibles: responsibles.value || 'Pas de Responsables',
  });
};

const close = () => {
  emit('modal-close');
};

const validate = async () => {
  if (!name.value || !IconCode.value || !type.value || !responsibles.value || !modificator.value) {
    fillMessageMode.value = true;
    return;
  }
  if (props.mode === 'modif') {

    const response = await axios.put(`/process/${ProcessId.value}`, {
      process: {
        processId: ProcessId.value,
        processName: name.value,
        processIcon: IconCode.value,
        processType: type.value,
        processResponsibles: responsibles.value,
        processModificator: modificator.value,
        processApprobator: processApprobator.value,
        processGroupId: processGroupId.value,
        modifDate: modifDate.value,
      }
    });

    if (response.status === 200) {
      emit('modif')
      emit('modal-close');
    } else {
      console.error('Erreur lors de la modification de la section');
    }
    return;
  }
  else {
    const response = await axios.post('/process', {
      process: {
        processName: name.value,
        processIcon: IconCode.value,
        processType: type.value,
        processResponsibles: responsibles.value,
        processModificator: modificator.value,
        processGroupId: props.idGroup,
      }
    });

    if (response.status === 200) {
      const processId = response.data.data.processId;
      emit('modal-close');
      init_titleBar();
      if (type.value === 'TEXT-IMAGE') {
        router.push({
          path: '/addTextImage',
          query: { id: processId }
        })
      }
      else if (type.value === 'DIAGRAM') {
        router.push({
          path: '/addDiagramProcessus',
          query: { id: processId }
        })
      }

    } else {
      console.error('Erreur lors de l\'ajout de la section');
    }
  }
};

const emit = defineEmits(['add', 'modal-close', 'modif']);

const name = ref("");
const Open = ref(props.open);

watch(() => props.open, (value) => {
  try {
    Open.value = value;
  } catch (error) {
    console.error('Erreur dans le watcher:', error);
  }
});

watch(() => props.info, (value) => {
  if (value) {
    name.value = value.processName;
    IconCode.value = value.processIcon;
    responsibles.value = value.processResponsibles;
    modificator.value = value.processGroupModificator;
    processGroupId.value = value.processGroupId;
    type.value = value.processType;
    processApprobator.value = value.processApprobator;
    modifDate.value = value.modifDate;
    ProcessId.value = value.processId;
  }
});

const handleSelectIcon = (icon: { IconName: string, IconCode: string }) => {
  IconName.value = icon.IconName;
  IconCode.value = icon.IconCode;
  // Close the popover after selection to avoid recursive updates
}


</script>

<style>
.dialog-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.dialog-content {
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  max-height: 80vh;
  max-width: 90%;
  overflow-y: auto;
}
</style>
