<template>
  <Return @Click-close="goBack" />
  <div v-if="!emptyGroup">
    <Button variant="outline" v-if="!modifMode && (role == 'approbator' || role == 'modificator')" icon="edit"
      class="m-3" @click="modif()"> Modifier les
      Groupes/Processus</Button>

    <div v-if="modifMode && (role == 'approbator' || role == 'modificator')" class="flex flex-wrap gap-2 m-3">
      <Button v-if="!supprMode" variant="outline" icon="add" @click="addNewGroup()"> Nouveau Groupe</Button>
      <Button v-if="!supprMode" variant="outline" icon="edit" @click="modifInfoFct()"> Modifier les infos d'un
        Groupe ou d'un Processus</Button>
      <Button v-if="!supprMode" variant="outline" icon="add" @click="addProcessus()"> Nouveau Processus</Button>

      <Button v-if="!supprMode" variant="outline" icon="delete" @click="suppr()"> Passer en mode
        Suppression</Button>
      <Button v-if="supprMode" variant="outline" icon="cancel" @click="suppr()"> Repasser en mode
        classique</Button>
      <Button variant="outline" icon="cancel" @click="modif()"> Arreter Modification</Button>
    </div>
    <categories-component :items="items" :mode="ModeSelection" @edit="selectEdit($event)" />
  </div>
  <div v-if="emptyGroup" class="text-center text-muted-foreground mt-4">
    <p>Il n'y a pas de Processus ou groupe de Processus dans ce groupe</p>
    <p v-if="role == 'approbator' || role == 'modificator'">Pour en ajouter un :</p>
    <div class="flex gap-2 justify-center m-4" v-if="role == 'approbator' || role == 'modificator'">
      <Button @click="addProcessus()" variant="outline" icon="add">Ajouter un Processus</Button>
      <Button @click="addNewGroup()" variant="outline" icon="add">Ajouter un Groupe de Processus</Button>
    </div>
  </div>
  <ModalProcessGroup :open="addGroupMode" @modal-close="addNewGroup" @add="refresh" />
  <ModalProcessGroup :open="openModifInfo" type="modif" @modal-close="openModifModal" @modif="refresh"
    :info="processGroupSelected" />
  <ModalProcess :open="addProcessusMode" @modal-close="addProcessus" @add="refresh"
    :nameGroup="processGroup?.processGroupName" :idGroup="processGroupId" />
  <ModalProcess :open="openModifInfoProcessus" mode="modif" @modal-close="openModifModalProcessus" @modif="refresh"
    :info="processSelected" />
</template>

<script lang="ts" setup>
import { ref, onMounted, watch } from 'vue'
import axios from 'axios'
import { useRoute, useRouter } from 'vue-router'
import type { ProcessGroup, inCategory, Process } from '@/types/api'
import CategoriesComponent from "@/components/MenuProcessusGroup.vue";
import Return from "@/components/buttonRetour.vue";
import Button from '@/components/ui/button/Button.vue'
import ModalProcessGroup from '@/components/ModalProcessGroup.vue';
import ModalProcess from '@/components/ModalProcess.vue';
const route = useRoute()
const processGroupId = ref<number>(0)
if (route.query.id && typeof route.query.id === 'string') {
  processGroupId.value = parseInt(route.query.id)
}
else {
  throw new Error('Invalid processGroupId')
}
// Utilisation du router pour la navigation
const router = useRouter()

// Méthode pour revenir en arrière
const goBack = () => {
  router.go(-1)
}

interface item {
  name: string
  icon: string
  text: string
  type: string
  id: number
}

const modifMode = ref<boolean>(false)
const addGroupMode = ref<boolean>(false)
const supprMode = ref<boolean>(false)
const addProcessusMode = ref<boolean>(false)
const openModifInfo = ref<boolean>(false)
const openModifInfoProcessus = ref<boolean>(false)
const ModeSelection = ref<string>('view')
const processGroupSelected = ref<ProcessGroup>()
const processSelected = ref<Process>()



const modif = () => {
  modifMode.value = !modifMode.value
  if (!modifMode.value) {
    ModeSelection.value = 'view'
    supprMode.value = false
    addGroupMode.value = false
    addProcessusMode.value = false
  }
}
const addNewGroup = () => {
  addGroupMode.value = !addGroupMode.value
}
const addProcessus = () => {
  addProcessusMode.value = !addProcessusMode.value
}

const modifInfoFct = () => {
  ModeSelection.value = 'edit'
}


const openModifModal = () => {
  openModifInfo.value = !openModifInfo.value
}

const openModifModalProcessus = () => {
  openModifInfoProcessus.value = !openModifInfoProcessus.value
}

const selectEdit = async (item: inCategory) => {
  if (item.type === 'processGroup') {
    processGroupSelected.value = await getProcessGroup(item.id)
    openModifModal()
  }
  if (item.type === 'process') {
    processSelected.value = await getProcess(item.id)
    openModifModalProcessus()
  }
}
const suppr = () => {
  supprMode.value = !supprMode.value
}

const refresh = async () => {
  addGroupMode.value = false
  if (ModeSelection.value === 'edit') {
    ModeSelection.value = "view"
    processGroupSelected.value = undefined
    processSelected.value = undefined
  }
  await getData()
}


const items = ref<Record<string, item>>({})
const processGroup = ref<ProcessGroup>()
const emptyGroup = ref(false)


const getProcessGroup = async (id: number) => {
  try {
    const processGroupResponse = await axios.get(
      `/processGroup/${id}`
    )
    return processGroupResponse.data.data.processGroup
  } catch (error) {
    console.error('Failed to fetch data:', error)
  }
}

const getProcess = async (id: number) => {
  try {
    const processResponse = await axios.get(
      `/process/${id}`
    )
    return processResponse.data.data.process
  } catch (error) {
    console.error('Failed to fetch data:', error)
  }
}

const getData = async () => {
  try {
    const processGroupResponse = await axios.get(
      `/processGroup/${processGroupId.value}`
    )

    processGroup.value = processGroupResponse.data.data.processGroup

    const inGroupResponse = await axios.get(
      `/processGroup/${processGroupId.value}/inGroup`
    )
    items.value = inGroupResponse.data.data.combinedItems

    if (inGroupResponse.data.data.combinedItems.length === 0) {
      emptyGroup.value = true
      console.log('Empty group')
    }
    else {
      emptyGroup.value = false
    }

  } catch (error) {
    console.error('Failed to fetch data:', error)
  }
}

import { useStore } from 'vuex';
import { State } from '@/stores/store'; // Importer le type State

const store = useStore<State>();



const init_titleBar = () => {
  store.commit('setMenuPage',
    {
      name: processGroup.value?.processGroupName || 'Pas de Nom de Page',
      icon: processGroup.value?.processGroupIcon || 'error'
    });
};

import { useAuthStore } from '@/stores/authStore';
const role = ref('')
const verif_role = () => {
  role.value = useAuthStore().getRole()
}

onMounted(async () => {
  await getData()
  init_titleBar()
  verif_role()
})


watch(() => route.query.id, async () => {
  if (typeof route.query.id === 'string') {
    processGroupId.value = parseInt(route.query.id)
    await getData()
    init_titleBar()
  } else {
    console.error('Invalid processGroupId in route query')
  }
})
</script>