import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/styles.css';
import store from './stores/store';
import { createPinia } from 'pinia';
import 'material-symbols';
// Créer une instance de Pinia
const pinia = createPinia()
const app = createApp(App);
app.use(store);
app.use(router);
app.use(pinia)
/* Configuration d'axios */


import axios from 'axios'
axios.defaults.baseURL = 'https://wikix.junior-aei.com/api';
app.mount('#app');
