<template>
    <div class="w-auto m-3">
        <div v-if="type == 'HEADER' || type == 'TEXT' || type == 'MARKDOWN-TEXT'" class="flex flex-col gap-2">
            <Textarea id="description" v-model="text" placeholder="Texte de la case" />
            <Button @click="validateText" variant="destructive"> Valider </Button>
        </div>
        <div v-if="type == 'LIST' || type == 'CHECKLIST'" class="flex flex-col gap-2">
            <div v-for="(el, index) in list" :key="index" class="flex flex-row gap-2">
                <Input v-model="list[index]" placeholder="Texte de la case" />
                <Button @click="removeList(index)" variant="destructive"> Supprimer </Button>
            </div>
            <Button @click="addLineList" variant="destructive"> Ajouter Ligne </Button>
            <Button v-if="!addChecklistMode" @click="checkList" variant="destructive"> Activer le mode Checklist
            </Button>
            <Button v-if="addChecklistMode" @click="checkList" variant="destructive"> Desactiver le mode Checklist
            </Button>
            <Button @click="validateList" variant="destructive"> Valider </Button>
        </div>
        <AddImageDocComp class="justify-center" v-if="type == 'IMAGE'" :type="type" @add="validateDocOrImage($event)" />
        <AddImageDocComp class="justify-center" v-if="type == 'DOCUMENT'" :type="type" @add="validateDocOrImage($event)" />
    </div>

</template>


<script setup lang="ts">
import Textarea from "@/components/ui/textarea/Textarea.vue";
import Input from "@/components/ui/input/Input.vue";
import AddImageDocComp from "@/components/AddImageDocComp.vue";
import Button from "@/components/ui/button/Button.vue";
import { ref, defineProps, defineEmits, onMounted, watch } from 'vue';

const list = ref<string[]>([]);
const text = ref<string>("");
const addChecklistMode = ref<boolean>(false);

const props = defineProps({
    type: {
        type: String,
        required: true,
    },
    data: {
        type: String,
        required: true,
    }
});


const addLineList = () => {
    list.value.push("");
};

const removeList = (index: number) => {
    list.value.splice(index, 1);
};
const checkList = () => {
    addChecklistMode.value = !addChecklistMode.value;
};
const emit = defineEmits(['validate', 'checklistchangevalidate']);


const validateDocOrImage = (data: { path: string, name: string }) => {
    emit("validate", data.name + '|' + data.path)
};

const validateText = () => {
    emit("validate", text.value)
};

const validateList = () => {
    if ((props.type === 'CHECKLIST' && addChecklistMode.value === false)
        || (props.type === 'LIST' && addChecklistMode.value === true)) {
        emit("checklistchangevalidate", { addChecklistMode: addChecklistMode.value, list: list.value.join('|') })
    } else {
        emit("validate", list.value.join('|'))
    }
};


const initialize = () => {

    if (props.type == 'LIST' || props.type == 'CHECKLIST') {
        list.value = props.data.split('|');
        if (props.type === 'CHECKLIST') {
            addChecklistMode.value = true;
        }
    } else {
        text.value = props.data;
    }

};

onMounted(() => {
    initialize();
});


watch(() => props, () => {
    initialize();
});
</script>