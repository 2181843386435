<template>
    <div :style="{
        top: `${rect.topPosition}px`, left: `${rect.leftPosition}px`, width: rect.width + 'px',
        height: rect.height + 'px', position: 'absolute', backgroundColor: props.rect.backgroundColor
    }" :class="rectangleClasses" @click="handleClick">
        <div class="center">
            {{ rect.text }}
        </div>
    </div>
    <div v-if="rect.taskResponsibles" :style="{
        position: 'absolute', top: `${rect.topPosition + rect.height}px`, left: `${rect.leftPosition + rect.width / 2 - rect.widthTaskResponsibles / 2}px`, width: rect.widthTaskResponsibles + 'px',
        height: rect.heightTaskResponsibles + 'px',
    }" class=" centerTaskResponsibles bg-gray-300 border-r-2 border-l-2 border-b-2 border-gray-500">
        <div class="center">
            {{ rect.taskResponsibles }}
        </div>
    </div>
</template>


<script setup lang="ts">
import { defineProps, onMounted, ref, defineEmits } from 'vue';
import type { rectangle } from '@/types/api';


interface rectangleMore extends rectangle {
    width: number;
    height: number;
    isSelected: boolean;
    heightTaskResponsibles: number;
    widthTaskResponsibles: number;
}




const props = defineProps({
    rect: {
        type: Object as () => rectangleMore,
        required: true,
    }
});

const rectangleClasses = ref<string>('');

const rectangleClassesInit = () => {
    rectangleClasses.value = 'relative border-2 border-black';
    if (props.rect.isSelected) {
        rectangleClasses.value += ' border-2 border-red-500';
    }
    if (props.rect.textStyle != 'normal') {
        rectangleClasses.value += ' ' + props.rect.textStyle;
    }
    if (props.rect.rectangleStyle != 'normal') {
        rectangleClasses.value += ' ' + props.rect.rectangleStyle;
    }

}

const emit = defineEmits(['select']);


const handleClick = () => {
    emit('select');

}


onMounted(() => {
    rectangleClassesInit();
});


</script>

<style>
.center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    font-family: "Inter-Bold";
    white-space: pre;
    text-align: center;
}
</style>