
<template>
  <div class="bg-white shadow dark:bg-gray-800 items-end text-center p-1">
    <div class="flex-row">
      <h1 class="text-xl font-bold text-gray-900">Wikix</h1>
      <p>Site des Processus de AEI</p>
    </div>
    <p class="text-l text-gray-600 mt-1">
      Contactez le Pole Qualité pour les ajouts/modifications de processus, le
      Pole DSI pour avoir les droits de modifs et les problèmes techniques ou
      Mathieu CHAILLON pour les soucis niveau code
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style>
/* Ajoutez ici les styles spécifiques au composant de titre si nécessaire */
</style>
