<template>
  <span class="material-symbols-rounded ">
    {{ name }}
  </span>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    }
  }
}
</script>

<style>
.material-symbols-rounded {
  font-variation-settings:
    'FILL' 0,
    'wght' 300,
    'GRAD' 0,
    'opsz' 24;
  font-size: 140%;
}

.inherit-font-size {
  font-size: inherit;
}
</style>
