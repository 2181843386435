<template>
  <div ref="componentRef" class="superposed-container">



    <!-- SVG Drawing -->
    <svg class="svg-drawing">
      <!-- Boucle pour ajouter plusieurs flèches -->
      <Arrow v-for="(link, index) in links" :key="'arrow-' + index" :componentInfo="componentInfo"
        :rectangle1="findRectangleById(link.rectangle1Id)" :rectangle2="findRectangleById(link.rectangle2Id)"
        :Side1="link.rectangle1Side" :Side2="link.rectangle2Side" :text="link.text" />
    </svg>
    <!-- Boucle pour ajouter plusieurs rectangles de texte -->
    <Rectangle v-for="(rect, index) in rectanglesTreated" :key="'rect-' + index" :rect="rect"
      @select="selectRectangle(rect)" />

    <ModalComponent :isOpen="isModalOpened" @modal-close="closeModal" name="first-modal" class="z-50">
      <template #header>
        <div class="text-center font-bold">{{ titleModal }}</div>
      </template>
      <template #content>
        <AllType :items="items" mode="View" />
      </template>
      <template #footer><button class="flex m-2 space-x-1 text-blue-500" @click="closeModal">
          <span>Retour au processus</span>
          <Icon name="arrow_right_alt" class="mx-1 text-blue-400" />

        </button></template>
    </ModalComponent>
  </div>





</template>

<script setup lang="ts">
import { ref, defineProps, onMounted, watch, onBeforeUnmount } from 'vue';
import Rectangle from "@/components/diagram/RectangleComp.vue";
import Arrow from "@/components/diagram/Arrow.vue";
import ModalComponent from "@/components/Modale.vue";
import { rectangle, linkInterface } from '@/types/api';
import AllType from "@/components/AllTypeComponent.vue";
import Icon from "@/components/ui/Icon.vue";

const props = defineProps({
  rectangles: {
    type: Array as () => rectangle[],
    required: true,
  },
  links: {
    type: Array as () => linkInterface[],
    required: true,
  },
});


const titleModal = ref<string>('');
const isModalOpened = ref(false);
const openModale = () => {
  isModalOpened.value = true;
};

const closeModal = () => {
  isModalOpened.value = false;
};

interface rectangleMore extends rectangle {
  width: number;
  height: number;
  isSelected: boolean;
  heightTaskResponsibles: number;
  widthTaskResponsibles: number;
}
const rectanglesTreated = ref<rectangleMore[]>([]);



const treatRectangles = () => {
  console.log('treatRectangles');
  console.log(props.rectangles);
  const rectanglesInfo = ref<rectangle[]>(props.rectangles);
  rectanglesTreated.value = rectanglesInfo.value.map((rectangle) => {
    const sizeAndText = calculateSizeOfRectangle(rectangle.text);
    const size = {
      width: sizeAndText.width,
      height: sizeAndText.height,
    };
    const position = calculatePositionOfRectangle(rectangle, size);
    let sizeResponsibles = {
      width: 0,
      height: 0,
      text: ""
    };
    if (rectangle.taskResponsibles) {
      sizeResponsibles = calculateSizeOfRectangle(rectangle.taskResponsibles);
    }

    return {
      topPosition: position.top,
      leftPosition: position.left,
      width: size.width,
      height: size.height,
      text: sizeAndText.text,
      rectangleId: rectangle.rectangleId,
      taskResponsibles: sizeResponsibles.text,
      widthTaskResponsibles: sizeResponsibles.width || 0,
      heightTaskResponsibles: sizeResponsibles.height || 0,
      backgroundColor: rectangle.backgroundColor,
      textStyle: rectangle.textStyle,
      rectangleStyle: rectangle.rectangleStyle,
      typeExternalLink: rectangle.typeExternalLink,
      dataExternalLink: rectangle.dataExternalLink,
      processId: rectangle.processId,
      isSelected: false,
    };
  });

};


const calculateSizeOfRectangle = (text: string) => {

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (!context) {
    console.error('Canvas context not available');
    return {
      width: 0,
      height: 0,
      text: "",
    };
  } else {
    context.font = '14px Inter-Bold';
    text = text.replace('\n', ' ')
    console.log(text);
    const textWidth = context.measureText(text).width;
    const textHeight = 14;
    const rectangleWidthMax = 200;
    if (textWidth < rectangleWidthMax) {
      if (textWidth < 50) {
        return {
          width: 50 + 10,
          height: textHeight + 10,
          text: text,
        };
      } else {
        return {
          width: Math.min(textWidth, rectangleWidthMax) + 10,
          height: textHeight + 10,
          text: text,
        };
      }
    }
    const words = text.split(' ');
    let numberReturnLine = 0;

    const lines = [];
    let currentLine = '';
    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      if (word.replace(' ', '').length == 0) {
        continue;
      }
      if (word.split('\n').length > 1) {
        numberReturnLine += word.split('\n').length - 1;
      }
      const widthWithWord = context.measureText(currentLine + ' ' + word).width;
      if (widthWithWord < rectangleWidthMax) {
        currentLine += ' ' + word;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    }
    lines.push(currentLine);
    console.log(lines);
    const rectangleHeight = (textHeight + 10) * (lines.length + numberReturnLine);
    text = lines.join('\n');
    return {
      width: rectangleWidthMax,
      height: rectangleHeight,
      text: text,
    };
  }
};

const calculatePositionOfRectangle = (rectangle: rectangle, size: { height: number, width: number }) => {
  console.log(componentInfo.value);
  return {
    top: rectangle.topPosition - size.height / 2,
    left: rectangle.leftPosition + componentInfo.value.width / 2 - size.width / 2,
  };
};


const findRectangleById = (rectangleId: number) => {
  return rectanglesTreated.value.find((rect) => rect.rectangleId == rectangleId);
};

import { useRouter } from 'vue-router'
const router = useRouter()
const selectRectangle = async (rectangle: rectangleMore) => {
  if (rectangle.typeExternalLink == 'EXTERNAL_WEBSITE') {
    const url = rectangle.dataExternalLink;

    if (url) {
      window.open(url, '_blank'); // Ouvre le lien dans un nouvel onglet
    } else {
      console.error('URL externe manquante ou non valide');
    }
  }
  if (rectangle.typeExternalLink == 'OTHER_PROCESS') {
    router.push({
      path: '/process',
      query: { id: rectangle.dataExternalLink.toString() }
    })
  }
  if (rectangle.typeExternalLink == 'MORE_INFORMATION') {
    await getContentModalData(rectangle.rectangleId);
    titleModal.value = rectangle.text;
    openModale();

  }
};

import axios from 'axios';
import type { elementTextImage } from '@/types/api';
const items = ref<elementTextImage[]>([]);
const getContentModalData = async (rectId: number) => {
  try {
    const contentRectResponse = await axios.get(
      `/rectangle/${rectId}/content`
    );
    console.log(contentRectResponse.data.data.content);

    items.value = contentRectResponse.data.data.content;
    items.value.sort((a: elementTextImage, b: elementTextImage) => a.index - b.index);
    console.log(items.value);

  }

  catch (error) {
    console.error('Failed to fetch data:', error);
  }
};


interface compInfo {
  width: number;
  height: number;
  top: number;
  left: number;
}
const componentInfo = ref<compInfo>({
  width: 0,
  height: 0,
  top: 0,
  left: 0,
});
const componentRef = ref<HTMLElement | null>(null);

const updateComponentInfo = () => {
  if (componentRef.value) {
    requestAnimationFrame(() => {
      if (componentRef.value) {
        const rect = componentRef.value.getBoundingClientRect();
        componentInfo.value.width = rect.width;
        componentInfo.value.height = rect.height;
        componentInfo.value.top = rect.top;
        componentInfo.value.left = rect.left;
        treatRectangles();
      }
    });
  }
};

onMounted(async () => {
  updateComponentInfo();

  const resizeObserver = new ResizeObserver(updateComponentInfo);

  if (componentRef.value) {
    resizeObserver.observe(componentRef.value);
  }

  onBeforeUnmount(() => {
    resizeObserver.disconnect();
  });

  treatRectangles();

})

watch(() => props.rectangles, () => {
  treatRectangles();
})




</script>
<style scoped>
.superposed-container {
  position: relative;
  width: 100%;
  /* Ajustez la taille du conteneur selon vos besoins */
  height: 100%;
  /* Bordure autour de la zone */
  overflow: auto;
  /* Ajout des barres de défilement */
  padding: 10px;
  /* Marge intérieure */
  box-sizing: border-box;
  /* Inclure le padding et la bordure dans la taille totale */
}

.svg-drawing {
  width: 100%;
  height: 100%;
}

.invisible-rectangle {
  position: absolute;
  width: 1px;
  height: 1px;
}
</style>