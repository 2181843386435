<template>
  <Modal :isOpen="Open" @modal-close="close">
    <template #header>
      <div class="flex items-center justify-center">
        <Label> Modifier le Texte du lien </Label>
        <Button @click="close" icon="close" class="ml-2"> </Button>
      </div>
    </template>
    <template #content>
      <div class="flex flex-col gap-4">
        <div class="flex flex-col gap-4">
          <Label>Texte du lien</Label>
          <Input v-model="text" placeholder="Texte du lien" />
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex justify-center pb-3"> <Button @click="validate()" variant="outline"> Enregistrer le texte du lien
        </Button>
      </div>

    </template>
  </Modal>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import Modal from '@/components/Modale.vue';
import Label from "@/components/ui/label/Label.vue";
import Input from "@/components/ui/input/Input.vue";
import Button from "@/components/ui/button/Button.vue";

import { defineProps, defineEmits } from 'vue';
const props = defineProps({
  text : {
    type: String,
    required: true,
  },
  open: Boolean,
});

const Open = ref(props.open);
const text = ref(props.text);

const validate = () => {
  emit('modif', text.value);
  close();
};


const close = () => {
  emit('modal-close');
};

const emit = defineEmits(['modal-close','modif']);


watch(() => props.open, (value) => {
  try {
    Open.value = value;
  } catch (error) {
    console.error('Erreur dans le watcher:', error);
  }
});
</script>

<style>
.dialog-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.dialog-content {
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  max-height: 80vh;
  max-width: 90%;
  overflow-y: auto;
}
</style>
