import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import AccueilRoutes from './accueil';
import { useAuthStore } from '@/stores/authStore'



import HomeView from '../views/HomeView.vue'
import LoginView from '@/views/LoginView.vue';
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Accueil',
    component: HomeView,
    meta: {
      icon: 'home',
      inSidebar : true
    }
  },
  {
    path:'/login',
    name:'Connexion',
    component: LoginView,
    meta:{
      inSidebar : false
    }
  },
  ...AccueilRoutes
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})



// Guard de navigation pour vérifier l'authentification
router.beforeEach((to, from, next) => {
  const authStore = useAuthStore() // Récupérer le store d'authentification
  const publicRoute = '/login' // Route publique

  // Si la route n'est pas /login et que l'utilisateur n'est pas authentifié
  if (to.path !== publicRoute && !authStore.isAuthenticated) {
    return next({ path: publicRoute }) // Redirige vers /login
  }

  // Sinon, continue la navigation normalement
  next()
})

export default router
