<template>
  <Collapsible class="flex w-full flex-col gap-2" v-model:open="isOpen">
    <div class="flex w-full items-center justify-between">
      <slot name="parent"></slot>
      <CollapsibleTrigger as-child>
        <button :class="toRotate ? 'transform rotate-90 material-symbols-rounded text-xl  text-blue-400' : 'transform rotate-180 material-symbols-rounded text-xl  text-blue-400'  " @click="rotate">
        chevron_right
      </button>
      </CollapsibleTrigger>
    </div>
    <CollapsibleContent class="gap-2 pb-1 pl-3 text-sm">
      <slot name="children"></slot>
    </CollapsibleContent>
  </Collapsible>
</template>

<script>
import Collapsible from "@/components/ui/collapsible/Collapsible.vue"
import CollapsibleTrigger from "@/components/ui/collapsible/CollapsibleTrigger.vue" // Correct the import path
import CollapsibleContent from "@/components/ui/collapsible/CollapsibleContent.vue" // Correct the import path
import { ref } from 'vue'

export default {
  data() {
    return {
      toRotate : false
    }
  },
  methods:{
    rotate(){
      this.toRotate = !this.toRotate
      console.log("PPPP")
    }
  },
  components: {
    Collapsible,
    CollapsibleTrigger,
    CollapsibleContent,
  },
  setup() {
    const isOpen = ref(false);

    return {
      isOpen
    };
  }
}
</script>

<style scoped>
/* Vos styles ici */
</style>
