<template>
  <div class=" flex items-center w-full justify-between">
    <!-- Partie gauche avec icône et nom -->
    <div class=" flex items-center ml-7">
      <Icon :name=icon class="mr-1" />
      <span class="text-2xl font-bold"> {{ name }}</span>

    </div>

    <!-- Partie droite avec informations secondaires -->
    <div class="flex flex-col items-end gap-1 mr-10" v-if="type == 'process'">
      <span>Processus Créé le : {{ creationDateFormatted }}</span>
      <span>
        Modifié le : {{ modifDateFormatted }} par {{ modifUser }}
        <span v-if="approbator === 'Not Approved'">(Pas encore approuvé)</span>
        <span v-else>(Approuvé par {{ approbator }})</span>
      </span>
      <span>Acteurs responsables : {{ responsibles }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useStore } from 'vuex';
import { computed } from 'vue';
import Icon from './Icon.vue';
import { State } from '@/stores/store'; // Assurez-vous d'importer correctement le type State depuis votre chemin

const store = useStore<State>();

const name = computed(() => store.state.name);
const icon = computed(() => store.state.icon);
const type = computed(() => store.state.type);
const modifDate = computed(() => store.state.modifDate);
const modifUser = computed(() => store.state.modifUser);
const creationDate = computed(() => store.state.creationDate);
const approbator = computed(() => store.state.approbator);
const responsibles = computed(() => store.state.responsibles);

const modifDateFormatted = computed(() => {
  const date = new Date(modifDate.value);
  return date.toLocaleDateString();
});

const creationDateFormatted = computed(() => {
  const date = new Date(creationDate.value);
  return date.toLocaleDateString();
});

// Vous n'avez pas besoin de définir de retour explicite avec setup()
// car les variables exportées seront automatiquement disponibles dans le composant.
</script>
<style>
/* Ajoutez ici les styles spécifiques au composant de titre si nécessaire */
</style>
