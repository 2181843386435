<template>
  <div>


    <div class="flex justify-center mb-10">
      <div class="max-w-screen-lg">
        <div class="flex flex-wrap justify-center gap-5">
          <div v-for="(item, index) in items" :key="index" @click="handleClick(item)"
            class="bg-gray-50 rounded-lg border-r border-b border-gray-300 border-solid hover:bg-gray-300 hover:text-blue-800 flex flex-col gap-1 text-center justify-center items-center h-auto w-80 p-4">

            <div class="flex gap-2 items-center ">
              <Icon v-if="item.icon" :name="item.icon" class="-m-1 text-blue-400" />
              <h1 class="font-bold text-2xl text-blue-400 mb-4">{{ item.name }}</h1>
            </div>

            <h2 v-if="item.text" class="text-l border-gray-300 text-gray-700 ">
              {{ item.text }}
            </h2>
            <div v-if="item.responsibles" class=" flex flex-col rounded-lg border bg-gray-200 p-2 w-60">
              <div class="flex flex-row items-center justify-center -m-4">

                <h2 class="flex flex-row items-center ">
                  <Icon name="person" />Responsables :
                </h2>
              </div>
              <h3 class="w-full">{{ item.responsibles }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>




<script lang="ts" setup>
import { defineProps, defineEmits } from 'vue'
import { useRouter } from 'vue-router'
import Icon from "@/components/ui/Icon.vue"
import type { inCategory } from '@/types/api'

// Définition des props
const props = defineProps({
  items: {
    type: Object,
    required: true,
  },
  mode : {
    type: String,
    required: false,
  }
})

// Définition des évenements
const emit  = defineEmits(['edit'])
// Utilisation du router pour la navigation
const router = useRouter()

const handleClick = (item: inCategory) => {
  if (props.mode === 'edit' ){
    emit('edit', item)
    return
  }
  if (item.type === 'processGroup') {
    router.push({

      path: '/processGroup',
      query: { id: item.id.toString() }
    })
  }
  if (item.type === 'process') {
    router.push({
      path: '/process',
      query: { id: item.id.toString() }
    })
  }
}


</script>


<style scoped>
.grid {
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 16px;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.grid-item {
  background-color: #3369e7;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

.grid-item h1 {
  font-size: 24px;
  margin: 0;
}

.grid-item h2 {
  padding: 20px;
  font-size: 16px;
  margin: 0;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.blue-button {
  background-color: white;
  color: blue;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  align-self: center;
  margin-top: 10px;
  cursor: pointer;
}

.div_txt {
  font-family: "Inter-Regular", sans-serif;
  padding: 20px;
  color: white;
  text-align: center;
  height: 300px;
}

h1 {
  font-size: 24px;
  margin: 0;
}

h2 {
  padding: 20px;
  font-size: 16px;
  margin: 0;
}
</style>
