<template>
    
    <svg v-if="rectangle1 && rectangle2 && modeArrow == 'normal'" @click="select">
        <!-- Ligne principale -->
        <line :x1="x1" :y1="y1" :x2="x2" :y2="y2" stroke="black" stroke-width="1" />
        <!-- Pointe de flèche -->
        <line :x1="arrowHead.x1" :y1="arrowHead.y1" :x2="arrowHead.x2" :y2="arrowHead.y2" stroke="black"
            stroke-width="1" />
        <line :x1="arrowHead.x1" :y1="arrowHead.y1" :x2="arrowHead.x3" :y2="arrowHead.y3" stroke="black"
            stroke-width="1" />
        <rect :x="(x1 + x2 - textSize.width) / 2" :y="(y1 + y2 - textSize.height) / 2" :width="textSize.width"
            :height="textSize.height" fill="white" stroke="none" />
        <text :x="(x1 + x2) / 2" :y="(y1 + y2) / 2" fill="black" text-anchor="middle" dominant-baseline="middle">{{ text
            }}</text>
    </svg>
    <svg v-else-if="rectangle1 && rectangle2 && (modeArrow == 'sameSideLeft' || modeArrow == 'sameSideRight')" @click="select">
        <!-- Ligne principale -->
        <line :x1="x1" :y1="y1" :x2="x1 + decalage" :y2="y1" stroke="black" stroke-width="1" />
        <line :x1="x1 + decalage" :y1="y1" :x2="x1 + decalage" :y2="y2" stroke="black" stroke-width="1" />
        <line :x1="x1 + decalage" :y1="y2" :x2="x2" :y2="y2" stroke="black" stroke-width="1" />
        <!-- Pointe de flèche -->
        <line :x1="arrowHead.x1" :y1="arrowHead.y1" :x2="arrowHead.x2" :y2="arrowHead.y2" stroke="black"
            stroke-width="1" />
        <line :x1="arrowHead.x1" :y1="arrowHead.y1" :x2="arrowHead.x3" :y2="arrowHead.y3" stroke="black"
            stroke-width="1" />
        <rect :x="x1 + decalage - (textSize.width) / 2" :y="(y1 + y2 - textSize.height) / 2" :width="textSize.width"
            :height="textSize.height" fill="white" stroke="none" />

        <text :x="x1 + decalage" :y="(y1 + y2) / 2" fill="black" text-anchor="middle" dominant-baseline="middle">{{ text
            }}</text>
    </svg>
    <svg v-else-if="rectangle1 && rectangle2 && (modeArrow == 'sameSideTop' || modeArrow == 'sameSideBottom')" @click="select">
        <!-- Ligne principale -->
        <line :x1="x1" :y1="y1" :x2="x1" :y2="y1 + decalage" stroke="black" stroke-width="1" />
        <line :x1="x1" :y1="y1 + decalage" :x2="x2" :y2="y1 + decalage" stroke="black" stroke-width="1" />
        <line :x1="x2" :y1="y1 + decalage" :x2="x2" :y2="y2" stroke="black" stroke-width="1" />
        <!-- Pointe de flèche -->
        <line :x1="arrowHead.x1" :y1="arrowHead.y1" :x2="arrowHead.x2" :y2="arrowHead.y2" stroke="black"
            stroke-width="1" />
        <line :x1="arrowHead.x1" :y1="arrowHead.y1" :x2="arrowHead.x3" :y2="arrowHead.y3" stroke="black"
            stroke-width="1" />
        <rect :x="(x1 + x2 - textSize.width) / 2" :y="y1 + decalage - (textSize.height) / 2" :width="textSize.width"
            :height="textSize.height" fill="white" stroke="none" />
        <text :x="(x1 + x2) / 2" :y="y1 + decalage" fill="black" text-anchor="middle" dominant-baseline="middle">{{ text
            }}</text>

    </svg>



</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { defineProps } from 'vue';
import type { rectangle } from '@/types/api';

interface rectangleMore extends rectangle {
    width: number;
    height: number;
    isSelected: boolean;
    heightTaskResponsibles: number;
    widthTaskResponsibles: number;
}
interface compInfo {
    width: number;
    height: number;
    top: number;
    left: number;
}
const props = defineProps({
    rectangle1: {
        type: Object as () => rectangleMore | undefined,
        required: true,
    },
    rectangle2: {
        type: Object as () => rectangleMore | undefined,
        required: true,
    },
    Side1: {
        type: String,
        required: true,
    },
    Side2: {
        type: String,
        required: true,
    },
    text: {
        type: String,
        required: true,
    },
    componentInfo: {
        type: Object as () => compInfo,
        required: true,
    },
});

const x1 = ref(0);
const y1 = ref(0);
const x2 = ref(0);
const y2 = ref(0);
const modeArrow = ref('normal');
const decalage = ref(30);

const treatLinkXY = () => {
    if (!props.rectangle1 || !props.rectangle2) {
        return;
    }
    if (props.Side1 == 'top') {
        x1.value = props.rectangle1.leftPosition + props.rectangle1.width / 2 - 10;
        y1.value = props.rectangle1.topPosition - 10;
    } else if (props.Side1 == 'bottom') {
        x1.value = props.rectangle1.leftPosition + props.rectangle1.width / 2 - 10;
        y1.value = props.rectangle1.topPosition + props.rectangle1.height - 10 + props.rectangle1.heightTaskResponsibles;
    } else if (props.Side1 == 'left') {
        x1.value = props.rectangle1.leftPosition - 10;
        y1.value = props.rectangle1.topPosition + props.rectangle1.height / 2 - 10;
    } else if (props.Side1 == 'right') {
        x1.value = props.rectangle1.leftPosition + props.rectangle1.width - 10;
        y1.value = props.rectangle1.topPosition + props.rectangle1.height / 2 - 10;
    }

    if (props.Side2 == 'top') {
        x2.value = props.rectangle2.leftPosition + props.rectangle2.width / 2 - 10;
        y2.value = props.rectangle2.topPosition - 10;
    } else if (props.Side2 == 'bottom') {
        x2.value = props.rectangle2.leftPosition + props.rectangle2.width / 2 - 10;
        y2.value = props.rectangle2.topPosition + props.rectangle2.height - 10 + props.rectangle2.heightTaskResponsibles;
    } else if (props.Side2 == 'left') {
        x2.value = props.rectangle2.leftPosition - 10;
        y2.value = props.rectangle2.topPosition + props.rectangle2.height / 2 - 10;
    } else if (props.Side2 == 'right') {
        x2.value = props.rectangle2.leftPosition + props.rectangle2.width - 10;
        y2.value = props.rectangle2.topPosition + props.rectangle2.height / 2 - 10;
    }


    if (props.Side1 == props.Side2) {
        if (props.Side1 == 'left') {
            modeArrow.value = 'sameSideLeft';

            if (props.rectangle1.leftPosition < props.rectangle2.leftPosition) {
                decalage.value = -30;
            } else {
                decalage.value = - Math.abs(props.rectangle2.leftPosition - props.rectangle1.leftPosition) - 30;
            }
        }
        if (props.Side1 == 'right') {
            modeArrow.value = 'sameSideRight';

            if (props.rectangle1.leftPosition > props.rectangle2.leftPosition) {
                decalage.value = 30;
            } else {
                decalage.value = Math.abs(props.rectangle2.leftPosition - props.rectangle1.leftPosition) + 30;
            }
        }
        if (props.Side1 == 'top') {
            modeArrow.value = 'sameSideTop';
            if (props.rectangle1.topPosition < props.rectangle2.topPosition) {
                decalage.value = -30;
            } else {
                decalage.value = - Math.abs(props.rectangle2.topPosition - props.rectangle1.topPosition) - 30;
            }
        }
        if (props.Side1 == 'bottom') {
            modeArrow.value = 'sameSideBottom';
            if (props.rectangle1.topPosition > props.rectangle2.topPosition) {
                decalage.value = 30;
            } else {
                decalage.value = Math.abs(props.rectangle2.topPosition - props.rectangle1.topPosition) + 30;
            }
        }
    }
    if (props.rectangle1.rectangleId == props.rectangle2.rectangleId) {
        if (props.Side1 == 'top' && props.Side2 == 'left'
            || props.Side1 == 'left' && props.Side2 == 'top'
            || props.Side1 == 'top' && props.Side2 == 'right'
            || props.Side1 == 'right' && props.Side2 == 'top'
            || props.Side1 == 'bottom' && props.Side2 == 'left'
            || props.Side1 == 'left' && props.Side2 == 'bottom'
            || props.Side1 == 'bottom' && props.Side2 == 'right'
            || props.Side1 == 'right' && props.Side2 == 'bottom') {
            modeArrow.value = 'sameRectangle1Angle';
        }
        if (props.Side1 == 'top' && props.Side2 == 'bottom'
            || props.Side1 == 'bottom' && props.Side2 == 'top'
            || props.Side1 == 'left' && props.Side2 == 'right'
            || props.Side1 == 'right' && props.Side2 == 'left') {
            modeArrow.value = 'sameRectangleOpposite';
        }
    }

    treatArrowHead();

    if (props.text != '') {
        textSize.value = calculateSizeOfRectangle(props.text);

    }

};

const arrowHead = ref({ x1: 0, y1: 0, x2: 0, y2: 0, x3: 0, y3: 0 });


const treatArrowHead = () => {
    let angle = Math.atan2(y2.value - y1.value, x2.value - x1.value);
    if (modeArrow.value == 'sameSideTop') {
        angle = 90 * (Math.PI / 180);
    } else if (modeArrow.value == 'sameSideBottom') {
        angle = 270 * (Math.PI / 180);
    } else if (modeArrow.value == 'sameSideLeft') {
        angle = 0 * (Math.PI / 180);
    } else if (modeArrow.value == 'sameSideRight') {
        angle = 180 * (Math.PI / 180);
    }

    const length = 10; // Length of the arrow head lines
    const offsetAngle = Math.PI / 6; // Angle of the arrow head lines

    arrowHead.value.x1 = x2.value;
    arrowHead.value.y1 = y2.value;
    arrowHead.value.x2 = x2.value - length * Math.cos(angle - offsetAngle);
    arrowHead.value.y2 = y2.value - length * Math.sin(angle - offsetAngle);
    arrowHead.value.x3 = x2.value - length * Math.cos(angle + offsetAngle);
    arrowHead.value.y3 = y2.value - length * Math.sin(angle + offsetAngle);

}


watch(() => props.rectangle1, () => {
    if (props.rectangle1) {
        treatLinkXY();

    }
});

watch(() => props.rectangle2, () => {
    if (props.rectangle2) {
        treatLinkXY();
    }
});

onMounted(() => {
    if (props.rectangle1 && props.rectangle2) {
        treatLinkXY();
    }
});


import { defineEmits } from 'vue';
const emit = defineEmits(['click']);

const select = () => {
    emit('click');
    console.log('click');
};

const textSize = ref({ width: 0, height: 0, text: '' });
const calculateSizeOfRectangle = (text: string) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (!context) {
        console.error('Canvas context not available');
        return {
            width: 0,
            height: 0,
            text: "",
        };
    } else {
        context.font = '14px Inter-Bold';
        text = text.replace('\n', ' ')
        const textWidth = context.measureText(text).width;
        const textHeight = 14;
        const rectangleWidthMax = 200;
        if (textWidth < rectangleWidthMax) {
            if (textWidth < 50) {
                return {
                    width: 50 + 10,
                    height: textHeight + 10,
                    text: text,
                };
            } else {
                return {
                    width: Math.min(textWidth, rectangleWidthMax) + 10,
                    height: textHeight + 10,
                    text: text,
                };
            }
        }
        const words = text.split(' ');
        let numberReturnLine = 0;

        const lines = [];
        let currentLine = '';
        for (let i = 0; i < words.length; i++) {
            const word = words[i];
            if (word.replace(' ', '').length == 0) {
                continue;
            }
            if (word.split('\n').length > 1) {
                numberReturnLine += word.split('\n').length - 1;
            }
            const widthWithWord = context.measureText(currentLine + ' ' + word).width;
            if (widthWithWord < rectangleWidthMax) {
                currentLine += ' ' + word;
            } else {
                lines.push(currentLine);
                currentLine = word;
            }
        }
        lines.push(currentLine);
        const rectangleHeight = (textHeight + 10) * (lines.length + numberReturnLine);
        text = lines.join('\n');
        return {
            width: rectangleWidthMax,
            height: rectangleHeight,
            text: text,
        };
    }
};

</script>