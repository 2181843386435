import { defineStore } from 'pinia'
import { useLocalStorage } from '@vueuse/core'
import router from '@/router/index'

export const useAuthStore = defineStore('auth', {
    state: () => {
        return {
            role: useLocalStorage('role', ''),
        }
    },
    actions: {
        login(password: string) {
            const approbatorPassword = process.env.VUE_APP_APPROB_PASSWORD
            const modificatorPassword = process.env.VUE_APP_MODIF_PASSWORD
            const normalPassword = process.env.VUE_APP_NORMAL_PASSWORD

            // Compare les identifiants avec ceux stockés dans le .env
            if (password === approbatorPassword) {
                this.role = 'approbator'
                router.push({ path: '/' })
            } else if (password === modificatorPassword) {
                this.role = 'modificator'
                router.push({ path: '/' })
            } else if (password === normalPassword) {
                this.role = 'normal'
                router.push({ path: '/' })
            } else {
                console.log('Identifiant ou mot de passe incorrect')
                throw new Error('Invalid credentials')
            }
        },
        logout() {
            this.role = ''
            this.redirectToLogin()
        },
        redirectToLogin() {
            router.push({ path: '/login' })
        },
        getRole(): string {
            return this.role
        }
    },
    getters: {
        isAuthenticated(): boolean {
            // Ici, on ne vérifie plus un token JWT réel, simplement s'il y a un token de connexion
            return this.role !== ''
        }
    }
})
