<template>
  <Return @Click-close="goBack" />
  <AllType :items="items" mode="Edit" @delete="supprElement($event)" @edit="editElement($event)" />
  <div class="flex flex-wrap justify-center gap-4 m-3 ">
    <Button @click="addTitre()" variant="destructive"> Ajouter un Titre</Button>
    <Button @click="addText()" variant="destructive"> Ajouter une Zone de Texte</Button>
    <Button @click="addMarkdown()" variant="destructive"> Ajouter une Zone de Texte avec formatage Markdown </Button>
    <Button @click="addImage()" variant="destructive"> Ajouter une Image</Button>
    <Button @click="addDoc()" variant="destructive"> Ajouter un Document Téléchargeable</Button>
    <Button @click="addList()" variant="destructive"> Ajouter une Liste (à Cocher)</Button>
  </div>
  <AddElementComp v-if="addMode" :type="type" data="" @validate="validate($event)"
    @checklistchangevalidate="checklistChangeValidate($event)" />
  <div class="flex justify-center gap-4">
    <Button @click="validateProcessus" variant="destructive"> Enregistrer le Processus </Button>
  </div>
</template>


<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import Return from "@/components/buttonRetour.vue";
import AllType from "@/components/AllTypeComponent.vue";
import Button from "@/components/ui/button/Button.vue";
import axios from 'axios';
import AddElementComp from "@/components/AddOrModifElementComp.vue";
import type { elementTextImage } from '@/types/api';


// Refs pour les propriétés de data
const items = ref<elementTextImage[]>([]);
const addMode = ref<boolean>(false);


const type = ref<string>("");
const index = ref<number>(0);

const router = useRouter();

const goBack = () => {
  router.go(-1);
};

const addText = () => {
  addMode.value = true;
  type.value = "TEXT";
};

const addMarkdown = () => {
  addMode.value = true;
  type.value = "MARKDOWN-TEXT";
};

const addTitre = () => {
  addMode.value = true;
  type.value = "HEADER";
};


const addList = () => {
  addMode.value = true;
  type.value = "LIST";
};

const addImage = () => {
  addMode.value = true;
  type.value = "IMAGE";
};

const addDoc = () => {
  addMode.value = true;
  type.value = "DOCUMENT";
};


const checklistChangeValidate = (data: { addChecklistMode: boolean, list: string }) => {
  if (data.addChecklistMode === true) {
    items.value.push({
      type: "CHECKLIST",
      data: data.list,
      index: index.value,
      elementId: -1
    });
  } else {
    items.value.push({
      type: "LIST",
      data: data.list,
      index: index.value,
      elementId: -1
    });
  }
  index.value++;
  addMode.value = false;
};

const validate = (data: string) => {
  items.value.push({
    type: type.value,
    data: data,
    index: index.value,
    elementId: -1
  });
  index.value++;
  addMode.value = false;
  console.log(items.value);
};





const supprElement = (index: number) => {
  if (modifMode.value === false) {
    const item = items.value[index];
    if (item.type === 'IMAGE' || item.type === 'DOCUMENT') {
      const path = item.data.split('|')[1].split('/')[1];
      axios.delete(`element/deletePath/${path}`);
    }
  }
  else {
    const initialItem = storeInitialItems.value.find((initialItem) => initialItem.index === index);
    if (initialItem) {
      if (initialItem.type === 'IMAGE' || initialItem.type === 'DOCUMENT') {
        const path = initialItem.data.split('|')[1].split('/')[1];
        axios.delete(`element/deletePath/${path}`);
      }
      axios.delete(`/element/${initialItem.elementId}`);
    }
  }
  items.value.splice(index, 1);
};

const editElement = (info: { data: string, index: number }) => {
  const item = items.value[info.index];
  console.log(item);
  console.log(info);
  items.value[info.index] = {
    type: item.type,
    data: info.data,
    index: item.index,
    elementId: item.elementId
  };
};
const storeInitialItems = ref<elementTextImage[]>([]);

const maxIndex = () => {
  let max = 0;
  storeInitialItems.value.forEach((item) => {
    if (item.index > max) {
      max = item.index;
    }
  });
  return max + 1;
};
const getData = async () => {
  try {
    const contentProcessResponse = await axios.get(
      `/process/${processId.value}/content`
    );
    items.value = contentProcessResponse.data.data.content;
    items.value.sort((a: elementTextImage, b: elementTextImage) => a.index - b.index);
    storeInitialItems.value = items.value.map((item) => {
      return {
        type: item.type,
        data: item.data,
        index: item.index,
        elementId: item.elementId,
      };
    });
    index.value = maxIndex();
  }

  catch (error) {
    console.error('Failed to fetch data:', error);
  }
};

const route = useRoute()
const processId = ref<number>(0)


const modifMode = ref<boolean>(false);

const itemChanged = (item: elementTextImage) => {
  const initialItem = storeInitialItems.value.find((initialItem) => initialItem.index === item.index);
  console.log(initialItem);
  if (initialItem === undefined) {
    return -1;
  }
  if (initialItem.type !== item.type || initialItem.data !== item.data) {
    return initialItem.elementId;
  }
  return NaN;
};


const validateProcessus = async () => {
  items.value.forEach(async (item) => {
    if (modifMode.value === true) {
      const id = itemChanged(item);
      if (id > 0) {
        console.log('Element modifié');
        console.log(item);
        await axios.put(`/element/${id}`, {
          element: {
            elementId: item.elementId,
            type: item.type,
            data: item.data,
            index: item.index,
            processId: processId.value
          }
        });
      }
      else if (itemChanged(item) === -1) {
        await axios.post('/element', {
          element: {
            type: item.type,
            data: item.data,
            index: item.index,
            processId: processId.value
          }
        });
      }
    }
    else {
      const response = await axios.post('/element', {
        element: {
          type: item.type,
          data: item.data,
          index: item.index,
          processId: processId.value
        }
      });
      if (response.status === 200) {
        console.log('Element ajouté');
      }
      else {
        console.error('Erreur lors de l\'ajout de l\'élément');
      }
    }


  });
  router.push({
    path: '/process',
    query: { id: processId.value }
  })

};

onMounted(async () => {
  if (route.query.id && typeof route.query.id === 'string') {
    processId.value = parseInt(route.query.id)
  }
  else {
    throw new Error('Invalid processGroupId')
  }
  if (route.query.mode && route.query.mode === 'modif') {
    modifMode.value = true;
    await getData()
  }
});

</script>