<template>
  <div class="h-full flex flex-col">
    <div class="flex flex-row justify-between">
      <Return @Click-close="goBack" />
      <Button @click="modif" variant="destructive" class="m-3" v-if="role == 'approbator' || role == 'modificator'"> Modifier </Button>
    </div>
    <div v-if="process && process.processType === 'TEXT-IMAGE'" class="mx-5 my-5">
      <AllTypeComponent :items="content" :mode="ModeTextImage" />
    </div>
    <div v-else-if="process && process.processType === 'DIAGRAM'" class="flex-grow mx-5 my-5 ">
      <NewDiagramComp :rectangles="rectangles" :links="links" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, watch } from 'vue';
import axios from 'axios';
import { useRoute, useRouter } from 'vue-router';
import type { Process, elementTextImage, rectangle, link } from '@/types/api';
import AllTypeComponent from '@/components/AllTypeComponent.vue';
import { useStore } from 'vuex';
import { State } from '@/stores/store'; // Importer le type State
import NewDiagramComp from '@/components/diagram/DiagramVisualisation.vue';
import Return from '@/components/buttonRetour.vue';
import Button from '@/components/ui/button/Button.vue';

// Utilisation du router pour la navigation
const router = useRouter()

// Méthode pour revenir en arrière
const goBack = () => {
  router.go(-1)
}

const ModeTextImage = 'View';






const route = useRoute();
const processId = ref<number>(0);

if (route.query.id && typeof route.query.id === 'string') {
  processId.value = parseInt(route.query.id);
} else {
  throw new Error('Invalid processId');
}

const process = ref<Process | null>(null);
const content = ref<elementTextImage[]>([]);
const rectangles = ref<rectangle[]>([]);
const links = ref<link[]>([]);
const getData = async () => {
  try {
    const processResponse = await axios.get(`/process/${processId.value}`);
    process.value = processResponse.data.data.process;

    if (process.value?.processType === 'TEXT-IMAGE') {
      const contentProcessResponse = await axios.get(
        `/process/${processId.value}/content`
      );
      content.value = contentProcessResponse.data.data.content;
      content.value.sort((a: elementTextImage, b: elementTextImage) => a.index - b.index);
    }
    else if (process.value?.processType === 'DIAGRAM') {
      const nodesResponse = await axios.get(`/process/${processId.value}/diagramInfo`);
      rectangles.value = nodesResponse.data.data.rectangles;
      links.value = nodesResponse.data.data.links;

    }
  } catch (error) {
    console.error('Failed to fetch data:', error);
  }
};

const store = useStore<State>();

const init_titleBar = () => {
  store.commit('setProcessPage', {
    name: process.value?.processName || 'Pas de Nom de Page',
    icon: process.value?.processIcon || 'error',
    modifDate: process.value?.modifDate || 'Pas de Date de Modification',
    modifUser: process.value?.processModificator || 'Pas de Nom de Modificateur',
    creationDate: process.value?.createdAt || 'Pas de Date de Création',
    approbator: process.value?.processApprobator || 'Pas implémenté encore',
    responsibles: process.value?.processResponsibles || 'Pas de Responsables',
  });
};

import { useAuthStore } from '@/stores/authStore';
const role = ref('')
const verif_role = () => {
  role.value = useAuthStore().getRole()
}


onMounted(async () => {
  await getData();
  init_titleBar();
  verif_role()

});

watch(
  () => route.query.id,
  async () => {
    if (typeof route.query.id === 'string') {
      processId.value = parseInt(route.query.id);
      await getData();
      init_titleBar();
    } else {
      console.error('Invalid processId in route query');
    }
  }
);

const modif = () => {
  if (process.value?.processType === 'TEXT-IMAGE') {

    router.push({
      path: '/addTextImage',
      query: { id: processId.value, mode: 'modif' }
    })
  }
  else if (process.value?.processType === 'DIAGRAM') {
    router.push({
      path: '/modifDiagram',
      query: { id: processId.value, mode: 'modif' }
    })

  }
}
</script>
