<template>
  <div ref="componentRef" class="superposed-container">
    <!-- SVG Drawing -->
    <svg class="svg-drawing">
      <!-- Boucle pour ajouter plusieurs flèches -->
      <Arrow v-for="(link, index) in links" :key="'arrow-' + index" :componentInfo="componentInfo"
        :rectangle1="findRectangleById(link.rectangle1Id)" :rectangle2="findRectangleById(link.rectangle2Id)"
        :Side1="link.rectangle1Side" :Side2="link.rectangle2Side" :text="link.text" @click="selectLink(link)" />
    </svg>
    <!-- Boucle pour ajouter plusieurs rectangles de texte -->
    <DraggableRectangle v-for="(rect, index) in rectanglesTreated" :key="'rect-' + index" :rect="rect" 
      @update-position="updatePosition($event, rect)" :mode="mode" @select="selectRectangle(rect)" />
    <div v-if="mode == 'AddLink'">
      <LinkPoints v-for="(rect, index) in rectanglesTreated" :key="'rectLinks-' + index" :rectangle="rect"
        :componentInfo="componentInfo" :refresh="refreshLinkPoint" @select="selectLinkPoint($event)" />
    </div>
    <div v-if="mode == 'ModifText'">
      <TextRectArea :rect="selectedRectangle" @validate="changeText($event)" @moreModif="modifMore" @stop="stopModif" />
    </div>


    <!-- Rectangle invisible pour la marge -->
    <div class="invisible-rectangle" :style="{ top: invisibleRect.top + 'px', left: invisibleRect.left + 'px' }"></div>
  </div>
</template>

<script setup lang="ts">
import { ref, reactive, onMounted, watch, onBeforeUnmount, defineProps, defineEmits } from 'vue';
import DraggableRectangle from '@/components/diagram/RectSuper.vue';
import Arrow from '@/components/diagram/Arrow.vue';
import LinkPoints from '@/components/diagram/LinkPoints.vue';
import TextRectArea from '@/components/diagram/TextRectArea.vue';
import type { rectangle, link } from '@/types/api';



interface rectangleMore extends rectangle {
  width: number;
  height: number;
  isSelected: boolean;
  heightTaskResponsibles: number;
  widthTaskResponsibles : number;
}


const props = defineProps({
  rectangles: {
    type: Array as () => rectangle[],
    required: true,
  },
  refresh: Boolean,
  mode: {
    type: String,
    required: true,
  },
  links: {
    type: Array as () => link[],
    required: true,
  },

});

const refreshLinkPoint = ref<boolean>(false);


const rectanglesInfo = ref<rectangle[]>(props.rectangles);
const rectanglesTreated = ref<rectangleMore[]>([]);
const componentRef = ref<HTMLElement | null>(null);


const findRectangleById = (id: number) => {
  return rectanglesTreated.value.find((rectangle) => rectangle.rectangleId == id);
};
const treatRectangles = () => {
  console.log('treatRectangles');
  console.log(rectanglesInfo.value);
  rectanglesTreated.value = rectanglesInfo.value.map((rectangle) => {
    const sizeAndText = calculateSizeOfRectangle(rectangle.text);
    const size = {
      width: sizeAndText.width,
      height: sizeAndText.height,
    };
    const position = calculatePositionOfRectangle(rectangle, size);
    let sizeResponsibles = {
      width: 0,
      height: 0,
      text : ""
    };
    if(rectangle.taskResponsibles){
      sizeResponsibles = calculateSizeOfRectangle(rectangle.taskResponsibles);
    }

    return {
      topPosition: position.top,
      leftPosition: position.left,
      width: size.width,
      height: size.height,
      text: sizeAndText.text,
      rectangleId: rectangle.rectangleId,
      taskResponsibles: sizeResponsibles.text,
      widthTaskResponsibles: sizeResponsibles.width || 0,
      heightTaskResponsibles: sizeResponsibles.height || 0,
      backgroundColor: rectangle.backgroundColor,
      textStyle: rectangle.textStyle,
      rectangleStyle: rectangle.rectangleStyle,
      typeExternalLink: rectangle.typeExternalLink,
      dataExternalLink: rectangle.dataExternalLink,
      processId: rectangle.processId,
      isSelected: false,
    };
  });

};


const calculateSizeOfRectangle = (text: string) => {

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (!context) {
    console.error('Canvas context not available');
    return {
      width: 0,
      height: 0,
      text: "",
    };
  } else {
    context.font = '14px Inter-Bold';
    text = text.replace('\n', ' ')
    const textWidth = context.measureText(text).width;
    const textHeight = 14;
    const rectangleWidthMax = 200;
    if (textWidth < rectangleWidthMax) {
      if (textWidth < 50) {
        return {
          width: 50 + 10,
          height: textHeight + 10,
          text: text,
        };
      } else {
        return {
          width: Math.min(textWidth, rectangleWidthMax) + 10,
          height: textHeight + 10,
          text: text,
        };
      }
    }
    const words = text.split(' ');
    let numberReturnLine = 0;

    const lines = [];
    let currentLine = '';
    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      if (word.replace(' ', '').length == 0) {
        continue;
      }
      if (word.split('\n').length > 1) {
        numberReturnLine += word.split('\n').length - 1;
      }
      const widthWithWord = context.measureText(currentLine + ' ' + word).width;
      if (widthWithWord < rectangleWidthMax) {
        currentLine += ' ' + word;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    }
    lines.push(currentLine);
    const rectangleHeight = (textHeight + 10) * (lines.length + numberReturnLine);
    text = lines.join('\n');
    return {
      width: rectangleWidthMax,
      height: rectangleHeight,
      text: text,
    };
  }
};

const calculatePositionOfRectangle = (rectangle: rectangle, size: { height: number, width: number }) => {
  return {
    top: rectangle.topPosition - size.height / 2,
    left: rectangle.leftPosition + componentInfo.value.width / 2 - size.width / 2,
  };
};





const invisibleRect = reactive({
  top: 200,
  left: 200
});



const emit = defineEmits(['modif-text-link','refreshDone','modif-info-rect', 'update-text', 'changeMode', 'update-position', 'AddLink', 'delete-link', "delete-rectangle"]);



const updatePosition = (newPos: { top: number, left: number }, rectangle: rectangleMore) => {
  const newTopPx = newPos.top + rectangle.height / 2;
  const newLeft = newPos.left + rectangle.width / 2;
  const newLeftCenter = newLeft - componentInfo.value.width / 2;
  emit('update-position', { rectangleId: rectangle.rectangleId, topPosition: newTopPx, leftPosition: newLeftCenter });
};


interface infoLinkPoint {
  rectangleId: number;
  side: string;
  // Add other properties as needed
}

const selectedLinkPoint = ref<infoLinkPoint>({ rectangleId: -1, side: '' });


const selectLinkPoint = (info: infoLinkPoint) => {
  if (selectedLinkPoint.value.rectangleId === -1) {
    // Update selectedLinkPoint.value if it hasn't been set yet
    selectedLinkPoint.value = info;
  } else {
    if (info.rectangleId === selectedLinkPoint.value.rectangleId && info.side === selectedLinkPoint.value.side) {

      selectedLinkPoint.value.rectangleId = -1;
      selectedLinkPoint.value.side = '';

    } else {
      // Emit an event or perform other actions
      emit('AddLink', { start: selectedLinkPoint.value, end: info });
      selectedLinkPoint.value.rectangleId = -1;
      selectedLinkPoint.value.side = '';
    }
  }
};


const selectedRectangle = ref<rectangleMore>({ rectangleId: -1, topPosition: 0, leftPosition: 0, text: '', backgroundColor: '', textStyle: '', rectangleStyle: '', typeExternalLink: '', dataExternalLink: '', processId: 0, width: 0, height: 0, isSelected: false, taskResponsibles: "", heightTaskResponsibles: 0, widthTaskResponsibles: 0 });


const selectRectangle = (rect: rectangleMore) => {
  if (props.mode == "SupprRect") {
    emit('delete-rectangle', { rectangleId: rect.rectangleId });
    selectedRectangle.value.rectangleId = -1;
    return;
  }
  if (selectedRectangle.value.rectangleId === -1) {
    selectedRectangle.value = rect;

  } else {
    if (rect.rectangleId === selectedRectangle.value.rectangleId) {
      emit('changeMode', 'ModifText');
    }
  }
};

const changeText = (newText: string) => {
  emit('update-text', { rectangleId: selectedRectangle.value.rectangleId, text: newText });
  selectedRectangle.value = { rectangleId: -1, topPosition: 0, leftPosition: 0, text: '', backgroundColor: '', textStyle: '', rectangleStyle: '', typeExternalLink: '', dataExternalLink: '', processId: 0, width: 0, height: 0, isSelected: false, taskResponsibles: "", heightTaskResponsibles: 0, widthTaskResponsibles: 0 };
};

const modifMore = () => {
  emit('modif-info-rect', selectedRectangle.value.rectangleId);
  selectedRectangle.value = { rectangleId: -1, topPosition: 0, leftPosition: 0, text: '', backgroundColor: '', textStyle: '', rectangleStyle: '', typeExternalLink: '', dataExternalLink: '', processId: 0, width: 0, height: 0, isSelected: false, taskResponsibles: "", heightTaskResponsibles: 0, widthTaskResponsibles: 0 };
};

const stopModif = () => {
  emit('changeMode', 'MoveRectangle');
  selectedRectangle.value = { rectangleId: -1, topPosition: 0, leftPosition: 0, text: '', backgroundColor: '', textStyle: '', rectangleStyle: '', typeExternalLink: '', dataExternalLink: '', processId: 0, width: 0, height: 0, isSelected: false, taskResponsibles: "", heightTaskResponsibles: 0, widthTaskResponsibles: 0 };
};

const selectLink = (link: link) => {
  if (props.mode == "SupprLink") {
    emit('delete-link', { linkId: link.linkId });
    console.log('Delete link');
  }
  if (props.mode == "ModifTextLink") {
    emit('modif-text-link', { linkId: link.linkId });
  }
};

interface compInfo {
  width: number;
  height: number;
  top: number;
  left: number;
}

const componentInfo = ref<compInfo>({
  width: 0,
  height: 0,
  top: 0,
  left: 0,
});

const updateComponentInfo = () => {
  if (componentRef.value) {
    requestAnimationFrame(() => {
      if (componentRef.value) {
        const rect = componentRef.value.getBoundingClientRect();
        componentInfo.value.width = rect.width;
        componentInfo.value.height = rect.height;
        componentInfo.value.top = rect.top;
        componentInfo.value.left = rect.left;
        treatRectangles();
      }
    });
  }
};

onMounted(() => {
  updateComponentInfo();

  const resizeObserver = new ResizeObserver(updateComponentInfo);

  if (componentRef.value) {
    resizeObserver.observe(componentRef.value);
  }

  onBeforeUnmount(() => {
    resizeObserver.disconnect();
  });
});


watch(() => props.refresh, () => {
  if (props.refresh == true) {
    console.log('Refresh');
    rectanglesInfo.value = props.rectangles;
    treatRectangles();
    emit('refreshDone');
  }

});




</script>


<style scoped>
.superposed-container {
  position: relative;
  width: 100%;
  /* Ajustez la taille du conteneur selon vos besoins */
  height: 100%;
  border: 1px solid black;
  /* Bordure autour de la zone */
  overflow: auto;
  /* Ajout des barres de défilement */
  padding: 10px;
  /* Marge intérieure */
  box-sizing: border-box;
  /* Inclure le padding et la bordure dans la taille totale */
}

.svg-drawing {
  width: 100%;
  height: 100%;
}

.invisible-rectangle {
  position: absolute;
  width: 1px;
  height: 1px;
}
</style>
