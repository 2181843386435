<template>
    <div class="flex items-start justify-end text-end gap-2 my-2"> <Button variant="outline"
            @click="validateProcessus">Valider le
            processus</Button>
        <Button variant="outline" @click="supprProcessus">Annuler le
            processus</Button>
    </div>
    <div class="flex flex-col text-center items-center justify-center h-full w-full">
        <div v-if="rectIdLocal == -1" class="w-1/2 justify-center text-center">
            Double-cliquez sur un rectangle puis sur
            <span class="material-symbols-outlined">edit</span>
            pour modifier ces caractéristiques (couleur, responsables, format)
            ou sélectionnez le rectangle dans la liste ci dessous pour le modifier.

            <RectSelection :rectangles="rectangles" @select="selectedRect($event)" />
        </div>
        <div v-else class="flex flex-col gap-2">
            Vous modifiez le rectangle : {{ text }}

            <div class="flex flex-col gap-2 border-2 border-black rounded-md m-2 p-2">
                <div class="flex flex-row gap-2">
                    <div class="flex flex-col gap-2">
                        <Label>Responsables</Label>
                        <Input v-model="taskResponsibles" />
                    </div>
                    <div class="flex flex-col gap-2">
                        <Label>Couleur de fond</Label>
                        <div class="flex items-center gap-2">
                            <span class="material-symbols-outlined">palette</span>
                            <input type="color" id="colorInput" v-model="backgroundColor">
                        </div>
                    </div>
                </div>
                <div class="flex flex-row gap-2">

                    <div class="flex flex-col gap-2">
                        <Label>Format du texte</Label>
                        <Select v-model="textStyle" class="w-full">
                            <SelectTrigger>
                                <SelectValue placeholder="Changer le style" />
                            </SelectTrigger>
                            <SelectContent class="w-full">
                                <SelectGroup class="bg-white">
                                    <SelectItem value="normal"> Normal </SelectItem>
                                    <SelectItem value="font-bold"> Gras </SelectItem>
                                    <SelectItem value="italic"> Italique </SelectItem>
                                    <SelectItem value="underline"> Souligné </SelectItem>
                                </SelectGroup>
                            </SelectContent>
                        </Select>
                    </div>
                    <div class="flex flex-col gap-2">
                        <Label>Format du rectangle</Label>
                        <Select v-model="rectangleStyle" class="w-full">
                            <SelectTrigger>
                                <SelectValue placeholder="Changer le format" />
                            </SelectTrigger>
                            <SelectContent class="w-full">
                                <SelectGroup class="bg-white">
                                    <SelectItem value="normal"> Normal </SelectItem>
                                    <SelectItem value="rounded-md"> Bord Arrondi </SelectItem>
                                    <SelectItem value="rounded-full"> Circulaire </SelectItem>
                                </SelectGroup>
                            </SelectContent>
                        </Select>
                    </div>
                </div>
            </div>
            <div class="flex flex-col gap-2 border-2 border-black rounded-md m-2 p-2">
                <div class="flex flex-col gap-2">
                    <Label>Action lors du clic sur le rectangle</Label>
                    <Select v-model="typeExternalLink" class="w-full">
                        <SelectTrigger>
                            <SelectValue placeholder="Changer l'action" />
                        </SelectTrigger>
                        <SelectContent class="w-full">
                            <SelectGroup class="bg-white">
                                <SelectItem value="OTHER_PROCESS"> Ouverture d'un autre processus </SelectItem>
                                <SelectItem value="EXTERNAL_WEBSITE"> Ouverture d'un autre site </SelectItem>
                                <SelectItem value="MORE_INFORMATION"> Affichage de plus d'information </SelectItem>
                                <SelectItem value="NO_LINK"> Pas d'action' </SelectItem>
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                    <div class="flex flex-col gap-2" v-if="typeExternalLink == 'EXTERNAL_WEBSITE'">
                        <Label>Lien de l'autre site</Label>
                        <Input v-model="externalWebsiteLink" />
                    </div>
                    <div class="flex flex-col gap-2" v-if="typeExternalLink == 'OTHER_PROCESS'">
                        <Label>Processus Lié</Label>
                        {{ listProcess }}
                        <Popover v-model:open="isOpenProcess">
                            <PopoverTrigger as-child>
                                <Button variant="outline" role="combobox" :aria-expanded="isOpenProcess"
                                    class="justify-between text-clip items-center">
                                    <span class="truncate">
                                        {{ processName ? processName : "Sélectionner le processus" }}
                                    </span>
                                    <Icon name="unfold_more" class="ml-2 h-4 w-4 shrink-0 opacity-50" />
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent class="p-0 bg-white">
                                <Command>
                                    <CommandInput class="h-9" placeholder="Rechercher un processus" />
                                    <CommandEmpty>Aucun Processus Trouvé</CommandEmpty>
                                    <CommandList>
                                        <CommandGroup class=" max-h-40 overflow-auto">
                                            <CommandItem v-for="Process in listProcess" :key="Process.label"
                                                :value="Process.label"
                                                @select="handleSelectProcess(Process.value, Process.label)">
                                                {{ Process.label }}
                                            </CommandItem>
                                        </CommandGroup>
                                    </CommandList>
                                </Command>
                            </PopoverContent>
                        </Popover>
                    </div>
                    <div class="flex flex-col gap-2" v-if="typeExternalLink == 'MORE_INFORMATION'">
                        <Label>Informations supplémentaires</Label>
                        <AllType :items="items" mode="Edit" @delete="supprElement($event)"
                            @edit="editElement($event)" />
                        <div class="flex flex-wrap justify-center gap-4 m-3 ">
                            <Button @click="addTitre()" variant="destructive"> Ajouter un Titre</Button>
                            <Button @click="addText()" variant="destructive"> Ajouter une Zone de Texte</Button>
                            <Button @click="addMarkdown()" variant="destructive"> Ajouter une Zone de Texte avec
                                formatage Markdown </Button>
                            <Button @click="addImage()" variant="destructive"> Ajouter une Image</Button>
                            <Button @click="addDoc()" variant="destructive"> Ajouter un Document Téléchargeable</Button>
                            <Button @click="addList()" variant="destructive"> Ajouter une Liste (à Cocher)</Button>
                        </div>
                        <AddElementComp v-if="addMode" :type="type" data="" @validate="validateElement($event)"
                            @checklistchangevalidate="checklistChangeValidate($event)" />
                    </div>
                </div>
            </div>
            <div class="flex flex-row gap-2 items-center justify-center">
                <Button @click="validate" class="button">
                    <span class="material-symbols-outlined">check</span> Valider
                </Button>
                <Button @click="stop" class="button">
                    <span class="material-symbols-outlined">close</span> Annuler
                </Button>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, defineEmits, defineProps, watch } from 'vue';
import RectSelection from '@/components/diagram/RectSelection.vue';
import { rectangle } from '@/types/api';
import Label from '@/components/ui/label/Label.vue';
import Input from '@/components/ui/input/Input.vue';
import Button from '@/components/ui/button/Button.vue';
import Select from "@/components/ui/select/Select.vue";
import SelectTrigger from "@/components/ui/select/SelectTrigger.vue";
import SelectContent from "@/components/ui/select/SelectContent.vue";
import SelectGroup from "@/components/ui/select/SelectGroup.vue";
import SelectItem from "@/components/ui/select/SelectItem.vue";
import SelectValue from "@/components/ui/select/SelectValue.vue";
import Popover from "@/components/ui/popover/Popover.vue";
import PopoverTrigger from "@/components/ui/popover/PopoverTrigger.vue";
import PopoverContent from "@/components/ui/popover/PopoverContent.vue";
import Command from "@/components/ui/command/Command.vue";
import CommandInput from "@/components/ui/command/CommandInput.vue";
import CommandEmpty from "@/components/ui/command/CommandEmpty.vue";
import CommandList from "@/components/ui/command/CommandList.vue";
import CommandGroup from "@/components/ui/command/CommandGroup.vue";
import CommandItem from "@/components/ui/command/CommandItem.vue";
import Icon from "@/components/ui/Icon.vue";
import AddElementComp from "@/components/AddOrModifElementComp.vue";
import AllType from "@/components/AllTypeComponent.vue";

const props = defineProps({
    rectId: {
        type: Number,
        required: true,
    },
    rectangles: {
        type: Array as () => rectangle[],
        required: true,
    },
    processId: {
        type: Number,
        required: false,
    },
});

const rectIdLocal = ref<number>(-1);
const taskResponsibles = ref<string>('');
const backgroundColor = ref<string>('');
const textStyle = ref<string>('');
const rectangleStyle = ref<string>('');
const text = ref<string>('');
const typeExternalLink = ref<string>('');
const externalWebsiteLink = ref<string>('');
const dataExternalLink = ref<string>('');

const selectedRect = (rectId: number) => {
    rectIdLocal.value = rectId;
    const rectangle = props.rectangles.find(rectangle => rectangle.rectangleId == rectId);
    taskResponsibles.value = rectangle?.taskResponsibles || '';
    backgroundColor.value = rectangle?.backgroundColor || '';
    textStyle.value = rectangle?.textStyle || '';
    rectangleStyle.value = rectangle?.rectangleStyle || '';
    text.value = rectangle?.text || '';
    typeExternalLink.value = rectangle?.typeExternalLink || '';
}

watch(() => props.rectId, () => {
    selectedRect(props.rectId);
});

const emit = defineEmits(['validate', 'stop', 'validateProcessus', 'supprProcessus']);

const validate = async () => {
    if (typeExternalLink.value == 'EXTERNAL_WEBSITE') {
        dataExternalLink.value = externalWebsiteLink.value;
    }
    emit('validate', { rectangleId: rectIdLocal.value, taskResponsibles: taskResponsibles.value, backgroundColor: backgroundColor.value, textStyle: textStyle.value, rectangleStyle: rectangleStyle.value, text: text.value, typeExternalLink: typeExternalLink.value, dataExternalLink: dataExternalLink.value, items: items.value });
    rectIdLocal.value = -1;
}



const stop = () => {
    emit('stop');
    rectIdLocal.value = -1;

}


const validateProcessus = () => {
    emit('validateProcessus');
}

const supprProcessus = () => {
    emit('supprProcessus');
}




watch(() => typeExternalLink.value, async () => {
    if (typeExternalLink.value == 'OTHER_PROCESS' && listProcess.value.length === 0) {
        await fetchProcess();
    }
    if (typeExternalLink.value != 'MORE_INFORMATION') {
        await getData();
    }
});


///////////////////////////////////////// PROCESSUS FUNCTIONS /////////////////////////////////////////
import type { Process } from '@/types/api';


const listProcess = ref<Array<Process>>([]); // Simuler la liste des processus
const isOpenProcess = ref(false);
const processName = ref('');
const handleSelectProcess = (processId: number, name: string) => {
    dataExternalLink.value = processId.toString();
    isOpenProcess.value = false;
    processName.value = name;
}

import axios from 'axios';
const fetchProcess = async () => {
    const processResponse = await axios.get(
        `/process`
    )
    console.log(processResponse.data.data.processes);
    processResponse.data.data.processes.forEach((process: Process) => {
        if (process.processId != props.processId) {
            listProcess.value.push({ value: process.processId, label: process.processName });
        }
    });
}



///////////////////////////////////////// MORE INFORMATION FUNCTIONS /////////////////////////////////////////
import type { elementTextImage } from '@/types/api';

const items = ref<elementTextImage[]>([]);
const addMode = ref<boolean>(false);
const type = ref<string>("");
const index = ref<number>(0);


const addText = () => {
    addMode.value = true;
    type.value = "TEXT";
};

const addMarkdown = () => {
    addMode.value = true;
    type.value = "MARKDOWN-TEXT";
};

const addTitre = () => {
    addMode.value = true;
    type.value = "HEADER";
};


const addList = () => {
    addMode.value = true;
    type.value = "LIST";
};

const addImage = () => {
    addMode.value = true;
    type.value = "IMAGE";
};

const addDoc = () => {
    addMode.value = true;
    type.value = "DOCUMENT";
};

const checklistChangeValidate = (data: { addChecklistMode: boolean, list: string }) => {
    if (data.addChecklistMode === true) {
        items.value.push({
            type: "CHECKLIST",
            data: data.list,
            index: index.value,
            elementId: -1,
        });
    } else {
        items.value.push({
            type: "LIST",
            data: data.list,
            index: index.value,
            elementId: -1,
        });
    }
    index.value++;
    addMode.value = false;
};

const validateElement = (data: string) => {
    items.value.push({
        type: type.value,
        data: data,
        index: index.value,
        elementId: -1,
    });
    index.value++;
    addMode.value = false;
    console.log(items.value);
};




const editElement = (info: { data: string, index: number }) => {
    const item = items.value[info.index];
    console.log(item);
    console.log(info);
    items.value[info.index] = {
        type: item.type,
        data: info.data,
        index: item.index,
        elementId: item.elementId,
    };
};


const getData = async () => {
    try {

        const contentRectResponse = await axios.get(
            `/rectangle/${rectIdLocal.value}/content`
        );
        items.value = contentRectResponse.data.data.content;
        items.value.sort((a: elementTextImage, b: elementTextImage) => a.index - b.index);
    }

    catch (error) {
        console.error('Failed to fetch data:', error);
    }
};



const supprElement = (index: number) => {
    const item = items.value[index];
    if (item.type === 'IMAGE' || item.type === 'DOCUMENT') {
        const path = item.data.split('|')[1].split('/')[1];
        axios.delete(`element/deletePath/${path}`);
    }
    items.value.splice(index, 1);
};




</script>

<style scoped>
.select-content {
    width: 100%;
}
</style>
