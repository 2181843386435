<template>
  <div id="app" class="w-screen">
    <Sidebar v-if="!isPhone && logedMode" class="border-r border-gray-200 border-solid" />
    <div v-if="!isPhone" class="flex flex-col w-full h-screen">
      <TitleBar v-if="logedMode" class="flex-none h-20 border-b border-gray-200 border-solid" />
      <div class="flex-grow overflow-y-scroll">
        <router-view />
      </div>
      <FooterComp class="flex-shrink-0 border-t border-gray-200 border-solid" />
    </div>
    <div v-if="isPhone" class="flex flex-col h-screen w-screen">
      <TitleBar class="flex-none h-20 border-b border-gray-200 border-solid" />
      <div class="flex-grow overflow-y-scroll">
        <router-view class="flex w-full" />
        <FooterComp class="border-t border-gray-200 border-solid" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {  onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import Sidebar from '@/components/sidebar/Sidebar.vue';
import TitleBar from '@/components/Titlebar.vue';
import FooterComp from '@/components/Footer.vue';
import { useRoute } from 'vue-router';
import { watch } from 'vue';

const route = useRoute();

// Déclarations réactives pour le statut d'authentification et si l'appareil est un téléphone
const isPhone = ref(false);
const logedMode = ref(false)

watch(route, () => {
  logedMode.value = route.path !== '/login';
})

onMounted(() => {
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    window.navigator.userAgent
  );
  isPhone.value = isMobile;
});
</script>

<style>
#app {
  display: flex;
  flex: 1;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}
</style>
