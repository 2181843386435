<template>
    <div class="image-container">
        <img :src="previewUrl" alt="Image Not Found" class="responsive-image" />
        <h4> {{ name }}</h4>
    </div>
</template>


<script setup lang="ts">
import axios from 'axios'
import { ref, defineProps, onMounted } from 'vue'
const props = defineProps<{
    data: string
    elementId: number
    mode: string
}>()

const name = ref<string>('')
const path = ref<string>('')
const dataStringParse = () => {
    const data = props.data.split('|');
    name.value = data[0]
    path.value = data[1].split('/')[1]
}



const previewUrl = ref<string>('')

const getData = async () => {
    try {
        let response
        if (props.mode === 'Edit') {
            response = await axios.get(`element/downloadPath/${path.value}`,
                {
                    responseType: 'blob',
                })
            
        }
        else {
            response = await axios.get(`element/downloadId/${props.elementId}`,
                {
                    responseType: 'blob',
                })
        }
        const url = URL.createObjectURL(response.data)
            previewUrl.value = url
    } catch (error) {
        console.error('Failed to fetch data:', error)
    }
}

onMounted(async () => {
    dataStringParse()
    await getData()
})

</script>


<style scoped>
.image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.responsive-image {
    max-width: 80%;
    max-height: 80%;
    object-fit: contain;
    justify-content: center;

}
</style>