<template>
    

        <Popover v-model:open="isOpenIcon">
            <PopoverTrigger as-child>
                <Button variant="outline" role="combobox" :aria-expanded="isOpenIcon" class="justify-between text-clip items-center">
                    <span class="material-symbols-outlined ml-2"> {{ IconCode ? IconCode : "" }}</span>
                    <span class="truncate">
                        {{ IconName ? IconName : "Sélectionner l'icone" }}
                    </span>
                    <Icon name="unfold_more" class="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
            </PopoverTrigger>
            <PopoverContent class="p-0 bg-white">
                <Command>
                    <CommandInput class="h-9" placeholder="Rechercher un icone" />
                    <CommandEmpty>Aucun icone trouvé</CommandEmpty>
                    <CommandList>
                        <CommandGroup class=" max-h-40 overflow-auto">
                            <CommandItem v-for="Icon in IconList" :key="Icon.label" :value="Icon.label"
                                @select="handleSelectIcon(Icon.label)">
                                <span class="material-symbols-outlined ml-2">{{ Icon.value }}</span>
                                {{ Icon.label }}
                            </CommandItem>
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>

</template>


<script setup lang="ts">
import Popover from "@/components/ui/popover/Popover.vue";
import PopoverTrigger from "@/components/ui/popover/PopoverTrigger.vue";
import PopoverContent from "@/components/ui/popover/PopoverContent.vue";
import Command from "@/components/ui/command/Command.vue";
import CommandInput from "@/components/ui/command/CommandInput.vue";
import CommandEmpty from "@/components/ui/command/CommandEmpty.vue";
import CommandList from "@/components/ui/command/CommandList.vue";
import CommandGroup from "@/components/ui/command/CommandGroup.vue";
import CommandItem from "@/components/ui/command/CommandItem.vue";
import Button from "@/components/ui/button/Button.vue";
import Icon from "@/components/ui/Icon.vue";
import { ref } from 'vue';
import { IconList } from '@/lib/icon';
import {  defineEmits } from 'vue';
const emit = defineEmits(['select']);


const isOpenIcon = ref(false);
const IconName = ref('');
const IconCode = ref('');


const handleSelectIcon = (iconValue: string) => {
    IconName.value = iconValue;
    IconCode.value = IconList.find((icon) => icon.label === iconValue)?.value || '';
    emit('select', { IconName: IconName.value, IconCode: IconCode.value });
    isOpenIcon.value = false;
}

</script>