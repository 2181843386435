<template>
  <div class="text-center my-10 mx-2">
    <h1 class="text-4xl font-bold text-gray-900">Bienvenue sur Wikix</h1>
    <p class="text-lg text-gray-600 mt-2">
      Ce site rassemble l'ensemble des processus de AEI , vous pouvez retrouvé
      ci dessous les différentes catégories de Processus :
    </p>
  </div>
  <Button variant="outline" v-if="!modifMode && (role == 'approbator' || role == 'modificator')" icon="edit" class="m-3" @click="modifSection()"> Modifier les
    Sections</Button>
  <div class="m-3 flex flex-col gap-2" v-if="modifMode && (role == 'approbator' || role == 'modificator')">
    <div class="flex gap-2">
      <Button v-if="!supprMode" variant="outline" icon="add" @click="addNewSection()"> Nouvelle Section</Button>
      <Button v-if="!supprMode" variant="outline" icon="edit" @click="modifInfoSection()"> Modifier les infos d'une
        Section</Button>
      <Button v-if="!supprMode" variant="outline" icon="delete" @click="supprSection()"> Passer en mode
        Suppression</Button>
      <Button v-if="supprMode" variant="outline" icon="cancel" @click="supprSection()"> Repasser en mode
        classique</Button>
      <Button variant="outline" icon="cancel" @click="modifSection()"> Arreter Modification</Button>
    </div>
    <div v-if="supprMode"> Selectionner la section à supprimer</div>
    <div v-if="modifInfo"> Selectionner la section à modifier</div>

  </div>
  <div class="flex justify-center mb-10">
    <div class="max-w-screen-lg w-200">
      <div class="flex flex-wrap justify-center gap-4">
        <div v-for="category in categories" :key="category.processGroupId" @click="handleClick(category)"
          class="bg-gray-50 rounded-lg border-r border-b border-gray-300 border-solid hover:bg-gray-300 hover:text-blue-800 flex flex-col gap-1 text-center justify-center items-center h-auto w-80 p-4">
          <h1 class="font-bold text-2xl text-blue-400">{{ category.processGroupName }}</h1>
          <Icon v-if="category.processGroupIcon" :name="category.processGroupIcon" class="-m-1 text-blue-400" />


          <div v-if="inCategories[category.processGroupId]"
            class="bg-gray-200 rounded-lg border text-l border-gray-300 text-gray-700 w-full pl-4 p-1 m-1">
            <div v-for="subCategory in inCategories[category.processGroupId]" :key="subCategory.name"
              class="flex flex-row">
              <Icon v-if="subCategory.icon" :name="subCategory.icon" class="mr-1" />
              {{ subCategory.name }}
            </div>
            <div v-if="inCategories[category.processGroupId].length === 0" class="text-gray-500">Section vide</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ModalProcessGroup :open="addMode" type="add" @modal-close="addNewSection" @add="refresh" />
  <ModalProcessGroup :open="openModifInfo" type="modif" @modal-close="openModifModal" @modif="refresh"
    :info="processGroupSelected" />

</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import axios from 'axios'
import Icon from '@/components/ui/Icon.vue'
import type { ProcessGroup, inCategory } from '@/types/api'
import { useRouter } from 'vue-router'
import Button from '@/components/ui/button/Button.vue'
import ModalProcessGroup from '@/components/ModalProcessGroup.vue'

const modifMode = ref<boolean>(false)
const supprMode = ref<boolean>(false)
const addMode = ref<boolean>(false)
const modifInfo = ref<boolean>(false)
const openModifInfo = ref<boolean>(false)


const openModifModal = () => {
  openModifInfo.value = !openModifInfo.value
}
const modifInfoSection = () => {
  modifInfo.value = !modifInfo.value
}

const modifSection = () => {
  modifMode.value = !modifMode.value
}

const supprSection = () => {
  supprMode.value = !supprMode.value
}
const addNewSection = () => {
  addMode.value = !addMode.value
}


const categories = ref<ProcessGroup[]>([])
const inCategories = ref<Record<string, inCategory[]>>({})
const getData = async () => {
  try {
    const response = await axios.get('/processGroup/section/')
    categories.value = response.data.data.categories
    console.log('Categories:', categories.value)  // Journalisation des catégories

    for (const category of categories.value) {
      try {
        const inGroupResponse = await axios.get(
          `/processGroup/${category.processGroupId}/inGroup`
        )
        const inCategory = inGroupResponse.data.data.combinedItems
        console.log(`InCategory for ${category.processGroupId}:`, inCategory)  // Journalisation des sous-catégories

        // Assurez-vous que categoryId est bien défini et unique pour chaque catégorie
        if (category.processGroupId) {
          inCategories.value[category.processGroupId] = inCategory
        } else {
          console.error('Category ID is missing for category:', category)
        }
      } catch (inGroupError) {
        console.error(`Failed to fetch inGroup for category ${category.processGroupId}:`, inGroupError)
      }
    }

    console.log('Final inCategories:', inCategories.value)  // Journalisation finale des sous-catégories
  } catch (error) {
    console.error('Failed to fetch data:', error)
  }
}





const router = useRouter()
const processGroupSelected = ref<ProcessGroup | undefined>(undefined)

const handleClick = (item: ProcessGroup) => {
  if (modifMode.value) {
    processGroupSelected.value = item
    openModifModal()
    console.log('Modif:', processGroupSelected.value)
    return
  }
  router.push({
    path: '/processGroup',
    query: { id: item.processGroupId.toString() }
  })


}
import { useStore } from 'vuex';
import { State } from '@/stores/store'; // Importer le type State

const store = useStore<State>();

const init_titleBar = () => {
  store.commit('setMenuPage', { name: 'Accueil', icon: 'home' });
};

import { useAuthStore } from '@/stores/authStore';
const role = ref('')
const verif_role = () => {
  role.value = useAuthStore().getRole()
}


onMounted(async () => {

  await getData()
  init_titleBar()
  verif_role()

})

const refresh = async () => {
  await getData()
  if (modifMode.value === true) {
    modifMode.value = false
  }
}


</script>
