import CreationDiagram from '../views/CreationDiagram.vue';
import CreationText_Image from '../views/CreationText_Image.vue';
import ProcessGroup from '../views/ProcessGroup.vue';
import Process from '../views/Process.vue';
import ModifDiagram from '@/views/ModifDiagram.vue';


const routes = [

    // Pour rajouter la route pour une des grandes catégories :
    
    // { path: '/nom_chemin', name: 'Nom de la route', component: nom de la vue }
    
    { path: '/addDiagramProcessus', name: 'Ajout d\'un Diagramme', component: CreationDiagram},
    { path: '/modifDiagram', name: 'Modif d\'un Diagramme', component: ModifDiagram},
    { path: '/addTextImage', name: 'Ajout d\'une page Texte et d\'une Image', component: CreationText_Image},
    { path : '/ProcessGroup', name: 'ProcessGroup', component: ProcessGroup},
    { path : '/Process', name: 'Process', component: Process}
    

];

export default routes;
