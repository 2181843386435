<template>
    <div v-for="(point, index) in linkPoints" :style="{
        top: point.topPosition + 'px',
        left: point.leftPosition + 'px',
        width: 10 + 'px',
        height: 10 + 'px'
    }" :class="linkPointClasses[index]" @click="handleClick(point.side)" :key="point.side">
    </div>
</template>


<script setup lang="ts">
import { defineProps, onMounted, watch, ref, defineEmits } from 'vue';
import type { rectangle } from '@/types/api';


interface rectangleMore extends rectangle {
    width: number;
    height: number;
    heightTaskResponsibles: number;
    widthTaskResponsibles: number;
}

interface linkPoint {
    topPosition: number;
    leftPosition: number;
    isSelected: boolean;
    side: string;
}
interface compInfo {
    width: number;
    height: number;
    top: number;
    left: number;
}

const linkPoints = ref<linkPoint[]>([]);
const linkPointClasses = ref<string[]>([]);
const basicClass = 'absolute border-2 border-black';


const props = defineProps({
    rectangle: {
        type: Object as () => rectangleMore,
        required: true,
    },
    componentInfo: {
        type: Object as () => compInfo,
        required: true,
    },
    refresh: {
        type: Boolean,
        required: true,
    },
});

const linkPointInit = () => {
    linkPoints.value = [];
    const topPosition = props.rectangle.topPosition;
    const leftPosition = props.rectangle.leftPosition;
    linkPoints.value.push({
        topPosition: topPosition - 5,
        leftPosition: leftPosition + props.rectangle.width / 2 - 5,
        isSelected: false,
        side: 'top',
    });
    linkPoints.value.push({
        topPosition: topPosition + props.rectangle.height - 5,
        leftPosition: leftPosition + props.rectangle.width / 2 - 5,
        isSelected: false,
        side: 'bottom',
    });
    linkPoints.value.push({
        topPosition: topPosition + props.rectangle.height / 2 - 5,
        leftPosition: leftPosition - 5,
        isSelected: false,
        side: 'left',
    });
    linkPoints.value.push({
        topPosition: topPosition + props.rectangle.height / 2 - 5,
        leftPosition: leftPosition + props.rectangle.width - 5,
        isSelected: false,
        side: 'right',
    });
}

const linkPointClassesInit = () => {
    linkPointClasses.value = [];
    for (let i = 0; i < linkPoints.value.length; i++) {
        linkPointClasses.value.push(basicClass);
        if (linkPoints.value[i].isSelected) {
            linkPointClasses.value[i] += ' border-2 border-red-500';
        }
    }
}

const emit = defineEmits(['select', 'refreshDone']);


const handleClick = (side: string) => {
    const indexClick = linkPoints.value.findIndex((point) => point.side == side);
    linkPoints.value[indexClick].isSelected = !linkPoints.value[indexClick].isSelected;
    linkPointClassesInit();
    emit('select', { side: side, rectangleId: props.rectangle.rectangleId });

}

watch(() => props.rectangle, () => {

    linkPointInit();
    linkPointClassesInit();

});

onMounted(() => {
    linkPointInit();
    linkPointClassesInit();
});


</script>

<style>
.center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    font-family: "Inter-Bold";
    white-space: pre;
    text-align: center;
}
</style>