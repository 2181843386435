<template>
  <Modal :isOpen="Open" @modal-close="close">
    <template #header>
      <div class="flex items-center justify-center">
        <div v-if="props.type === 'modif'">
          <Label v-if="props.idTopGroup"> Modifier le Groupe de Processus : {{ name }}</Label>
          <Label v-else> Modifier la Section : {{ name }}</Label>
        </div>
        <div v-else>
          <Label v-if="props.idTopGroup"> Créer un nouveau Groupe de Processus dans {{ props.nameTopGroup ?
            props.nameTopGroup
            : "Nom pas renseigné" }}</Label>
          <Label v-else> Créer une nouvelle Section</Label>
        </div>
        <Button @click="close" icon="close" class="ml-2"> </Button>
      </div>

    </template>
    <template #content>
      <div v-if="fillMessageMode" class="flex justify-center text-red-500">Veuillez remplir tous les champs</div>
      <div class="flex flex-col gap-4">
        <div class="flex flex-col gap-2">
          <Label v-if="props.idTopGroup">Nom du Groupe</Label>
          <Label v-else>Nom de la Section</Label>
          <Input v-model="name" placeholder="Nom du Groupe/Section" />
        </div>
        <div class="flex flex-1 flex-col gap-2">
          <Label v-if="props.idTopGroup">Icone du Groupe</Label>
          <Label v-else>Icone de la Section</Label>
          <IconSelection class="z-50" @select="handleSelectIcon($event)" />

        </div>
        <div v-if="props.idTopGroup" class="flex flex-col gap-4">
          <Label>Resumé du Groupe</Label>
          <Input v-model="text" placeholder="Résumé du contenu du Groupe" />
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex justify-center pb-3"> <Button @click="validate()" variant="outline"> Enregistrer les
          modifications
        </Button>
      </div>

    </template>
  </Modal>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import Modal from './Modale.vue';
import Label from "@/components/ui/label/Label.vue";
import Input from "@/components/ui/input/Input.vue";
import Button from "@/components/ui/button/Button.vue";
import IconSelection from '@/components/IconSelection.vue';
import { defineProps, defineEmits } from 'vue';
import axios from 'axios';
import { State } from '@/stores/store'; // Assurez-vous d'importer correctement le type State depuis votre chemin
import { useStore } from 'vuex';
const store = useStore<State>();
const props = defineProps({
  nameTopGroup: String,
  idTopGroup: Number,
  open: Boolean,
  type: String,
  info: Object,
});

const IconName = ref('');
const IconCode = ref('');
const text = ref('');
const id = ref(0);
const fillMessageMode = ref(false);
const close = () => {
  Open.value = false;
  emit('modal-close');
};

const fillInputMessage = () => {
  fillMessageMode.value = true;
};
const validate = async () => {

  if (!name.value || !IconCode.value) {
    fillInputMessage();
    return;
  }
  let response;
  if (props.type === 'modif') {
    response = await axios.put(`/processGroup/${id.value}`, {
      processGroup: {
        processGroupId: id.value,
        processGroupName: name.value,
        processGroupIcon: IconCode.value,
        processGroupText: text.value,
        processGroupTopId: props.idTopGroup,
      }
    });
  }
  else {
    if (!props.idTopGroup) {


      response = await axios.post('/processGroup', {
        processGroup: {
          processGroupName: name.value,
          processGroupIcon: IconCode.value,
        }
      });
    }
    else {
      response = await axios.post('/processGroup', {
        processGroup: {
          processGroupName: name.value,
          processGroupIcon: IconCode.value,
          processGroupTopId: props.idTopGroup,
          processGroupText: text.value,
        }
      });
    }
  }


  if (response.status === 200) {
    emit(props.type === 'modif' ? 'modif' : 'add');
    close();
    store.commit('askRefreshSideBar');
  } else {
    console.error('Erreur lors de l\'ajout/modif de la section');
  }
};

const emit = defineEmits(['add', 'modif', 'modal-close']);

const name = ref("");
const Open = ref(props.open);

watch(() => props.open, (value) => {
  try {
    Open.value = value;
  } catch (error) {
    console.error('Erreur dans le watcher:', error);
  }
});

watch(() => props.info, (value) => {
  if (value) {
    name.value = value.processGroupName;
    IconName.value = value.processGroupIcon;
    IconCode.value = value.processGroupIcon;
    text.value = value.processGroupText;
    id.value = value.processGroupId;
  }
});

const handleSelectIcon = (icon: { IconName: string, IconCode: string }) => {
  IconName.value = icon.IconName;
  IconCode.value = icon.IconCode;
  // Close the popover after selection to avoid recursive updates
}


</script>

<style>
.dialog-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.dialog-content {
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  max-height: 80vh;
  max-width: 90%;
  overflow-y: auto;
}
</style>
