<template>
    <div class="flex justify-center m-2">
        <div v-if="mode == 'MoveRectangle' || mode == 'ModifText'" class="flex gap-2 flex-wrap">
            <Button variant="outline" @click="addRectangle">Ajouter un Rectangle</Button>
            <Button variant="outline" @click="supprRect">Supprimer un Rectangle</Button>
            <Button variant="outline" @click="addLink">Ajouter un Lien</Button>
            <Button variant="outline" @click="supprLink">Supprimer un Lien</Button>
            <Button variant="outline" @click="modifTextLink">Modifier/Ajouter le texte d'un Lien</Button>
        </div>
        <div v-else-if="mode == 'SupprRect' || mode == 'SupprLink' || mode == 'AddLink'||mode == 'ModifTextLink' " class="flex flex-col gap-2">
            <Label v-if="mode == 'SupprRect'">Cliquez sur le Rectangle à supprimer</Label>
            <Label v-else-if="mode == 'AddLink'">Cliquez sur le coté souhaité du rectangle de départ et
                d'arrivée</Label>
            <Label v-else-if="mode == 'SupprLink'">Cliquez sur le Lien à supprimer</Label>
            <Label v-else-if="mode == 'ModifTextLink'">Cliquez sur le Lien dont vous souhaitez modif le texte (Cliquez sur le texte du lien c'est plus simple) </Label>
            <Button variant="outline" @click="stop">
                <span v-if="mode == 'SupprRect' || mode == 'SupprLink'">Stopper la Suppression</span>
                <span v-else-if="mode == 'AddLink'">Stopper l'Ajout</span>
                <span v-else-if="mode == 'ModifTextLink'">Stopper la Modification</span>
            </Button>
        </div>
    </div>




</template>


<script setup lang="ts">
import { ref, defineEmits, defineProps, watch } from 'vue';
import Button from '@/components/ui/button/Button.vue';
import Label from '@/components/ui/label/Label.vue';

const mode = ref<string>('MoveRectangle');

const props = defineProps({
    mode: {
        type: String,
        required: true,
    },
});

const emit = defineEmits(['changeMode']);


const addRectangle = () => {
    emit('changeMode', 'AddRectangle');

}

const supprRect = () => {
    emit('changeMode', 'SupprRect');
}

const addLink = () => {
    emit('changeMode', 'AddLink');
}

const supprLink = () => {
    emit('changeMode', 'SupprLink');
}

const modifTextLink = () => {
    emit('changeMode', 'ModifTextLink');
}

const stop = () => {
    emit('changeMode', 'MoveRectangle');

}


watch(() => props.mode, () => {
    mode.value = props.mode;
});




</script>