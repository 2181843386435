import { createStore, Store as VuexStore, CommitOptions, MutationTree} from 'vuex';

// Définir les types pour l'état
export interface State {
    name: string;
    icon: string;
    modifDate: string;
    modifUser: string;
    creationDate: string;
    approbator: string;
    responsibles: string;
    type: string;
    sideBarUpToDate: boolean;
}

// Définir les types pour les mutations
type Mutations<S = State> = {
    setMenuPage(state: S, name: string, icon: string): void;
    setProcessPage (state: S, name: string, icon: string, modifDate: string, modifUser: string, creationDate: string, creationUser: string, approbator: string, responsibles: string): void;
    askRefreshSideBar(state: S): void;
    refreshSideBarDone(state: S): void;
};

// État initial
const state: State = {
    name: '',
    icon: 'draft',
    modifDate: '',
    modifUser: '',
    creationDate: '',
    approbator: '',
    responsibles: '',
    type: '',
    sideBarUpToDate: true
};

// Définir les types pour les mutations
const mutations: MutationTree<State> = {
    setMenuPage(state, { name, icon }: { name: string, icon: string }) {
        state.name = name;
        state.icon = icon;
        state.type = 'menu';
    },
    setProcessPage(state, payload: State) {
        state.name = payload.name;
        state.icon = payload.icon;
        state.modifDate = payload.modifDate;
        state.modifUser = payload.modifUser;
        state.creationDate = payload.creationDate;
        state.approbator = payload.approbator;
        state.responsibles = payload.responsibles;
        state.type = 'process';
    },
    askRefreshSideBar(state) {
        state.sideBarUpToDate = false;
    },
    refreshSideBarDone(state) {
        state.sideBarUpToDate = true;
    }
};

// Créer le store
const store = createStore({
    state,
    mutations
});

// Définir les types pour le store
export type Store = Omit<
    VuexStore<State>,
    'getters' | 'commit' | 'dispatch'
> & {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1],
        options?: CommitOptions
    ): ReturnType<Mutations[K]>;
};

export default store as Store;