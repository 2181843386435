<template>
  <div :style="{
    top: `${rect.topPosition}px`, left: `${rect.leftPosition}px`, width: rect.width + 'px',
    height: rect.height + 'px', position: 'absolute', backgroundColor: props.rect.backgroundColor, cursor: isDragging ? 'grabbing' : 'pointer'
  }" :class="rectangleClasses" @click="handleClick" @mousedown="startDrag">
    <div class="center">
      {{ rect.text }}
    </div>
  </div>
  <div v-if="rect.taskResponsibles" :style="{
    position: 'absolute', top: `${rect.topPosition + rect.height}px`, left: `${rect.leftPosition + rect.width / 2 - rect.widthTaskResponsibles / 2}px`, width: rect.widthTaskResponsibles + 'px',
    height: rect.heightTaskResponsibles + 'px',
  }" class=" centerTaskResponsibles bg-gray-300 border-r-2 border-l-2 border-b-2 border-gray-500"> {{
      rect.taskResponsibles }}</div>
</template>

<script setup lang="ts">
import { ref, watch,onMounted, defineProps, defineEmits } from 'vue';
import type { rectangle } from '@/types/api';


interface rectangleMore extends rectangle {
  width: number;
  height: number;
  isSelected: boolean;
  heightTaskResponsibles: number;
  widthTaskResponsibles: number;
}
const props = defineProps({
  rect: {
    type: Object as () => rectangleMore,
    required: true,
  },
  mode: {
    type: String,
    required: true,
  }
});

const rectangleClasses = ref<string>('');

const rectangleClassesInit = () => {
  rectangleClasses.value = 'relative border-2 border-black';
  if (props.rect.isSelected) {
    rectangleClasses.value += ' border-2 border-red-500';
  }
  if (props.rect.textStyle != 'normal') {
    rectangleClasses.value += ' ' + props.rect.textStyle ;
  }
  if (props.rect.rectangleStyle != 'normal') {
    rectangleClasses.value += ' ' + props.rect.rectangleStyle ;
  }
  
}

const emit = defineEmits(['update-position', 'select']);

const isDragging = ref(false);
const offsetX = ref(0);
const offsetY = ref(0);

const snapToGrid = (value: number, gridSize: number) => {
  return Math.round(value / gridSize) * gridSize;
};

const startDrag = (event: MouseEvent) => {
  isDragging.value = false;
  offsetX.value = event.clientX - props.rect.leftPosition;
  offsetY.value = event.clientY - props.rect.topPosition;

  const onMouseMove = (event: MouseEvent) => {
    isDragging.value = true;
    let newTop = event.clientY - offsetY.value;
    let newLeft = event.clientX - offsetX.value;

    newTop = snapToGrid(newTop, 20);
    newLeft = snapToGrid(newLeft, 20);
    emit('update-position', { top: newTop, left: newLeft });
  };

  const onMouseUp = () => {
    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', onMouseUp);
    isDragging.value = false;
  };

  document.addEventListener('mousemove', onMouseMove);
  document.addEventListener('mouseup', onMouseUp);
};

const handleClick = () => {
  if (!isDragging.value) {
    emit('select');
  }
}

watch (() => props.rect.textStyle, () => {
  console.log('rectangle changed');
  rectangleClassesInit();
});
watch (() => props.rect.rectangleStyle, () => {
  console.log('rectangle changed');
  rectangleClassesInit();
});


onMounted(() => {
  rectangleClassesInit();
});
</script>

<style scoped>
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  font-family: "Inter-Bold";
  white-space: pre;
  text-align: center;
}

.centerTaskResponsibles {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 14px;
  font-family: "Inter-Bold";
  white-space: pre;
  text-align: center;
  font-style: italic; /* Ajouté pour rendre le texte en italique */
}
</style>
