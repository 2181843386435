<template>
        <div class="flex items-center  gap-2 border rounded-md p-2 w-fit">
            <Icon name="description" />
            <span>{{ name }}</span>
            <Button variant="outline" icon="visibility" @click="getData()"></Button>

        </div>
</template>


<script setup lang="ts">
import axios from 'axios'
import { ref, defineProps, onMounted } from 'vue'
import Icon from '@/components/ui/Icon.vue'
import Button from '@/components/ui/button/Button.vue'
const props = defineProps<{
    data: string
    elementId: number
    mode: string
}>()

const name = ref<string>('')
const path = ref<string>('')
const dataStringParse = () => {
    const data = props.data.split('|');
    name.value = data[0]
    path.value = data[1].split('/')[1]
}

const getExtensionByMime = (mime: string) => {
    const mimeTypes: Record<string, string> = {
        'application/pdf': 'pdf',
        'image/jpeg': 'jpeg',
        'image/png': 'png',
        'image/gif': 'gif',
        'image/bmp': 'bmp',
        'image/webp': 'webp',
        'image/svg+xml': 'svg'
    }
    return mimeTypes[mime] || ''
}


const getData = async () => {
    try {
        let response
        if (props.mode === 'Edit') {
            response = await axios.get(`element/downloadPath/${path.value}`,
                {
                    responseType: 'blob',
                })

        }
        else {
            response = await axios.get(`element/downloadId/${props.elementId}`,
                {
                    responseType: 'blob',
                })
        }
        const file = new File(
            [response.data],
            `${name.value}.${getExtensionByMime(response.headers['content-type'])}`,
            { type: response.headers['content-type'] }
        )
        window.open(URL.createObjectURL(file))
        return URL.createObjectURL(file)
    } catch (error) {
        console.error('Failed to fetch data:', error)
    }
}

onMounted(async () => {
    dataStringParse()
})

</script>