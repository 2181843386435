<template>
    <div class="flex h-screen flex-1 flex-col items-center justify-center sm:p-10">
        <Card class="w-full max-w-xs">
            <form @submit.prevent="LoginAction"> <!-- Utilisation correcte du submit -->
                <div class="justify-center m-5">
                    <img src="/logo.svg" alt="logo" class="h-24" />
                </div>
                <div class="flex flex-col gap-3">
                    <div class="flex flex-col gap-2">
                        <Label for="password">Mot de passe</Label>
                        <Input id="password" v-model="password" type="password" placeholder="·········" />
                    </div>
                    <div class="flex justify-center">
                        <span v-if="error" class="text-sm font-light text-destructive">
                            Identifiant ou mot de passe incorrect
                        </span>
                    </div>
                    <Button variant="outline" class="w-full" type="submit">Se connecter</Button>

                </div>
            </form>
        </Card>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import router from '@/router'
import { useAuthStore } from '@/stores/authStore'
import Button from '@/components/ui/button/Button.vue';
import Input from '@/components/ui/input/Input.vue';
import Label from '@/components/ui/label/Label.vue';

const authStore = useAuthStore()

// Si l'utilisateur est déjà authentifié, on le redirige
if (authStore.isAuthenticated) {
    router.push({ path: '/' })
}

const password = ref('')
const error = ref(false)

const LoginAction = async () => {
    error.value = false // Réinitialiser l'erreur avant chaque tentative
    try {
        await authStore.login(password.value)
        router.push({ path: '/' }) // Redirection après succès
    } catch {
        error.value = true // Afficher une erreur en cas d'échec
    }
}
</script>