<template>
    <div class="flex flex-row w-full h-full p-2">
        <div class="flex flex-col items-center justify-center w-1/2 h-full ">
            <div class="flex flex-col h-full w-full">
                <ButtonDiagramModif :mode="mode" @changeMode="changeModeFct($event)" />
                <CompSuper :rectangles="rectangles" :links="links" class=" w-full h-full" :refresh="refresh"
                    @refreshDone="refreshDone" @update-position="updatePosition($event)" :mode="mode"
                    @AddLink="createLink($event)" @delete-rectangle="supprRect($event)" @delete-link="supprLink($event)"
                    @changeMode="changeModeFct($event)" @update-text="updateText($event)"
                    @modif-info-rect="selectRectToModif" @modif-text-link="startModifTextLink($event)" />
            </div>
        </div>
        <div class="flex flex-col items-center justify-center w-1/2 h-full">
            <ModifRectInfoComp :rectId="selectedRectToModifId" :rectangles="rectangles" :processId="processId"
                @validate-processus="validateProcessus" @suppr-processus="supprProcessus"
                @validate="validateModifInfo($event)" @stop="stopModifInfo" />
        </div>
        <ModalModifTextLink :open="openModifTextLink" @modal-close="closeModifTextLink" @modif="modifTextLink($event)"
            :text="textLink" />
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import ButtonDiagramModif from '@/components/diagram/ButtonDiagramModif.vue';
import ModifRectInfoComp from '@/components/diagram/ModifRectInfoComp.vue';
import CompSuper from '@/components/diagram/DiagramCreation.vue';
import { rectangle, linkInterface } from '@/types/api';
import { useRoute } from 'vue-router';
const route = useRoute()
const processId = ref<number>(0)

const mode = ref<string>('MoveRectangle'); // Mode of the diagram (MoveRectangle,AddRectangle, SupprRect, AddLink, SupprLink,ModifyText)

const changeModeFct = (newMode: string) => {
    mode.value = newMode;
    if (newMode == 'AddRectangle') {
        addRectangle();
        mode.value = 'MoveRectangle';
    }
}

const refresh = ref<boolean>(false);


const refreshDone = () => {
    refresh.value = false;
}

interface rectangleUpdated extends rectangle {
    items: elementTextImage[];
}


const rectangles = ref<rectangleUpdated[]>([]);

const index = ref<number>(0);


///////////////////////////////////////// RECTANGLE FUNCTIONS /////////////////////////////////////////
const addRectangle = () => {
    let leftPosition = 0;
    let topPosition = 0;
    if (rectangles.value.length > 0) {
        const leftPositionLastRect = rectangles.value[rectangles.value.length - 1].leftPosition;
        const topPositionLastRect = rectangles.value[rectangles.value.length - 1].topPosition;
        topPosition = topPositionLastRect + 5;
        leftPosition = leftPositionLastRect;
    }
    else {
        topPosition = 50;
        leftPosition = 0;
    }

    rectangles.value.push({
        rectangleId: index.value++,
        topPosition: topPosition,
        leftPosition: leftPosition,
        text: "Voici le nouveau Rectangle" + index.value,
        taskResponsibles: "Bonjour",
        backgroundColor: '#a0bbfa',
        textStyle: '',
        rectangleStyle: '',
        typeExternalLink: '',
        dataExternalLink: '',
        processId: processId.value,
        items: []
    });
    refresh.value = true;

}


const updatePosition = (info: { rectangleId: number, topPosition: number, leftPosition: number }) => {
    const index = rectangles.value.findIndex((rect) => rect.rectangleId == info.rectangleId);
    if (index != -1) {
        rectangles.value[index].topPosition = info.topPosition;
        rectangles.value[index].leftPosition = info.leftPosition;
    }
    refresh.value = true;

}
const updateText = (info: { rectangleId: number, text: string }) => {
    const index = rectangles.value.findIndex((rect) => rect.rectangleId == info.rectangleId);
    if (index != -1) {
        rectangles.value[index].text = info.text;
    }
    console.log(info.text);
    refresh.value = true;
    mode.value = 'MoveRectangle';
}

const selectedRectToModifId = ref<number>(-1);

const selectRectToModif = (rectangleId: number) => {
    selectedRectToModifId.value = rectangleId;
    mode.value = 'MoveRectangle';
}

const validateModifInfo = (info: { rectangleId: number, taskResponsibles: string, backgroundColor: string, textStyle: string, rectangleStyle: string, typeExternalLink: string, dataExternalLink: string, items: elementTextImage[] }) => {

    const index = rectangles.value.findIndex((rect) => rect.rectangleId == info.rectangleId);
    if (index != -1) {
        console.log(rectangles.value[index]);
        rectangles.value[index].taskResponsibles = info.taskResponsibles;
        rectangles.value[index].backgroundColor = info.backgroundColor;
        rectangles.value[index].textStyle = info.textStyle;
        rectangles.value[index].rectangleStyle = info.rectangleStyle;
        rectangles.value[index].typeExternalLink = info.typeExternalLink;
        rectangles.value[index].dataExternalLink = info.dataExternalLink;
        rectangles.value[index].items = info.items;
    }
    refresh.value = true;
    selectedRectToModifId.value = -1;
    mode.value = 'MoveRectangle';
}





const stopModifInfo = () => {
    selectedRectToModifId.value = -1;
    mode.value = 'MoveRectangle';
}

const supprRect = (info: { rectangleId: number }) => {
    const index = rectangles.value.findIndex((rect) => rect.rectangleId == info.rectangleId);
    if (index != -1) {
        rectangles.value.splice(index, 1);
    }
    supprLinkOfRect(info.rectangleId);
    refresh.value = true;
    console.log(rectangles.value);
    mode.value = 'MoveRectangle';
}
///////////////////////////////////////// LINK FUNCTIONS /////////////////////////////////////////


const links = ref<linkInterface[]>([]);
const indexLink = ref<number>(0);

const createLink = (info: { start: { rectangleId: number, side: string }, end: { rectangleId: number, side: string } }) => {
    links.value.push({
        linkId: indexLink.value++,
        rectangle1Id: info.start.rectangleId,
        rectangle2Id: info.end.rectangleId,
        rectangle1Side: info.start.side,
        rectangle2Side: info.end.side,
        text: 'Lien entre ' + info.start.rectangleId + ' et ' + info.end.rectangleId,
    });
    console.log(links.value);
    refresh.value = true;
    mode.value = 'MoveRectangle';
}

const supprLinkOfRect = (rectangleId: number) => {
    let index = links.value.findIndex((link) => link.rectangle1Id == rectangleId || link.rectangle2Id == rectangleId);
    while (index != -1) {
        links.value.splice(index, 1);
        index = links.value.findIndex((link) => link.rectangle1Id == rectangleId || link.rectangle2Id == rectangleId);
    }
    console.log(links.value);
    refresh.value = true;
}

const supprLink = (info: { linkId: number }) => {
    const index = links.value.findIndex((link) => link.linkId == info.linkId);
    if (index != -1) {
        links.value.splice(index, 1);
    }
    refresh.value = true;
    mode.value = 'MoveRectangle';
}

const openModifTextLink = ref<boolean>(false);
const textLink = ref<string>('');
const linkIdToModif = ref<number>(-1);

const startModifTextLink = (info: { linkId: number }) => {
    console.log(info.linkId);
    openModifTextLink.value = true;
    const link = links.value.find(link => link.linkId == info.linkId);

    textLink.value = link?.text !== undefined ? link.text : '';

    linkIdToModif.value = info.linkId;
}

const closeModifTextLink = () => {
    openModifTextLink.value = false;
    textLink.value = '';
    linkIdToModif.value = -1;
    mode.value = 'MoveRectangle';
}

const modifTextLink = (text: string) => {
    const index = links.value.findIndex((link) => link.linkId == linkIdToModif.value);
    if (index != -1) {
        links.value[index].text = text;
    }
    refresh.value = true;
    openModifTextLink.value = false;
    textLink.value = '';
    linkIdToModif.value = -1;
    mode.value = 'MoveRectangle';
}

///////////////////////////////////////// PROCESSUS FUNCTIONS /////////////////////////////////////////
import axios from 'axios';
import { useRouter } from 'vue-router';


const rectChanged = (item: rectangle) => {
    const initialRect = storeRectangles.value.find((initialRect) => initialRect.rectangleId === item.rectangleId);
    console.log("init", initialRect);
    console.log("item", item);
    if (initialRect === undefined) {
        return -1;
    }
    if (initialRect.text !== item.text
        || initialRect.taskResponsibles !== item.taskResponsibles
        || initialRect.backgroundColor !== item.backgroundColor
        || initialRect.textStyle !== item.textStyle
        || initialRect.rectangleStyle !== item.rectangleStyle
        || initialRect.typeExternalLink !== item.typeExternalLink
        || initialRect.dataExternalLink !== item.dataExternalLink
        || initialRect.topPosition !== item.topPosition
        || initialRect.leftPosition !== item.leftPosition) {

        return initialRect.rectangleId;
    }
    return NaN;
};

const supprRectInStoreNotKeep = async () => {
    for (const storeRect of storeRectangles.value) {
        const index = rectangles.value.findIndex((rect) => rect.rectangleId === storeRect.rectangleId);
        if (index === -1) {
            await axios.delete(`/rectangle/${storeRect.rectangleId}`);
        }
    }
}

const ListCorrespondanceIdRect = ref<Record<number, number>>({});
const validateRectangles = async () => {
    for (const rect of rectangles.value) {
        const id = rectChanged(rect);
        console.log(id);
        if (id > 0) {
            const response = await axios.put(`/rectangle/${id}`, {
                rectangle: {
                    rectangleId: rect.rectangleId,
                    topPosition: rect.topPosition,
                    leftPosition: rect.leftPosition,
                    text: rect.text,
                    taskResponsibles: rect.taskResponsibles,
                    backgroundColor: rect.backgroundColor,
                    textStyle: rect.textStyle,
                    rectangleStyle: rect.rectangleStyle,
                    typeExternalLink: rect.typeExternalLink,
                    dataExternalLink: rect.dataExternalLink,
                    processId: rect.processId
                }
            });
            if (rect.typeExternalLink === 'MORE_INFORMATION') {
                await addItemsDatabase(id, rect.items);
            }
            if (response.status === 200) {
                ListCorrespondanceIdRect.value[rect.rectangleId] = rect.rectangleId;
                if (rect.typeExternalLink === 'MORE_INFORMATION') {
                    await addItemsDatabase(ListCorrespondanceIdRect.value[rect.rectangleId], rect.items);
                }
            } else {
                console.error('Erreur lors de l\'ajout du rectangle');
            }
        }
        else if (rectChanged(rect) === -1) {
            const response = await axios.post('/rectangle', {
                rectangle: {
                    topPosition: rect.topPosition,
                    leftPosition: rect.leftPosition,
                    text: rect.text,
                    taskResponsibles: rect.taskResponsibles,
                    backgroundColor: rect.backgroundColor,
                    textStyle: rect.textStyle,
                    rectangleStyle: rect.rectangleStyle,
                    typeExternalLink: rect.typeExternalLink,
                    dataExternalLink: rect.dataExternalLink,
                    processId: rect.processId
                }
            });
            if (response.status === 200) {
                ListCorrespondanceIdRect.value[rect.rectangleId] = response.data.data.rectangleId;
                if (rect.typeExternalLink === 'MORE_INFORMATION') {
                    await addItemsDatabase(ListCorrespondanceIdRect.value[rect.rectangleId], rect.items);
                }
            } else {
                console.error('Erreur lors de l\'ajout du rectangle');
            }
        }


    }
    await supprRectInStoreNotKeep();
}

import { elementTextImage } from '@/types/api';
import ModalModifTextLink from '@/components/diagram/ModalModifTextLink.vue';
const storeInitialItems = ref<elementTextImage[]>([]);



async function addItemsDatabase(rectId: number, items: elementTextImage[]) {
    console.log(rectId);
    await getDataRect(rectId);
    for (const item of items) {
        const id = itemChanged(item);
        if (id > 0) {
            await axios.put(`/element/${id}`, {
                element: {
                    elementId: item.elementId,
                    type: item.type,
                    data: item.data,
                    index: item.index,
                    rectangleId: rectId
                }
            });
        }
        else if (itemChanged(item) === -1) {
            await axios.post('/element', {
                element: {
                    type: item.type,
                    data: item.data,
                    index: item.index,
                    rectangleId: rectId
                }
            });
        }
    }
}
const itemChanged = (item: elementTextImage) => {
    const initialItem = storeInitialItems.value.find((initialItem) => initialItem.index === item.index);
    console.log(initialItem);
    if (initialItem === undefined) {
        return -1;
    }
    if (initialItem.type !== item.type || initialItem.data !== item.data) {
        return initialItem.elementId;
    }
    return NaN;
};


const getDataRect = async (rectId: number) => {
    try {

        const contentRectResponse = await axios.get(
            `/rectangle/${rectId}/content`
        );
        storeInitialItems.value = contentRectResponse.data.data.content;
    }

    catch (error) {
        console.error('Failed to fetch data:', error);
    }
};






const router = useRouter()

const supprProcessus = async () => {
    await axios.delete(`/process/${processId.value}`);
    router.go(-1)
}

import { onMounted } from 'vue';

const storeRectangles = ref<rectangle[]>([]);
const storeLinks = ref<linkInterface[]>([]);

const maxIndexRect = (rectangles: rectangle[]) => {
    let max = 0;
    for (const rect of rectangles) {
        if (rect.rectangleId > max) {
            max = rect.rectangleId;
        }
    }
    return max + 1;
}

const maxIndexLink = (links: linkInterface[]) => {
    let max = 0;
    for (const link of links) {
        if (link.linkId > max) {
            max = link.linkId;
        }
    }
    return max + 1;
}

const getData = async () => {
    try {
        console.log("getData");
        const nodesResponse = await axios.get(`/process/${processId.value}/diagramInfo`);
        // On assigne directement les données à rectangles et links
        rectangles.value = nodesResponse.data.data.rectangles;
        links.value = nodesResponse.data.data.links;

        // On copie les tableaux pour storeRectangles et storeLinks
        storeRectangles.value = JSON.parse(JSON.stringify(rectangles.value));
        storeLinks.value = JSON.parse(JSON.stringify(links.value));
        index.value = maxIndexRect(rectangles.value);
        indexLink.value = maxIndexLink(links.value);

        for (const rect of rectangles.value) {
            ListCorrespondanceIdRect.value[rect.rectangleId] = rect.rectangleId;
        }


    }

    catch (error) {
        console.error('Failed to fetch data:', error);
    }
};

onMounted(async () => {
    if (route.query.id && typeof route.query.id === 'string') {
        processId.value = parseInt(route.query.id)
    }
    else {
        throw new Error('Invalid processGroupId')
    }
    await getData()
    refresh.value = true;

});

const supprLinkInStoreNotKeep = async () => {
    for (const storeLink of storeLinks.value) {
        const index = links.value.findIndex((link) => link.linkId === storeLink.linkId);
        if (index === -1) {
            await axios.delete(`/link/${storeLink.linkId}`);
        }
    }
}

const validateLink = async () => {
    for (const link of links.value) {
        const index = storeLinks.value.findIndex((storeLink) => storeLink.linkId === link.linkId);
        if (index === -1) {
            await axios.post('/link', {
                link: {
                    rectangle1Id: ListCorrespondanceIdRect.value[link.rectangle1Id],
                    rectangle2Id: ListCorrespondanceIdRect.value[link.rectangle2Id],
                    rectangle1Side: link.rectangle1Side,
                    rectangle2Side: link.rectangle2Side,
                    text: link.text,
                }
            });
        }
        else if (storeLinks.value[index].text !== link.text) {
            console.log("modif link");
            console.log(link);
            await axios.put(`/link/${link.linkId}`, {
                link: {
                    linkId: link.linkId,
                    rectangle1Id: link.rectangle1Id,
                    rectangle2Id: link.rectangle2Id,
                    rectangle1Side: link.rectangle1Side,
                    rectangle2Side: link.rectangle2Side,
                    text: link.text,
                }
            });
        }
    }

    await supprLinkInStoreNotKeep();
}



const validateProcessus = async () => {
    console.log("validateProcessus");
    await validateRectangles();
    console.log(ListCorrespondanceIdRect.value);
    await validateLink();
    router.push({
        path: '/process',
        query: { id: processId.value }
    })
}

</script>